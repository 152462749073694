import React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import {getParams, toQueryString} from "../../../../utils/UrlUtil";
import {ellipsis, prettyBool} from "../../../../utils/Util";
import {DEFAULT_SIZE} from "../../../../constants/constants";
import ReactPaginate from 'react-paginate';

class StreamSeasonsTemplate extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            seasons: [],
            totalElements: 0,
            isInProgress: false,
            pageCount: 0,
            searchQuery: {
                size: params.get("size") || DEFAULT_SIZE,
                page: params.get("page") || 0,
                active: true,
                q: params.get("q") || '',
                order: "desc",
                sort: "dateCreated"
            },
        }
    }


    list = (params) => {
        this.toggleInProgress(true);
        http.get("/api/es/seasons" + toQueryString(params))
            .then((response) => {
                let {seasons, totalElements} = response.data.content;
                let pageCount = Math.ceil(totalElements / 10);
                this.setState({seasons, totalElements, pageCount});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    changeHandler = (evt) => {
        let {searchQuery} = this.state;
        searchQuery.q = evt.target.value;
        this.setState({searchQuery});
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    onPageChange = (data) => {
        let {searchQuery} = this.state;
        searchQuery.page = data.selected;
        this.setState({searchQuery});
        this.list(searchQuery)
    };

    onSeasonSelect = (season) => {
        let {onSeasonSelect} = this.props;
        if (onSeasonSelect) {
            this.props.onSeasonSelect(season);
        }
    };

    componentDidMount() {
        let {searchQuery} = this.state;
        this.list(searchQuery);
    }

    render() {
        let {seasons, totalElements, pageCount, searchQuery} = this.state;
        let {modalView} = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        {!modalView ? <a href="/admin/stream/season/create" className="btn btn-primary btn-sm">Create
                            Season</a> : null}
                    </Col>
                    <Col>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            this.list(searchQuery)
                        }}>
                            <Col>
                                <Form.Control type="text" placeholder="Search" name="q" value={searchQuery.q}
                                              onChange={this.changeHandler}/>
                            </Col>
                        </Form>
                    </Col>
                </Row>
                <br/>
                <div>
                    <p className="font-weight-bold">
                        Total Seasons : {totalElements}
                    </p>
                </div>
                <div>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Provider</th>
                            <th>Friendly URL</th>
                            <th>Active</th>
                            <th>Added From</th>
                            <th>Actions</th>
                        </tr>
                        {seasons.map((season, index) => {
                            return <tr key={index}>
                                <td>
                                    <a href={"/admin/stream/season/edit?id=" + season.id}>{ellipsis(season.name, 150)}</a>
                                </td>
                                <td>{season.number}</td>
                                <td>{season.provider}</td>
                                <td>{season.friendlyUrl}</td>
                                <td className={season.active ? 'admin-active' : 'admin-inactive'}>{prettyBool(season.active)}</td>
                                <td>{season.addedFrom}</td>
                                <td>
                                    {!modalView ? <a href={"/admin/stream/season/edit?id=" + season.id}
                                                     className="btn btn-sm btn-primary">Edit</a> : null}
                                    {modalView ? <a href={""} onClick={(e) => {
                                        e.preventDefault();
                                        this.onSeasonSelect(season)
                                    }} className="btn btn-sm btn-primary">Select</a> : null}
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
                <div>
                    {seasons.length > 0 && pageCount > 1 ? <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={parseInt(pageCount)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={this.onPageChange}
                        containerClassName={'pagination'}
                        initialPage={parseInt(searchQuery.page)}
                        disableInitialCallback={true}
                        activeClassName={'active'}
                    /> : null}
                </div>
            </div>
        )
    }
}

export default StreamSeasonsTemplate