import React from 'react';
import {Breadcrumb, Col, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import {getParams} from "../../../../utils/UrlUtil";
import _ from 'lodash'
import {prettyBool} from "../../../../utils/Util";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

class ManageProvidersOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            totalElements: 0,
            isInProgress: false
        }
    }

    onProviderDrag = (newProviders) => {
        _.forEach(newProviders, (provider, index) => {
            provider.tabIndex = index
        });
        this.setState({providers: newProviders});
    };

    saveProvidersOrder = () => {
        let {providers} = this.state;
        let ordersModel = _.map(providers, (provider) => {
            return {
                providerId: provider._id,
                tabIndex: provider.tabIndex
            }
        });
        http.post("/api/es/providers/saveProvidersOrder", {
            orders: ordersModel
        }).then((response) => {
            window.location.reload();
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    list = () => {
        this.toggleInProgress(true);
        let params = getParams();
        http.get("/api/es/providers?" + params.toString())
            .then((response) => {
                let {providers} = response.data.content;
                _.forEach(providers, (provider, index) => {
                    provider._id = provider.id;
                    provider.id = index
                });
                this.setState({providers});
            }).catch((error) => {
            console.log(error)
        })
            .finally(() => {
                this.toggleInProgress(false);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {providers} = this.state;
        providers = _.orderBy(providers, "tabIndex");

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/provider/list">Providers</Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Orders</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <div>
                    {
                        <RLDD items={providers} onChange={this.onProviderDrag}
                              assertValidItem={() => {
                              }}
                              itemRenderer={(provider) => {
                                  return <div className="row table-view cursor-pointer">
                                      <div className="col-md-2">{provider.tabIndex}</div>
                                      <div className="col-md-5">
                                          {provider.label}({prettyBool(provider.active)})
                                      </div>
                                  </div>
                              }
                              }/>
                    }
                </div>
                <br/>
                <Row>
                    <Col>
                        <input type={"button"} value={"Save"}
                               onClick={this.saveProvidersOrder}
                               className="btn btn-primary btn-sm"/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ManageProvidersOrder