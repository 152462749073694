import React from 'react';
import {Breadcrumb, Button, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import loader from '../../../../images/loader.gif'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams} from "../../../../utils/UrlUtil";


class GenreCreateOrEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            genre: {},
            isInProgress: false
        }
    }

    save = (evt) => {
        evt.preventDefault();
        let {genre} = this.state;
        this.toggleInProgress(true);
        http.post("/api/es/genres", genre)
            .then((response) => {
                let {genre} = response.data.content;
                if (response.data.status) {
                    window.location.href = "/admin/stream/genre/edit?id=" + genre.id;
                } else {
                    ToastUtil.error(response.data.errors[0]);
                }
            }, (error) => {
                console.log(error)
            }).finally(() => {
            this.toggleInProgress(false)
        })

    };

    changeHandler = (evt) => {
        this.state.genre[evt.target.name] = evt.target.value;
        this.setState({genre: this.state.genre});
    };

    toggleChangeHandler = (evt) => {
        this.state.genre[evt.target.name] = !this.state.genre[evt.target.name];
        this.setState({genre: this.state.genre});
    };

    loadGenre = (id) => {
        if (id) {
            http.get("/api/es/genres/" + id)
                .then((response) => {
                    let {genre = {}} = response.data.content;
                    this.setState({genre});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.loadGenre(getParams("id"));
    }

    render() {
        let {genre} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/genre/list">
                        Genres
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.save}>
                            <Form.Group controlId="name">
                                <Form.Label className={"font-weight-bold"}>Name *</Form.Label>
                                <Form.Control type="text" placeholder="Name" name='name' required="required"
                                              value={genre.name} onChange={this.changeHandler}/>
                                <Form.Text className="text-muted">
                                    Maximum characters allowed 255.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="label">
                                <Form.Label className={"font-weight-bold"}>Swedish Name *</Form.Label>
                                <Form.Control type="text" placeholder="Swedish Name" name='label' required="required"
                                              value={genre.label} onChange={this.changeHandler}/>
                                <Form.Text className="text-muted">
                                    Maximum characters allowed 255.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="published">
                                <Form.Check type="checkbox" label="Active" name='active'
                                            onChange={this.toggleChangeHandler}
                                            checked={genre.active ? 'checked' : ''}/>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </Row>
            </div>
        )
    }
}

export default GenreCreateOrEdit