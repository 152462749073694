import React from 'react';
import {Breadcrumb, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import {getParams} from "../../../utils/UrlUtil";
import {ellipsis, gotoPage} from "../../../utils/Util";
import {DEFAULT_SIZE} from "../../../constants/constants";
import {format} from "../../../utils/DateUtil";
import HasFeaturePermission from "../../user/HasFeaturePermission";

class Blogs extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            blogs: [],
            totalElements: 0,
            isInProgress: false,
            q: params.get("q") || '',
            size: params.get("size") || DEFAULT_SIZE,
            page: params.get("page") || 0
        }
    }


    loadBlogs = () => {
        this.toggleInProgress(true);
        let params = getParams();
        params.set("sort", "dateCreated");
        params.set("order", "desc");
        http.get("/api/blogs?" + params.toString())
            .then((response) => {
                let {blogs, totalElements} = response.data.content;
                this.setState({blogs: blogs, totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    changeHandler = (evt) => {
        this.setState({[evt.target.name]: this.state.value});
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.loadBlogs();
    }

    render() {
        let {blogs, q, totalElements, page} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Blogs</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        <HasFeaturePermission permission={'blog.write'}>
                            <a href="/admin/blog/create" className="btn btn-primary btn-sm">Create Blog</a>
                            &nbsp;
                            <a href="/admin/blog/manageBlogOrders" className="btn btn-primary btn-sm">Manage Order</a>
                        </HasFeaturePermission>
                    </Col>
                    <Col>
                        <Form onSubmit={this.search}>
                            <Col>
                                <Form.Control type="text" placeholder="Search" name="q" value={q}
                                              onChange={this.changeHandler}/>
                            </Col>
                        </Form>
                    </Col>
                </Row>
                <br/>
                <div>
                    <p className="font-weight-bold">
                        Total Blogs : {totalElements}
                    </p>
                </div>
                <div>
                    <table className="table">
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Views</th>
                            <th>Publish At</th>
                            <th>Published</th>
                            <th>Featured</th>
                            <th>Category</th>
                            <th>Actions</th>
                        </tr>
                        {blogs.map(function (blog, index) {
                            return <tr key={index}>
                                <td>{blog.id}</td>
                                <td>
                                    <a href={"/admin/blog/edit?id=" + blog.id}>{ellipsis(blog.title, 150)}</a>
                                </td>
                                <td>{blog.views}</td>
                                <td>{format(blog.publishAt, "DD-MM-YYYY hh:mm a")}</td>
                                <td>{blog.published ? 'Yes' : 'No'}</td>
                                <td>{blog.featured ? 'Yes' : 'No'}</td>
                                <td>{blog.category.name}</td>
                                <td>
                                    <HasFeaturePermission permission={'blog.write'}>
                                        <a href={"/admin/blog/edit?id=" + blog.id}
                                           className="btn btn-sm btn-primary">Edit</a>
                                    </HasFeaturePermission>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
                <Row>
                    <Col>
                        {page > 0 ? <input type={"button"} className="btn btn-primary btn-sm" value={"Previous"}
                                           onClick={() => {
                                               gotoPage(parseInt(page) - 1)
                                           }}/> : ''}
                    </Col>
                    <Col>
                        <input type={"button"} className="btn btn-primary btn-sm float-right" value={"Next"}
                               onClick={() => {
                                   gotoPage(parseInt(page) + 1)
                               }}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Blogs