import React from "react";
import './MainContent.scss';
import './common.scss';
import MyFavouritePrograms from "./program/MyFavouritePrograms";
import {isMobile, isTablet} from "react-device-detect";
import {isChannelSearchPage} from "../utils/Util";

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let {myPrograms} = this.state;
        return (
            <div className="sidebar">

                <div className="">
                    {!(isMobile && !isTablet || isChannelSearchPage()) ? <MyFavouritePrograms/> : ''}
                </div>
            </div>
        )
    }
}

export default SideBar;