import React from 'react';
import {Breadcrumb, Button, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import loader from '../../../../images/loader.gif'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams} from "../../../../utils/UrlUtil";
import Select from 'react-select'
import _ from 'lodash'

class ProviderCreateOrEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: {},
            countries: [],
            isInProgress: false
        }
    }

    save = (evt) => {
        evt.preventDefault();
        let {provider} = this.state;
        this.toggleInProgress(true);
        http.post("/api/es/providers", provider)
            .then((response) => {
                let {provider} = response.data.content;
                if (response.data.status) {
                    window.location.href = "/admin/stream/provider/edit?id=" + provider.id;
                } else {
                    ToastUtil.error(response.data.errors[0]);
                }
            }, (error) => {
                console.log(error)
            }).finally(() => {
            this.toggleInProgress(false)
        })

    };

    changeHandler = (evt) => {
        this.state.provider[evt.target.name] = evt.target.value;
        this.setState({provider: this.state.provider});
    };

    toggleChangeHandler = (evt) => {
        this.state.provider[evt.target.name] = !this.state.provider[evt.target.name];
        this.setState({provider: this.state.provider});
    };

    loadProvider = (id) => {
        if (id) {
            http.get("/api/es/providers/" + id)
                .then((response) => {
                    let {provider = {}} = response.data.content;
                    this.setState({provider});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    onCountryChange = (_countries) => {
        let {countries, provider} = this.state;
        provider["countries"] = _.map(countries.filter((country) => {
            return _.includes(_.map(_countries, "value"), country.code)
        }), "code");
        this.setState({provider});
    };

    loadCountries = () => {
        http.get("/api/es/countries")
            .then((response) => {
                let {countries} = response.data.content;
                this.setState({countries});
            }).catch((error) => {
            console.log(error)
        })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.loadProvider(getParams("id"));
        this.loadCountries();
    }

    render() {
        let {provider, countries} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/provider/list">
                        Providers
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.save}>
                            <Form.Group controlId="name">
                                <Form.Label className={"font-weight-bold"}>Name *</Form.Label>
                                <Form.Control type="text" placeholder="Name" name='name' required="required"
                                              disabled={provider.id}
                                              value={provider.name} onChange={this.changeHandler}/>
                            </Form.Group>

                            <Form.Group controlId="label">
                                <Form.Label className={"font-weight-bold"}>Label *</Form.Label>
                                <Form.Control type="text" placeholder="Label" name='label' required="required"
                                              value={provider.label} onChange={this.changeHandler}/>
                            </Form.Group>
                            <Form.Group controlId="baseUrl">
                                <Form.Label className={"font-weight-bold"}>Provider Base URL *</Form.Label>
                                <Form.Control type="text" placeholder="Provider Base Url" name='baseUrl' required="required"
                                              value={provider.baseUrl} onChange={this.changeHandler}/>
                            </Form.Group>

                            <Form.Group controlId="className">
                                <Form.Label className={"font-weight-bold"}>Icon Class</Form.Label>
                                <Form.Control type="text" placeholder="Icon Class" name='className'
                                              value={provider.className} onChange={this.changeHandler}/>
                            </Form.Group>

                            {
                                countries.length > 0 ? <Form.Group controlId="countries">
                                    <Form.Label className={"font-weight-bold"}>Countries *</Form.Label>

                                    <Select
                                        defaultValue={countries.filter((country) => {
                                            return _.includes(provider.countries, country.code)
                                        }).map((country) => {
                                            return {value: country.code, label: country.name}
                                        })}
                                        isMulti
                                        onChange={this.onCountryChange}

                                        name="countries"
                                        options={countries.map((country) => {
                                            return {value: country.code, label: country.name}
                                        })}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </Form.Group> : null
                            }

                            <Form.Group controlId="active">
                                <Form.Check type="checkbox" label="Active" name='active'
                                            onChange={this.toggleChangeHandler}
                                            checked={provider.active ? 'checked' : ''}/>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </Row>
            </div>
        )
    }
}

export default ProviderCreateOrEdit