import {
    DEFAULT_COUNTRY_CODE,
    DEFAULT_SIZE,
    TABLE_LAYOUT,
    TIMELINE_LAYOUT,
    TOTAL_WEEK_DAYS
} from "../constants/constants";
import _ from 'lodash'
import {getParams, redirectTo, toQueryString} from "./UrlUtil";
import http from "../services/HttpService";
import {TIME_ZONE, toDuration} from "./DateUtil";
import moment from 'moment-timezone'
import {
    clearChannelsOrder,
    clearIncludeChannels,
    getChannelsOrder,
    getIncludeChannels,
    saveMyChannelSettings
} from "../services/ChannelService";
import {Pagination} from 'react-bootstrap';
import {isMobile, isTablet} from "react-device-detect";
import {isMobileDevice} from "./DeviceUtil";

export const MY_PROGRAMS = 'myPrograms';
export const JTOKEN = 'jtoken';
export const FIRST_VISIT_INFO = 'firstVisitInfo';
let location = window.location;

export const getLayoutWithDefaultValue = () => {
    return isTimelineLayoutSelected() ? TIMELINE_LAYOUT : TABLE_LAYOUT;
};

export const addMyPrograms = (program, channel) => {
    let programs = getAllMyPrograms();
    if (_.findIndex(programs, {id: program.id}) < 0) {
        programs.push({
            ...program,
            channel: {id: channel.id, sourceId: channel.sourceId, name: channel.name}
        });
        localStorage.setItem(MY_PROGRAMS, JSON.stringify(programs));
    }
};

export const bulkAddToMyPrograms = (success, error, always) => {
    let programIds = _.map(getAllMyPrograms(), "id");
    let params = {programIds: programIds};
    if (_.size(programIds) > 0) {
        return http.get("/api/programs/bulkAddToMyPrograms" + toQueryString(params))
            .then(success)
            .catch(error)
            .finally(() => {
                if (always) {
                    always(true)
                }
            })
    } else {
        if (always) {
            always(false)
        }
    }
};

export const clearAllMyPrograms = () => {
    localStorage.setItem(MY_PROGRAMS, JSON.stringify([]));
};

export const getMyPrograms = () => {
    let programs = getAllMyPrograms();
    let day = getDay();
    let startDate = moment.tz(TIME_ZONE).add(day, "days").set("hour", 6).set("minute", 0).set("second", 0).set("millisecond", 0).toDate();
    let endDate = moment().add(day + 1, "days").set("hour", 6).set("minute", 0).set("second", 0).set("millisecond", 0).toDate();
    return programs.filter((program) => {
        let programStartTime = new Date(program.startTime);
        return startDate.getTime() <= programStartTime.getTime() && programStartTime.getTime() <= endDate.getTime()
    });
};

export const getAllMyPrograms = () => {
    return JSON.parse(localStorage.getItem(MY_PROGRAMS)) || [];
};

export const removeMyPrograms = (program) => {
    let programs = getAllMyPrograms();
    _.remove(programs, {id: program.id});
    localStorage.setItem(MY_PROGRAMS, JSON.stringify(programs));
};

const base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const downloadFile = (name, base64, contentType) => {
    let bytes = base64ToArrayBuffer(base64);
    let blob = new Blob([bytes], {type: contentType});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
};

export const isTimelineLayoutSelected = () => {
    return location.pathname.includes("/Gridview")
};

export const isDefaultLayoutSelected = () => {
    return getLayoutWithDefaultValue() === TABLE_LAYOUT
};

export const getSelectedWeek = () => {
    let selectedWeek = parseInt(getParams("week"));
    if (!selectedWeek) {
        selectedWeek = 1
    }
    return selectedWeek
};

export const getSelectedDay = () => {
    let selectedDay = parseInt(getParams("day"));
    if (!selectedDay) {
        selectedDay = 0;
    }
    return selectedDay % 7;
};

export const isLoggedIn = () => {
    return !!localStorage.getItem(JTOKEN)
};

export const logout = () => {
    localStorage.removeItem(JTOKEN);
    localStorage.removeItem("user");
};

export const getTimelineDurations = (range) => {
    let durations = [];
    let day = getDay();
    let start = moment.tz(TIME_ZONE).add(day, "days");
    start.hours(6);
    start.minutes(0);
    let now = moment.tz(TIME_ZONE);
    let minutes, isFirstDateActive = day > 0, active;
    for (let i = 0; i < range; i++) {
        active = false;
        minutes = start.minute();
        if (minutes < 30) {
            start.minutes(0);
        } else {
            start.minutes(30);
        }
        if (!isFirstDateActive) {
            active = now.minute() >= start.minute() && now.hour() === start.hour()
        }
        durations.push({
            date: start.clone(),
            isActive: isFirstDateActive && i === 0 || active,
            duration: toHourMinuteDuration(start.clone())
        });
        start = start.add(30, "minutes")
    }
    return durations
};

export const toHourMinuteDuration = (momentInstance) => {
    return toDuration(momentInstance.hours()) + ":" + toDuration(momentInstance.minute());
};

export const postLoginRegister = (redirectUrl) => {
    bulkAddToMyPrograms(() => {
        clearAllMyPrograms();
    }, null, () => {
        let params = {includeChannelIds: null};
        let includeChannelIds = _.map(getIncludeChannels(), "id");
        if (_.size(includeChannelIds) > 0) {
            params.includeChannelIds = includeChannelIds;
        }

        if (_.size(getChannelsOrder()) > 0) {
            params.orders = getChannelsOrder();
        }

        saveMyChannelSettings(params, null, null, () => {
            clearIncludeChannels();
            clearChannelsOrder();
            if (redirectUrl) {
                redirectTo(redirectUrl)
            } else {
                window.location.reload(false);
            }
        });
    });
};

export const getDay = () => {
    return (getSelectedWeek() - 1) * TOTAL_WEEK_DAYS + getSelectedDay();
};

export const isStartPage = () => {
    return location.pathname === '/' || location.pathname.includes("/Standard");
    ;
};

export const isChannelSearchPage = () => {
    return window.location.pathname.includes("/Sok");
};

export const isFilmPage = () => {
    return location.pathname.includes("/Film");
};

export const isSportsPage = () => {
    return location.pathname.includes("/Sport");
};

export const isSeriesPage = () => {
    return location.pathname.includes("/Tv-serier");
};

export const isRegisterPage = () => {
    return location.pathname.includes('register');
};

export const isArticleListPage = () => {
    return location.pathname.includes('/Artiklar');
};

export const isArticleDetailPage = () => {
    return location.pathname.includes('/artikel/');
};

export const isStreamPage = () => {
    return location.pathname.includes('/Stream');
};

export const isStreamHomePage = () => {
    return location.pathname.includes('/Stream/Home');
};

export const isStreamDetail = () => {
    return location.pathname.includes('/play');
};
export const isChannelDetailPage = () => {
    return location.pathname.includes("/kanal/");
};

export const isFooterPage = () => {
    return isSitemapPage() ||
        isAdvertisementPage() ||
        isHelpPage() ||
        isAboutPage() ||
        isCookiePage()
};

export const isSitemapPage = () => {
    return location.pathname.includes("/Sitemap");
};
export const isAdvertisementPage = () => {
    return location.pathname.includes("/Annonsera");
};
export const isHelpPage = () => {
    return location.pathname.includes("/Help");
};
export const isAboutPage = () => {
    return location.pathname.includes("/OmKollaTV");
};
export const isCookiePage = () => {
    return location.pathname.includes("/Cookies");
};

export const isAdmin = () => {
    let user = getCurrentUser();
    return user.isAdmin;
};

export const hasFeaturePermissions = () => {
    let user = getCurrentUser();
    return _.size(user.featurePermissions) > 0;
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user")) || {};
};

export const isNewUser = () => {
    return window.localStorage.getItem("isNewUser");
};

export const setFirstVisitOnce = () => {
    if (!getFirstVisit().date) {
        localStorage.setItem(FIRST_VISIT_INFO, JSON.stringify({date: new Date().getTime()}))
    }
};

export const getFirstVisit = () => {
    return JSON.parse(localStorage.getItem(FIRST_VISIT_INFO)) || {};
};

export const getChannelLogo = (channel, className) => {
    className = className || '';
    if (channel.logo) {
        return <span className={className}><img src={channel.logo} alt={channel.name}/></span>
    } else if (isNaN(channel.sourceId)) {
        return <span className={`largelogo-${channel.sourceId}`}/>
    } else {
        return <span className={`largelogo-A_${channel.sourceId}`}/>
    }
};

export const removeLastChar = (str) => {
    if (str && str.length > 0) {
        str = str.trim();
        str = str.substring(0, str.length - 1);
    }
    return str;
};

export const getPagination = (totalElements, selectedPage, params) => {
    let items = [];
    let lastPage = Math.ceil(totalElements / DEFAULT_SIZE);
    for (let pageNumber = 0; pageNumber < lastPage; pageNumber++) {
        params.set("page", pageNumber);
        items.push(
            <Pagination.Item href={"?" + params.toString()} key={pageNumber} active={pageNumber == selectedPage}>
                {pageNumber + 1}
            </Pagination.Item>,
        );
    }
    return <Pagination>{items}</Pagination>;
};

export const getPageNumbers = (totalElements) => {
    let items = [];
    let lastPage = Math.ceil(totalElements / 10000);
    for (let pageNumber = 0; pageNumber < lastPage; pageNumber++) {
        items.push(pageNumber);
    }
    return items;
};

export const gotoPage = (number) => {
    let params = getParams();
    params.set("page", number);
    window.location.href = "?" + params.toString();
};

export const getTopDesktopAds = () => {
    if (!isMobile || isTablet) {
        return <> {getAdsLabel()}
            <div id="cncpt-dsk_top"></div>
        </>
    }
};

export const getTopMobileAds = () => {
    if (isMobileDevice()) {
        return <>
            {getAdsLabel()}
            <div id="cncpt-mob_top"></div>
        </>
    }
};

export const getHeaderDesktopAds = () => {
    if (!isMobile) {
        return <div id="adscontainer" className="top-add-container">
            {getAdsLabel()}
            <div id="cncpt-lb1"></div>
        </div>
    }
};


export const getHeaderMobileAds = () => {
    if (isMobile) {
        return <div id="adscontainer" className="top-add-container mobile-header-add">
            {getAdsLabel()}
            <div className="ad-placeholder">
                <div id="cncpt-mob1"></div>
            </div>
        </div>
    }
};

export const ellipsis = (str, n) => {
    if (str && str.length > n) {
        return str.substring(0, n) + "...";
    } else {
        return str;
    }
};

export const setBlogSettings = (settings) => {
    localStorage.setItem("blogSettings", JSON.stringify(settings))
};

export const getBlogSettings = () => {
    let blogSettings = localStorage.getItem("blogSettings") || '{}';
    return JSON.parse(blogSettings);
};

export const setHasViewBlog = (blog) => {
    let viewedBlogs = getHasViewBlog();
    if (!hasViewedBlog(blog)) {
        viewedBlogs.push({'id': blog.id});
        localStorage.setItem('viewedBlogs', JSON.stringify(viewedBlogs));
    }
};

export const getHasViewBlog = (blog) => {
    return JSON.parse(localStorage.getItem("viewedBlogs") || '[]');
};

export const hasViewedBlog = (blog) => {
    let viewedBlogs = getHasViewBlog();
    return _.some(viewedBlogs, ['id', blog.id]);
};

export const setSelectedCountry = (code) => {
    localStorage.setItem("selectedCountryCode", code)
};

export const getSelectedCountry = () => {
    return localStorage.getItem("selectedCountryCode") || DEFAULT_COUNTRY_CODE
};

export const getProgramImage = (program) => {
    let image = _.find(program.images, {size: '600X400'});
    if (image && image.imageUrl) {
        return image.imageUrl
    }
    return program.imageUrl;
};

export const prettyTime = (minutes) => {
    if (minutes) {
        let minutesInt = parseInt(minutes);
        let hour = Math.floor(minutesInt / 60);
        let minute = minutesInt % 60;
        let prettyTimeStr = '';
        if (hour > 0) {
            prettyTimeStr = hour + "h "
        }
        return prettyTimeStr + minute + 'm'
    }
};

export const prettyBool = (bool) => {
    if (bool) {
        return 'Yes'
    }
    return 'No'
};

export const parseBoolean = (value) => {
    return /^true$/.test(value)
};

export const getProviderLabel = (providerName) => {
    let name = providerName;
    switch (providerName) {
        case 'netflix':
            name = 'Netflix';
            break;
        case 'cmore':
            name = 'C More';
            break;
        case 'primevideo':
        case 'amazonprimevideo':
            name = 'Amazon Prime Video';
            break;
        case 'hbonordic':
            name = 'HBO Nordic';
            break;
        case 'hbomax':
            name = 'HBO Max';
            break;
        case 'sfanytime':
            name = 'Sfanytime';
            break;
        case 'viaplay':
            name = 'Viaplay';
            break;
        case 'svtplay':
            name = 'SVT Play';
            break;
        case 'dplay':
            name = 'Discovery+';
            break;
        case 'hbo':
            name = 'HBO';
            break;
        case 'viafree':
            name = 'Viafree';
            break;
        case 'tv4play':
            name = 'TV4 Play';
            break;
        case 'plejmo':
            name = 'Plejmo';
            break;
        case 'appletv':
            name = 'Apple TV';
            break;
        case 'disneyplus':
            name = 'Disney+';
            break;
    }
    return name;
};

export const isLive = (program) => {
    if (program && program.live && program.liveStartDate && program.liveEndDate) {
        let now = new Date().getTime();
        let liveStartDate = new Date(program.liveStartDate);
        let liveEndDate = new Date(program.liveEndDate);
        return liveStartDate <= now && now <= liveEndDate
    }
    return false;
};

export const isLatestTvShow = (program) => {
    if (program && _.size(program.seasons) > 0) {
        return _.some(program.seasons, ["latest", true])
    }
    return false;
};

export const getStreamStartTime = (program) => {
    if (program && program.liveStartDate) {
        try {
            let startDateStr = moment(program.liveStartDate).format("YYYY-MM-DD");
            let todayStr = moment().format("YYYY-MM-DD");
            if (startDateStr === todayStr) {
                return <>{moment(program.liveStartDate).format("HH:mm")}</>
            } else {
                return <>{moment(program.liveStartDate).format("DD MMM HH:mm")}</>
            }
        } catch (e) {
            console.log(e)
        }
    }
};

export const isArticle = (program) => {
    if (program) {
        return program.programType === 'article';
    }
    return false;
};

export const findOrGetDefaultTranslation = (program, selectedCountry) => {
    let translation = {
        title: program.title,
        description: program.description
    };
    if (program && _.size(program.translations) > 0) {
        translation = _.find(program.translations, {"country": selectedCountry}) || {}
        if (!translation.title) {
            translation.title = program.title
        }
        if (!translation.description) {
            translation.description = program.description
        }
    }
    return translation
}

export const getSetting = (program, country) => {
    if (program && _.size(program.settings) > 0) {
        return _.find(program.settings, {"country": country}) || {}
    }
    return {}
}

export const getAdsLabel = () => {
    // return <div className="ad-label"><span>ANNONS</span></div>
}
