import React from 'react';
import {Breadcrumb, Col, Form, Row, Table} from 'react-bootstrap';
import http from "../../../services/HttpService";
import {getParams} from "../../../utils/UrlUtil";
import {gotoPage} from "../../../utils/Util";
import {format} from "../../../utils/DateUtil";
import {DEFAULT_SIZE} from "../../../constants/constants";
import _ from 'lodash'

class Users extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            users: [],
            size: params.get("size") || DEFAULT_SIZE,
            page: params.get("page") || 0,
            q: params.get("q") || '',
            totalElements: 0,
            isInProgress: false
        }
    }

    changeHandler = (evt) => {
        this.setState({[evt.target.name]: this.state.value});
    };

    list = () => {
        this.toggleInProgress(true);
        let params = getParams();
        http.get("/api/users?" + params.toString())
            .then((response) => {
                let {users, totalElements} = response.data.content;
                this.setState({users: users, totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        })
            .finally(() => {
                this.toggleInProgress(false);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {users, isInProgress, totalElements, page, q} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Users</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col><b>Total Users: {totalElements}</b></Col>
                    <Col>
                        <Form onSubmit={this.search}>
                            <Row>
                                <Col>
                                    <Form.Control type="text" placeholder="Search" name="q" value={q}
                                                  onChange={this.changeHandler}/>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <br/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Enable</th>
                        <th>Date Created</th>
                        <th>Username</th>
                        <th>Permissions</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        users.map((user, index) => {
                            return <tr key={index}>
                                <td>{user.id}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.enable ? 'Yes' : 'No'}</td>
                                <td>{format(user.dateCreated, "DD-MM-YYYY hh:mm a")}</td>
                                <td>{user.username}</td>
                                <td>{_.join(user.featurePermissions, ",")}</td>
                                <td>
                                    <a href={`/admin/user/edit?id=${user.id}`}
                                       className="btn btn-sm btn-primary">Edit</a>
                                    {' '}
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
                <Row>
                    <Col>
                        {page > 0 ? <input type={"button"} className="btn btn-primary btn-sm" value={"Previous"}
                                           onClick={() => {
                                               gotoPage(parseInt(page) - 1)
                                           }}/> : ''}
                    </Col>
                    <Col>
                        <input type={"button"} className="btn btn-primary btn-sm float-right" value={"Next"}
                               onClick={() => {
                                   gotoPage(parseInt(page) + 1)
                               }}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Users