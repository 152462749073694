import {LOCAL_ENV} from "../constants/api";
import moment from 'moment-timezone'

export const TIME_ZONE = LOCAL_ENV ? 'Asia/Kolkata' : 'Europe/Stockholm';
export const equalDate = (date1, date2) => {
    if (date1 && date2) {
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }
    return false
};

export const toDuration = (number) => {
    return number < 10 ? "0" + number : number.toString()
};

export const clearTime = (mills) => {
    if (mills) {
        return new Date(new Date(mills).setHours(0, 0, 0, 0))
    }
};

export const format = (mills, format) => {
    return moment.tz(mills, TIME_ZONE).format(format);
};