import React, {useEffect, useState} from 'react';
import Streams from "./Streams";
import '../streamListing.scss';
import {getParams} from "../../../utils/UrlUtil";
import {getAdsLabel, getHeaderDesktopAds, getTopDesktopAds} from "../../../utils/Util";
import {isTableDevice} from "../../../utils/DeviceUtil";
import Providers from "../Providers";
import StreamGenre from "../StreamGenre";
import _ from 'lodash'

const StreamList = (props) => {

    let params = getParams();
    const selectedSorting = params.get("sorting") || "prioritized";
    let [providers, setProviders] = useState([]);
    const {match: {params: urlParams}} = props;

    const onProvidersLoaded = (providers) => {
        setProviders(_.orderBy(providers, "tabIndex").slice(0, 10));
    };

    const selectSorting = (value) => {
        let params = getParams();
        params.set("sorting", value);
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    const getSelectedProvider = () => {
        let provider = params.get("providers");
        if (provider && _.size(providers) > 0) {
            return _.find(providers, {"name": provider})
        }
    };

    useEffect(() => {
    }, []);

    const StreamTypes = (
        <div className="gotoMenu">
            <h2>{getSelectedProvider() ? getSelectedProvider().label : 'Titta efter val'}</h2>
            <ul>
                <li><a href="/Stream" target={"_self"}>Rensa Filter</a></li>
                <li><a href={`/Stream/Movie${params.toString() ? '?' + getParams().toString() : ''}`}
                       target={"_self"}>Film</a></li>
                <li><a href={`/Stream/Tv-Show${params.toString() ? '?' + getParams().toString() : ''}`}
                       target={"_self"}>Serier</a></li>
                <li><a href={`/Stream?free=true${params.toString() ? '&' + getParams().toString() : ''}`}
                       target={"_self"}>Gratis</a></li>
                <li><a href={`/Stream/Live${params.toString() ? '?' + getParams().toString() : ''}`} target={"_self"}>Sport</a>
                </li>
            </ul>
        </div>
    );

    const SortingOptions = (
        <div className="sorting">
          <span className={`${selectedSorting === "prioritized" ? 'active' : ''}`}>
                <a href="/Stream?sorting=prioritized" onClick={(e) => {
                    e.preventDefault();
                    selectSorting("prioritized");
                }}>Nyheter</a>
          </span>
            <span className={`${selectedSorting === "rating" ? 'active' : ''}`}>
                <a href="/Stream?sorting=rating" onClick={(e) => {
                    e.preventDefault();
                    selectSorting("rating");
                }}>Betyg</a>
          </span>
            <span className={`${selectedSorting === "latest" ? 'active' : ''}`}>
                <a href="/Stream?sorting=latest" onClick={(e) => {
                    e.preventDefault();
                    selectSorting("latest");
                }}> Senaste</a>
          </span>
        </div>
    );

    return (
        <div className="stream-listing-page">
            {getTopDesktopAds()}
            {getHeaderDesktopAds()}
            {isTableDevice() ?<> {getAdsLabel()}<div id="cncpt-tab_lb1"></div> </> : ''}
            <Providers onProvidersLoaded={onProvidersLoaded}/>
            <div className="headingH2">
                {StreamTypes}
            </div>
            <div className="topSection">
                {SortingOptions}
                <div className="rightSection">
                    <div className="custom-selectBox">
                        <StreamGenre/>
                    </div>
                    {/*<div className="custom-selectBox">*/}
                    {/*<StreamCountry/>*/}
                    {/*</div>*/}
                </div>

            </div>
            <Streams sorting={selectedSorting} streamType={urlParams.streamType}/>
        </div>
    )
};

export default StreamList;