import React from 'react';
import {Modal} from 'react-bootstrap';
import StreamSeasonsTemplate from "./StreamSeasonsTemplate";

class StreamSeasonsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onSeasonSelect = (season) => {
        let {onSeasonSelect} = this.props;
        if (onSeasonSelect) {
            this.props.onSeasonSelect(season);
        }
        this.props.onHide();
    };

    componentDidMount() {
    }


    render() {
        return <div>
            <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Seasons</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StreamSeasonsTemplate modalView={true} onSeasonSelect={this.onSeasonSelect}/>
                </Modal.Body>
            </Modal>
        </div>
    }
}

export default StreamSeasonsModal