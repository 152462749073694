import React from 'react';
import {Breadcrumb} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import _ from 'lodash'
import {getParams} from "../../../../utils/UrlUtil";
import {ellipsis, prettyBool} from "../../../../utils/Util";

class StreamCountries extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            countries: [],
        }
    }


    list = () => {
        let params = getParams();
        params.set("sort", "name");
        http.get("/api/es/countries?" + params.toString())
            .then((response) => {
                let {countries, totalElements} = response.data.content;
                this.setState({countries, totalElements});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
        })
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {countries, totalElements} = this.state;
        countries = _.orderBy(countries, ['active'], "desc");
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Countries</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <div>
                    <p className="font-weight-bold">
                        Total Countries : {totalElements}
                    </p>
                </div>
                <div>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                        {countries.map(function (country, index) {
                            return <tr key={index}>
                                <td>
                                    <a href={"/admin/stream/country/edit?id=" + country.id}>{ellipsis(country.name, 150)}</a>
                                </td>
                                <td>{country.code}</td>
                                <td className={country.active ? 'admin-active' : 'admin-inactive'}>{prettyBool(country.active)}</td>
                                <td>
                                    <a href={"/admin/stream/country/edit?id=" + country.id}
                                       className="btn btn-sm btn-primary">Edit</a>
                                    &nbsp;
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </div>
        )
    }
}

export default StreamCountries