import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {getProgramImage, isLoggedIn} from "../../utils/Util";
import http from "../../services/HttpService";
import {toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'

class MyFavouriteStreamPrograms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            favouritePrograms: [],
            isInProgress: false
        }
    }


    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };


    loadMyStreamPrograms = () => {
        if (isLoggedIn()) {
            http.get("/api/es/favourites/kollatv/me" + toQueryString({withProgram: true}))
                .then((response) => {
                    this.setState({
                        favouritePrograms: response.data.content.favourites
                    });
                }).catch((error) => {
                console.log(error.toJSON)
            })
        }
    };

    removeFavourite = (programId) => {
        if (isLoggedIn() && programId) {
            http.delete("/api/es/favourites/kollatv/me/" + programId)
                .then((response) => {
                    this.loadMyStreamPrograms();
                }).catch((error) => {
                console.log(error.toJSON)
            })
        }
    };

    componentDidMount() {
        this.loadMyStreamPrograms();
    }

    render() {
        let {favouritePrograms} = this.state;
        favouritePrograms = _.filter(favouritePrograms, ["watch", true]);
        return (<div className="my-list">
            <h2>MIN TABLÅ STREAM</h2>
            <ul className="stream-list">
                {
                    favouritePrograms.map((favourite, index) => {
                        return <li key={index}>

                            <div className="image"><img src={getProgramImage(favourite.program)}/></div>
                            <div className="name">
                                <h3> {favourite.program.title}</h3>
                            </div>
                            <span className="close-btn"
                                  onClick={() => this.removeFavourite(favourite.programId)}>x</span>
                        </li>
                    })
                }
            </ul>
        </div>)
    }
}

export default MyFavouriteStreamPrograms;