import React from 'react';
import {Button, Form} from 'react-bootstrap'
import CKEditor from 'ckeditor4-react';
import {CONTENT_PAGES} from "../../../constants/constants";
import http from "../../../services/HttpService";
import {toQueryString} from "../../../utils/UrlUtil";
import ToastUtil from "../../../utils/ToastUtil";


class PageContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: undefined,
            contentType: undefined,
            contentPages: CONTENT_PAGES
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value}, this.loadContent);
    };

    onEditorChange = (e) => {
        this.setState({content: e.editor.getData()});
    };

    saveContent = (e) => {
        e.preventDefault();
        let {content, contentType} = this.state;
        http
            .post("/api/contents", {
                content: content,
                contentType: contentType
            })
            .then((response) => {
                ToastUtil.success("Saved successfully");
                console.log(response)
            });
    };

    loadContent = () => {
        let params = {contentType: this.state.contentType};
        http
            .get("/api/contents" + toQueryString(params))
            .then((response) => {
                let {pages} = response.data.content;
                let page = pages[0] || {};
                this.setState({content: page.content});
            })
            .catch((error) => {
                console.log(error.toJSON())
            })
    };

    render() {
        let {contentPages, content} = this.state;
        return (
            <div>
                <Form>
                    <Form.Group controlId='page'>
                        <Form.Label>Page</Form.Label>
                        <Form.Group>
                            <Form.Control as="select" name='contentType' onChange={this.onChange}>
                                <option value=''>Select Page Type</option>
                                {
                                    contentPages.map((type, index) => {
                                        return <option value={type.value} key={index}>{type.name}</option>

                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group controlId="content">
                        <Form.Label>Content</Form.Label>
                        <CKEditor
                            data={content}
                            onChange={this.onEditorChange}/>
                    </Form.Group>

                    <Button variant="primary" type="submit" onClick={this.saveContent}>
                        Save
                    </Button>
                </Form>
            </div>
        )
    }
}

export default PageContent