import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import ToastUtil from "../../../utils/ToastUtil";
import {getParams} from "../../../utils/UrlUtil";

class BlogSettings extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            blogSettings: {},
            isInProgress: false
        }
    }


    loadBlogSettings = () => {
        this.toggleInProgress(true);
        http.get("/api/blogs/blogSettings")
            .then((response) => {
                let {blogSettings} = response.data.content;
                this.setState({blogSettings: blogSettings});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    saveBlogSettings = (e) => {
        e.preventDefault();
        this.toggleInProgress(true);
        let {blogSettings} = this.state;
        http.post("/api/blogs/blogSettings", blogSettings)
            .then((response) => {
                let {blogSettings} = response.data.content;
                this.setState({blogSettings: blogSettings});
                ToastUtil.success("Settings has been saved successfully")
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    changeHandler = (evt) => {
        this.setState({[evt.target.name]: this.state.value});
    };

    toggleChangeHandler = (evt) => {
        this.state.blogSettings[evt.target.name] = !this.state.blogSettings[evt.target.name];
        this.setState({blogSettings: this.state.blogSettings});
    };


    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.loadBlogSettings();
    }

    render() {
        let {blogSettings} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/blog/list">Blogs</Breadcrumb.Item>
                    <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        <Form onSubmit={this.saveBlogSettings}>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Show Articles on Start Page" name='showOnStartPage'
                                            onChange={this.toggleChangeHandler}
                                            checked={blogSettings.showOnStartPage ? 'checked' : ''}/>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default BlogSettings