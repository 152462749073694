import axios from 'axios'
import {API} from '../constants/api'
import {logout} from "../utils/Util";

const http = axios.create({
    baseURL: API
});
http.interceptors.request.use((request) => {
    if (localStorage.getItem("jtoken") && !isAuthDisabled(request)) {
        request.headers["X-AUTH-TOKEN"] = localStorage.getItem("jtoken");
    }
    return request
});

http.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            logout();
            if (!(error.response.config.url.indexOf('/login') > -1)) {
                window.location.reload(false);
            }
        }
    }
    return Promise.reject(error)
});

const isAuthDisabled = (config) => {
    return config && config.hasOwnProperty('auth') && !config.auth
};
export default http