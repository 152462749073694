import React from 'react';
import {getCurrentUser, isAdmin} from "../../utils/Util";
import _ from 'lodash'

class HasFeaturePermission extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {permission} = this.props;
        let user = getCurrentUser();
        let index = _.indexOf(user.featurePermissions, permission)
        if (isAdmin() || index >= 0) {
            return this.props.children
        }
        return null
    }
}

export default HasFeaturePermission