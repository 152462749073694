import React from 'react';
import MyFavouritePrograms from "./program/MyFavouritePrograms";


import './MainContent.scss'
import {TABLE_LAYOUT, TIMELINE_LAYOUT} from "../constants/constants";
import {getLayoutWithDefaultValue} from "../utils/Util";
import {getParams, redirectTo} from "../utils/UrlUtil";
import _ from 'lodash'
import {isMobile} from "react-device-detect";
import {getSelectedCategoryId} from "../services/ChannelService";

class InfoBar extends React.Component {
    constructor(props) {
        super(props);
        let layout = getLayoutWithDefaultValue();
        this.state = {
            layout: layout,
            showMyProgramList: false
        }
    }

    toggleMyProgramListModal = (bool) => {
        this.setState({showMyProgramList: bool})
    };

    onCategoryChange = (event) => {
        let params = getParams();
        if (!isNaN(event.target.value)) {
            params.set("category", event.target.value);
        } else {
            params.delete("category")
        }
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    componentDidMount() {

    }

    setLayout = (layout) => {
        if (!(getLayoutWithDefaultValue() === layout)) {
            this.setState({layout: layout}, () => {
                if (layout === TIMELINE_LAYOUT) {
                    window.location.href = '/Gridview'
                } else {
                    window.location.href = '/Standard'
                }
            });
        }

    };

    switchLayout = () => {
        let layout = this.state.layout === TABLE_LAYOUT ? TIMELINE_LAYOUT : TABLE_LAYOUT;
        this.setLayout(layout)
    };

    render() {
        let {layout, showMyProgramList} = this.state;
        let {categories} = this.props;
        return (
            <div className="row info-bar">
                <div className="col-xs-6 col-md-6">
                    {_.size(categories) > 0 ? <div className="filter">
                        <label><strong>Välj filmkategori:</strong></label>
                        <select name='categoryId' className="category-drop-down"
                                defaultValue={`${getSelectedCategoryId()}`}
                                onChange={this.onCategoryChange}>
                            <option value="Select Category">Alla kategorier</option>
                            {
                                categories.map((category, index) => {
                                    return <option key={index} value={category.id}>
                                        {category.name}
                                    </option>
                                })
                            }
                        </select>
                    </div> : ''}

                </div>
                <div className={isMobile ? 'col-xs-6 col-md-6 padding0' : 'col-xs-6 col-md-6'}>
                    <div className="show-mylist">
                        <button className="showlist" onClick={() => {
                            this.toggleMyProgramListModal(true)
                        }}>
                            Program jag följer
                        </button>
                        {' '}
                        <button className="showlist" onClick={() => {
                            redirectTo("/MinaKanaler")
                        }}>Min sida
                        </button>
                    </div>
                    <div className="switch-layout">
                        <strong>Visningsläge:</strong>
                        <span onClick={() => this.setLayout(TABLE_LAYOUT)}>Tablåvy</span>
                        <span className={`toggle-btn ${layout === TABLE_LAYOUT ? 'off' : ''}`}
                              onClick={this.switchLayout}/>
                        <span onClick={() => this.setLayout(TIMELINE_LAYOUT)}>Tidslinje</span>
                    </div>
                </div>

                <div className="modal-overlay" style={{display: showMyProgramList ? 'block' : 'none'}}>
                    <div className="modal-inner-container myProgramList-modal">

                        <button className="close" onClick={() => {
                            this.toggleMyProgramListModal(false)
                        }}>
                            X
                        </button>
                        {isMobile ? <MyFavouritePrograms/> : ''}
                    </div>
                </div>

            </div>
        )
    }
}

export default InfoBar