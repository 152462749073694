import React from 'react';
import AdminRoutes from "../admin/routes/AdminRoutes";
import {Route, Switch} from 'react-router-dom';
import AdminIndex from "../admin/index/Index";
import Programs from "../admin/program/Programs";
import PageContent from "../admin/content/Content";
import '../../style/admin.scss'
import Channels from "../admin/channel/Channels";
import Show from "../admin/channel/Show";
import {hasFeaturePermissions, isAdmin} from "../../utils/Util";
import ImportPrograms from "../admin/program/ImportPrograms";
import Blogs from "../admin/blog/Blogs";
import CreateOrEdit from "../admin/blog/CreateOrEdit";
import Users from "../admin/user/Users";
import EditUser from "../admin/user/EditUser";
import BlogCategories from "../admin/blogCategory/BlogCategories";
import CreateOrEditBlogCategory from "../admin/blogCategory/CreateOrEditBlogCategory";
import MediaList from "../admin/media/MediaList";
import BlogSettings from "../admin/blog/BlogSettings";
import ManageBlogOrders from "../admin/blog/ManageBlogOrders";
import Providers from "../admin/stream/provider/Providers";
import StreamPrograms from "../admin/stream/program/StreamPrograms";
import StreamSeasons from "../admin/stream/season/StreamSeasons";
import StreamGenres from "../admin/stream/genre/StreamGenres";
import StreamCountries from "../admin/stream/country/StreamCountries";
import CountryCreateOrEdit from "../admin/stream/country/CountryCreateOrEdit";
import GenreCreateOrEdit from "../admin/stream/genre/GenreCreateOrEdit";
import ProviderCreateOrEdit from "../admin/stream/provider/ProviderCreateOrEdit";
import SeasonCreateOrEdit from "../admin/stream/season/SeasonCreateOrEdit";
import StreamProgramCreateOrEdit from "../admin/stream/program/StreamProgramCreateOrEdit";
import StreamLanguages from "../admin/stream/languages/StreamLanguages";
import LanguageCreateOrEdit from "../admin/stream/languages/LanguageCreateOrEdit";
import ManageProvidersOrder from "../admin/stream/provider/ManageProvidersOrder";
import ManageDraftStreamPrograms from "../admin/stream/program/ManageDraftStreamPrograms";
import StreamlyBlogs from "../admin/stream/blog/StreamlyBlogs";
import StreamlyBlogCreateOrEdit from "../admin/stream/blog/StreamlyBlogCreateOrEdit";
import Categories from "../admin/category/Categories";
import CreateOrEditCategory from "../admin/category/CreateOrEditCategory";
import ManageRankStreamPrograms from "../admin/stream/program/ManageRankStreamPrograms";


class AdminLayout extends React.Component {

    render() {
        if (isAdmin() || hasFeaturePermissions()) {
            return (<div className="admin-root">
                    <AdminRoutes/>
                    <div className="container">
                        <Switch>
                            <Route exact path='/admin/index' component={AdminIndex}/>
                            <Route exact path='/admin/program/list' component={Programs}/>
                            <Route exact path='/admin/program/import' component={ImportPrograms}/>
                            <Route exact path='/admin/channel/list' component={Channels}/>
                            <Route exact path='/admin/channel/show/:id' component={Show}/>
                            <Route exact path='/admin/content/index' component={PageContent}/>
                            <Route exact path='/admin/blog/list' component={Blogs}/>
                            <Route exact path='/admin/blog/create/' component={CreateOrEdit}/>
                            <Route exact path='/admin/blog/blogSettings' component={BlogSettings}/>
                            <Route exact path='/admin/blog/edit' component={CreateOrEdit}/>
                            <Route exact path='/admin/blog/manageBlogOrders' component={ManageBlogOrders}/>
                            <Route exact path='/admin/user/list' component={Users}/>
                            <Route exact path='/admin/user/edit' component={EditUser}/>
                            <Route exact path='/admin/category/list' component={Categories}/>
                            <Route exact path='/admin/category/edit' component={CreateOrEditCategory}/>
                            <Route exact path='/admin/category/create' component={CreateOrEditCategory}/>
                            <Route exact path='/admin/blogCategory/list' component={BlogCategories}/>
                            <Route exact path='/admin/blogCategory/edit' component={CreateOrEditBlogCategory}/>
                            <Route exact path='/admin/blogCategory/create' component={CreateOrEditBlogCategory}/>
                            <Route exact path='/admin/media/list' component={MediaList}/>
                            <Route exact path='/admin/stream/program/list' component={StreamPrograms}/>
                            <Route exact path='/admin/stream/program/create' component={StreamProgramCreateOrEdit}/>
                            <Route exact path='/admin/stream/program/edit' component={StreamProgramCreateOrEdit}/>
                            <Route exact path='/admin/stream/program/manageDraftPrograms'
                                   component={ManageDraftStreamPrograms}/>
                            <Route exact path='/admin/stream/program/manageRankPrograms'
                                   component={ManageRankStreamPrograms}/>
                            <Route exact path='/admin/stream/season/list' component={StreamSeasons}/>
                            <Route exact path='/admin/stream/season/create' component={SeasonCreateOrEdit}/>
                            <Route exact path='/admin/stream/season/edit' component={SeasonCreateOrEdit}/>
                            <Route exact path='/admin/stream/genre/list' component={StreamGenres}/>
                            <Route exact path='/admin/stream/genre/create' component={GenreCreateOrEdit}/>
                            <Route exact path='/admin/stream/genre/edit' component={GenreCreateOrEdit}/>
                            <Route exact path='/admin/stream/country/list' component={StreamCountries}/>
                            <Route exact path='/admin/stream/country/create' component={CountryCreateOrEdit}/>
                            <Route exact path='/admin/stream/country/edit' component={CountryCreateOrEdit}/>
                            <Route exact path='/admin/stream/provider/list' component={Providers}/>
                            <Route exact path='/admin/stream/provider/create' component={ProviderCreateOrEdit}/>
                            <Route exact path='/admin/stream/provider/manageOrder' component={ManageProvidersOrder}/>
                            <Route exact path='/admin/stream/provider/edit' component={ProviderCreateOrEdit}/>
                            <Route exact path='/admin/stream/language/list' component={StreamLanguages}/>
                            <Route exact path='/admin/stream/language/create' component={LanguageCreateOrEdit}/>
                            <Route exact path='/admin/stream/language/edit' component={LanguageCreateOrEdit}/>
                            <Route exact path='/admin/stream/blog/list' component={StreamlyBlogs}/>
                            <Route exact path='/admin/stream/blog/create' component={StreamlyBlogCreateOrEdit}/>
                            <Route exact path='/admin/stream/blog/edit' component={StreamlyBlogCreateOrEdit}/>

                        </Switch>
                    </div>
                </div>
            )
        }
        return null
    }
}

export default AdminLayout