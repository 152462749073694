import React from 'react';
import {hasFeaturePermissions, isAdmin} from "../../utils/Util";

class IsAdmin extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (isAdmin() || hasFeaturePermissions()) {
            return this.props.children
        }
        return null
    }
}

export default IsAdmin