import React from 'react'
import MyFavouritePrograms from "../program/MyFavouritePrograms";
import IsLoggedIn from "../user/IsLoggedIn";
import LoginHeader from "./LoginHeader";
import MyFavouriteStreamPrograms from "../program/MyFavouriteStreamPrograms";

class FavouritePrograms extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="user-container">

                            <div>
                                <LoginHeader/>
                                <IsLoggedIn>
                                    <h2>Program som du följer</h2>
                                    <MyFavouritePrograms/>
                                    <MyFavouriteStreamPrograms/>
                                </IsLoggedIn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default FavouritePrograms
