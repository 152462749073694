import React from 'react';
import {Button, Form} from 'react-bootstrap';
import loader from '../../../images/loader.gif'

import http from "../../../services/HttpService";
import ToastUtil from "../../../utils/ToastUtil";


class ImportPrograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedFiles: undefined, isInProgress: false}
    }

    onFileChangeHandler = (e) => {
        e.preventDefault();
        this.setState({
            selectedFiles: e.target.files
        });
    };

    upload = (e) => {
        e.preventDefault();
        if (!this.state.isInProgress) {
            let {selectedFiles} = this.state;
            const formData = new FormData();
            this.toggleInProgress(true);
            for (let i = 0; i < selectedFiles.length; i++) {
                console.log(selectedFiles[i].name);
                formData.append('files', selectedFiles[i])
            }
            http
                .post("/api/programs/import", formData)
                .then((response) => {
                    ToastUtil.success("Upload request has been submitted successfully.");
                    console.log(response)
                }).finally(() => {
                this.toggleInProgress(false);
            });
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {

    }

    render() {
        return <div>
            <h2>Import Programs</h2><br/>
            <Form onSubmit={this.upload}>
                <Form.Group>
                    <Form.File id="xmlFiles" name="xmlFiles" label="Select XML Files for Import Programs"
                               multiple="multiple" accept="application/xml"
                               onChange={this.onFileChangeHandler}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Upload
                </Button>
                &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
            </Form>
        </div>
    }
}

export default ImportPrograms