import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import '../Form.scss'

class AddToFavouriteOptionsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    selectOption = (option) => {
        this.props.onFavouriteOptionsSelect(option)
    };

    render() {
        let {program, channel} = this.props;
        return (
            <Modal
                {...this.props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="rerun-modal">
                        <h5>Välj hur programmet ska läggas till i din tablå</h5>
                        <div>
                            <span className={`left-logo largelogo-${channel.sourceId}`}/>
                            <span className="text">{program.name}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="primary" onClick={() => {
                        this.selectOption("INDIVIDUAL_PROGRAM")
                    }}>Enskilt program</Button>&nbsp;
                    <Button variant="primary" onClick={() => {
                        this.selectOption("SERIES")
                    }}>Följetong</Button>

                </Modal.Body>
            </Modal>
        )
    }
}

export default AddToFavouriteOptionsModal