export const WEEKDAYS = {
    0: {
        name: "söndag"
    },
    1: {
        name: "måndag"
    },
    2: {
        name: "tisdag"
    },
    3: {
        name: "onsdag"
    },
    4: {
        name: "torsdag"
    },
    5: {
        name: "fredag"
    },
    6: {
        name: "lördag"
    }
};

export const TIMELINE_LAYOUT = 'timeline';
export const TABLE_LAYOUT = 'default';
export const TOTAL_WEEK_DAYS = 7;
export const CONTENT_PAGES = [
    {name: 'About US', value: 'ABOUT_US'},
    {name: 'Help', value: 'HELP'},
    {name: 'Cookies', value: 'COOKIES'},
    {name: 'Advertisement', value: 'ADVERTISEMENT'},
    {name: 'Sitemap', value: 'SITEMAP'},
    {name: 'Streamly About Us', value: 'STREAMLY_ABOUT_US'},
    {name: 'Streamly Cookies', value: 'STREAMLY_COOKIES'},
    {name: 'Streamly Terms', value: 'STREAMLY_TERMS'},
    {name: 'Dagenstv About US', value: 'DAGENSTV_ABOUT_US'},
    {name: 'Dagenstv Help', value: 'DAGENSTV_HELP'},
    {name: 'Dagenstv Cookies', value: 'DAGENSTV_COOKIES'},
    {name: 'Dagenstv Advertisement', value: 'DAGENSTV_ADVERTISEMENT'},
    {name: 'Dagenstv Sitemap', value: 'DAGENSTV_SITEMAP'},
    {name: 'Dagenstv Terms', value: 'DAGENSTV_TERMS'},
    {name: 'Dagenstv Contact us', value: 'DAGENSTV_CONTACT_US'}
];
export const CLIENTS = [
    {name: 'KollaTV', value: 'KOLLATV'},
    {name: 'Dagenstv', value: 'DAGENSTV'},
];
export const CHANNEL_CHUNK_SIZE = 4;
export const BLOG_CHUNK_SIZE = 4;
export const TABLET_CHANNEL_CHUNK_SIZE = 3;
export const TABLET_BLOG_CHUNK_SIZE = 3;
export const DEFAULT_SIZE = 10;
export const DEFAULT_COUNTRY_CODE = "SE";
export const DEFAULT_STREAM_PROVIDERS = {
    'NETFLIX': {
        provider: 'netflix',
        name: 'Netflix'
    },
    'VIAPLAY': {
        provider: 'viaplay',
        name: 'Viaplay'
    },
    'HBO': {
        provider: 'hbo',
        name: 'HBO'
    },
    'HBO_MAX': {
        provider: 'hbomax',
        name: 'HBO Max'
    }
};
export const FULL_ACCESS_PERMISSION = 'FULL_ACCESS';
export const WRITE_BLOG_PERMISSION = 'WRITE_BLOG';
export const WRITE_STREAMLY_PERMISSION = 'WRITE_STREAMLY';

export const FEATURE_PERMISSIONS = [
    FULL_ACCESS_PERMISSION,
    WRITE_BLOG_PERMISSION,
    WRITE_STREAMLY_PERMISSION,
];