import React from 'react';
import {Breadcrumb, Button, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import ToastUtil from "../../../../utils/ToastUtil";
import loader from '../../../../images/loader.gif'
import SimpleReactValidator from 'simple-react-validator';

class ManageDraftStreamPrograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            countries: [],
            providers: [],
            programTypes: [],
            isInProgress: false,
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    saveDraftProgram = (evt) => {
        evt.preventDefault();
        let {payload} = this.state;
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http.post("/api/es/programs/saveDraftPrograms", payload)
                .then((response) => {
                    let {message} = response.data.content;
                    if (response.data.status) {
                        ToastUtil.success(message)
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }, (error) => {
                    console.log(error)
                }).finally(() => {
                this.toggleInProgress(false)
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    };

    changeHandler = (evt) => {
        this.state.payload[evt.target.name] = evt.target.value;
        this.setState({payload: this.state.payload});
    };

    toggleChangeHandler = (evt) => {
        this.state.payload[evt.target.name] = !this.state.payload[evt.target.name];
        this.setState({payload: this.state.payload});
    };

    loadCountries = () => {
        http.get("/api/es/countries")
            .then((response) => {
                let {countries} = response.data.content;
                this.setState({countries});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadStreamTypes = () => {
        http.get("/api/es/programs/types")
            .then((response) => {
                let {programTypes} = response.data.content;
                this.setState({programTypes});
            }).catch((error) => {
            console.log(error)
        })
    };


    loadProviders = () => {
        http
            .get("/api/es/providers")
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };


    componentDidMount() {
        this.loadCountries();
        this.loadStreamTypes();
        this.loadProviders();
    }

    render() {
        let {payload, countries, programTypes, providers} = this.state;

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/program/list">
                        Programs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Draft</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.saveDraftProgram} method="POST" novalidate={"novalidate"}>
                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Country *</Form.Label>
                                <Form.Control as="select" value={payload.country}
                                              name='country'
                                              onChange={this.changeHandler}>
                                    <option value=''>Select</option>
                                    {
                                        countries.map((country, index) => {
                                            return <option value={country.code}
                                                           key={index}>
                                                {country.name}
                                            </option>

                                        })
                                    }
                                </Form.Control>
                                {this.validator.message('Country', payload.country, 'required')}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Provider *</Form.Label>
                                <Form.Control as="select" value={payload.provider}
                                              name='provider'
                                              onChange={this.changeHandler}>
                                    <option value=''>All Providers</option>
                                    {
                                        providers.map((provider, index) => {
                                            return <option value={provider.name}
                                                           key={index}>
                                                {provider.label}
                                            </option>

                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Program Type *</Form.Label>
                                <Form.Control as="select" value={payload.streamType}
                                              name='streamType'
                                              onChange={this.changeHandler}>
                                    <option value=''>All Program Type</option>
                                    {
                                        programTypes.map((programType, index) => {
                                            return <option value={programType.label}
                                                           key={index}>
                                                {programType.name}
                                            </option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Active All Draft Programs
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </Row>

            </div>
        )
    }
}

export default ManageDraftStreamPrograms