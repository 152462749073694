import React from 'react';
import {Breadcrumb, Col, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import {ellipsis, prettyBool} from "../../../../utils/Util";

class StreamGenres extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            genres: [],
        }
    }


    list = () => {
        http.get("/api/es/genres")
            .then((response) => {
                let {genres, totalElements} = response.data.content;
                this.setState({genres, totalElements});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
        })
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {genres, totalElements} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Genres</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        <a href="/admin/stream/genre/create" className="btn btn-primary btn-sm">Create Genre</a>
                        &nbsp;
                    </Col>
                </Row>
                <br/>
                <div>
                    <p className="font-weight-bold">
                        Total Genre : {totalElements}
                    </p>
                </div>
                <div>
                    <table className="table">
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Swedish Name</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                        {genres.map(function (genre, index) {
                            return <tr key={index}>
                                <td>{genre.id}</td>
                                <td>
                                    <a href={"/admin/stream/genre/edit?id=" + genre.id}>{ellipsis(genre.name, 150)}</a>
                                </td>
                                <td>{genre.label}</td>
                                <td className={genre.active ? 'admin-active' : 'admin-inactive'}>{prettyBool(genre.active)}</td>
                                <td>
                                    <a href={"/admin/stream/genre/edit?id=" + genre.id}
                                       className="btn btn-sm btn-primary">Edit</a>
                                    &nbsp;
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </div>
        )
    }
}

export default StreamGenres