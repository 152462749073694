import React from 'react'
import {getDay, getMyPrograms, isLoggedIn} from "../../utils/Util";
import http from "../../services/HttpService";
import {toQueryString} from "../../utils/UrlUtil";
import moment from 'moment'
import 'moment/locale/sv'

class PrintMyPrograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myPrograms: [],
            selectedDate: moment(),
        }
    }

    loadMyPrograms = () => {
        if (isLoggedIn()) {
            let params = {day: getDay()};
            http.get("/api/public/programs/me" + toQueryString(params))
                .then((response) => {
                    this.setState({
                        myPrograms: response.data.content.seasons
                    });
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
        } else {
            this.setState({
                myPrograms: getMyPrograms()
            });
        }
    };

    componentDidMount() {
        this.setState({selectedDate: moment().add(getDay(), "days")});
        this.loadMyPrograms();
    }

    render() {
        let {myPrograms, selectedDate} = this.state;
        return (
            <div>
                <h2>Min Tablå {selectedDate.format("dddd DD MMMM, yyyy")}</h2>
                <table>
                    <tr>
                        <th>Tid</th>
                        <th>Program</th>
                        <th>Kanal</th>
                    </tr>
                    {
                        myPrograms.map((program, index) => {
                            return <tr>
                                <td>{program.formattedStartTime} - {program.formattedEndTime}</td>
                                <td>{program.name}</td>
                                <td>{program.channel.name}</td>
                            </tr>
                        })
                    }
                </table>
            </div>
        );
    }
}

export default PrintMyPrograms