import React from "react";
import ReactPlayer from "react-player/youtube";
import {Modal} from 'react-bootstrap';

class StreamPlay extends React.Component {
    constructor() {
        super();
        this.state = {};
    }


    componentDidMount() {
    }

    render() {
        let {program} = this.props;
        return (
            <div className="">
                <Modal
                    {...this.props}
                    size="lg"
                    dialogClassName="stream-play"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="player-container">
                        <ReactPlayer controls={true}
                            className="react-player"
                            url={program.videoUrl}
                        />
                        </div>
                        
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

export default StreamPlay;
