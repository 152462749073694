import React from 'react';
import {Link} from 'react-router-dom';
import {FaLock, FaUser} from 'react-icons/fa';
import IsLoggedIn from '../components/user/IsLoggedIn';
import IsNotLoggedIn from '../components/user/IsNotLoggedIn';
import './header/Header.scss'
import Login from "./user/Login";
import IsAdmin from "./user/IsAdmin";
import {isFilmPage, isSeriesPage, isSportsPage} from "../utils/Util";
import {getParams, toQueryString} from "../utils/UrlUtil";
import _ from 'lodash'

class TopNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false
        }
    }

    openLogin = (evt) => {
        evt.preventDefault();
        this.setState({showLogin: true})
    };

    closeLogin = () => {
        this.setState({showLogin: false})
    };

    getUrlWithParams = (baseUrl) => {
        let urlParams = getParams();
        let params = {};
        if (urlParams.get("day")) {
            params.day = urlParams.get("day")
        }
        if (urlParams.get("week")) {
            params.week = urlParams.get("week")
        }
        if (_.size(params) > 0) {
            return baseUrl + toQueryString(params)
        }
        return baseUrl;
    };

    render() {
        return (
            <div>
                <nav className="navbar">

                    <a className="" href="/Standard" title="Kolla.tv - Tips från oss på filmer">Start</a>
                    <a className={`${isFilmPage() ? 'film-selected' : ''}`}
                       href={this.getUrlWithParams("/Film")} title="Kolla.tv - Film">Film</a>
                    <a className={`${isSeriesPage() ? 'series-selected' : ''}`}
                       href={this.getUrlWithParams("/Tv-serier")}
                       title="Kolla.tv - TV-Serier">Serier</a>
                    <a className={`${isSportsPage() ? 'sport-selected' : ''}`} href={this.getUrlWithParams("/Sport")}
                       title="Kolla.tv - Sport">Sport</a>
                    <a className="" target="_self" href="/Stream/Home">Play</a>
                    <a className="" href="/Artiklar" title="Kolla.tv - Artiklar">Artiklar</a>

                    <span className="user-link">
                    <a href="/MinaKanaler" className="user-link">
                        <IsLoggedIn>
                            <FaUser/>
                        </IsLoggedIn>
                        <IsNotLoggedIn>
                            <FaLock/>
                        </IsNotLoggedIn>
                        <span>Min sida</span>
                    </a>
                        <span className="dropdown">
                            <IsLoggedIn>
                                <IsAdmin>
                                    <a href="/admin/index" target="_blank">Admin</a>
                                </IsAdmin>
                                <Link to="/logout">Logout</Link>
                            </IsLoggedIn>
                            <IsNotLoggedIn>
                                <a href="/login" onClick={this.openLogin}>Login</a>
                            </IsNotLoggedIn>
                        </span>
                    </span>


                </nav>
                <Login show={this.state.showLogin} onHide={this.closeLogin}/>

            </div>
        )
    }
}

export default TopNavigation