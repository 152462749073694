import React, {useEffect, useState} from "react";
import {getParams, redirectTo} from "../../utils/UrlUtil";
import http from "../../services/HttpService";
import "./blogListing.scss";
import ReactPaginate from 'react-paginate';
import {BLOG_CHUNK_SIZE, TABLET_BLOG_CHUNK_SIZE} from "../../constants/constants";
import _ from 'lodash'
import {isTablet} from "react-device-detect";
import {ellipsis, getAdsLabel} from "../../utils/Util";
import {isDesktop, isMobileDevice, isTableDevice} from "../../utils/DeviceUtil";

const Blogs = (props) => {
    const DEFAULT_BLOG_SIZE = 16;
    const params = getParams();
    const [blogs, setBlogs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const sorting = props.sorting;
    const selectedPage = params.get("page") || 0;
    const size = params.get("size") || DEFAULT_BLOG_SIZE;
    let screenWidth = window.screen.width;
    const blogChunkSize = isTablet && screenWidth >= 768 && screenWidth <= 970 ? TABLET_BLOG_CHUNK_SIZE : BLOG_CHUNK_SIZE;

    const loadBlogs = () => {
        let params = getParams();
        params.set("sorting", sorting);
        params.set("active", true);
        params.set("size", size);
        params.set("withContent", false);
        params.set("client", 'KOLLATV');
        http
            .get("/api/blogs?" + params.toString())
            .then((response) => {
                let {blogs, totalElements} = response.data.content;
                setBlogs(blogs);
                setTotalElements(totalElements);
                setPageCount(Math.ceil(totalElements / DEFAULT_BLOG_SIZE))
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    };

    useEffect(() => {
        for (let i = 1; i <= 40; i++) {
            blogs.push({category: {}})
        }
        setBlogs(blogs);
        loadBlogs();
    }, []);

    const onPageChange = (data) => {
        let selectedPage = data.selected;
        let params = getParams();
        params.set("page", selectedPage);
        window.location.href = "/Artiklar?" + params.toString();
    };

    return (
        <div>
            {
                _.chunk(blogs, blogChunkSize).map((blogsSubList, parentIndex) => {
                    return <div key={parentIndex}>
                        <ul className="blog-listing">
                            {
                                blogsSubList.map((blog, index) => {
                                    return (
                                        <li className="item cursor-pointer" key={index}
                                            onClick={() => redirectTo('/artikel/' + blog.friendlyUrl)}>
                                            <div className="">
                                                <img src={blog.thumbnailUrl} className="image"/>
                                            </div>

                                            <div className="info">
                                                <h3>{blog.teaser}</h3>
                                                <span>KollaTv | {blog.category.name}</span>
                                                <p>{ellipsis(blog.title, 100)}</p>
                                                <a href={`/artikel/${blog.friendlyUrl}`}>LÄS MER</a>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        {/*Display second ads */}
                        {parentIndex === 0 ? <div className="blog-ads">
                            {getAdsLabel()}
                            {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                            {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                            {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                        </div> : null}
                    </div>
                })
            }

            {blogs.length > 0 && pageCount > 1 ? <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={parseInt(pageCount)}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                initialPage={parseInt(selectedPage)}
                disableInitialCallback={true}
                activeClassName={'active'}

            /> : null}
            <div className="blog-ads">
                {getAdsLabel()}
                {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                {isMobileDevice() ? <div id="cncpt-mob3"></div> : ''}
            </div>
        </div>
    );
};

export default Blogs;
