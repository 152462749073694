import React from 'react';
import {Modal} from 'react-bootstrap';
import Medias from "./Medias";

class MediasModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Medias {...this.props}/>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default MediasModal