import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import loader from '../../../../images/loader.gif'
import SimpleReactValidator from 'simple-react-validator';
import {toQueryString} from "../../../../utils/UrlUtil";
import {ellipsis, getSetting, isArticle} from "../../../../utils/Util";
import {format} from "../../../../utils/DateUtil";

class ManageRankStreamPrograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            countries: [],
            providers: [],
            programTypes: [],
            isInProgress: false,
            programs: [],
            totalElements: 0
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    list = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.toggleInProgress(true);
        let {payload} = this.state
        payload.topList = true
        payload.sorting = "latest"
        payload.size = 20
        http.get("/api/es/programs" + toQueryString(payload))
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                let pageCount = Math.ceil(totalElements / 10);
                this.setState({programs, totalElements, pageCount});
                console.log(programs)
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };


    changeHandler = (evt) => {
        this.state.payload[evt.target.name] = evt.target.value;
        this.setState({payload: this.state.payload});
    };

    changeRankHandler = (index, evt) => {
        let {programs} = this.state;
        programs[index].newRank = evt.target.value;
        this.setState({programs});
    }

    toggleChangeHandler = (evt) => {
        this.state.payload[evt.target.name] = !this.state.payload[evt.target.name];
        this.setState({payload: this.state.payload});
    };

    loadCountries = () => {
        http.get("/api/es/countries")
            .then((response) => {
                let {countries} = response.data.content;
                this.setState({countries});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadStreamTypes = () => {
        http.get("/api/es/programs/types")
            .then((response) => {
                let {programTypes} = response.data.content;
                this.setState({programTypes});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadProviders = () => {
        http
            .get("/api/es/providers")
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    saveRank = () => {
        let {programs, payload} = this.state;
        let ranks = programs
            .filter((program) => program.newRank > 0)
            .map((program) => {
                return {
                    id: program.id,
                    rank: program.newRank
                }
            });
        http
            .post("/api/es/programs/saveStreamsRank", {
                country: payload.country,
                ranks: ranks
            })
            .then((response) => {
                this.list()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    getEditUrl = (program) => {
        if (isArticle(program)) {
            return "/admin/stream/blog/edit?id=" + program.id
        }
        return "/admin/stream/program/edit?id=" + program.id
    };


    componentDidMount() {
        this.loadCountries();
        this.loadStreamTypes();
        this.loadProviders();
    }

    render() {
        let {payload, countries, programTypes, providers, programs} = this.state;

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/program/list">
                        Programs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Rank</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.list} method="POST" novalidate={"novalidate"}>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className={"font-weight-bold"}>Country *</Form.Label>
                                        <Form.Control as="select" value={payload.country}
                                                      name='country'
                                                      onChange={this.changeHandler}>
                                            <option value=''>Select</option>
                                            {
                                                countries.map((country, index) => {
                                                    return <option value={country.code}
                                                                   key={index}>
                                                        {country.name}
                                                    </option>

                                                })
                                            }
                                        </Form.Control>
                                        {this.validator.message('Country', payload.country, 'required')}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className={"font-weight-bold"}>Provider *</Form.Label>
                                        <Form.Control as="select" value={payload.provider}
                                                      name='providers'
                                                      onChange={this.changeHandler}>
                                            <option value=''>All Providers</option>
                                            {
                                                providers.map((provider, index) => {
                                                    return <option value={provider.name}
                                                                   key={index}>
                                                        {provider.label}
                                                    </option>

                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className={"font-weight-bold"}>Program Type *</Form.Label>
                                        <Form.Control as="select" value={payload.streamType}
                                                      name='streamType'
                                                      onChange={this.changeHandler}>
                                            <option value=''>All Program Type</option>
                                            {
                                                programTypes.map((programType, index) => {
                                                    return <option value={programType.name}
                                                                   key={index}>
                                                        {programType.label}
                                                    </option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit">
                                Search
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </Row>
                <br/>
                <Row>
                    <div className={"col-md-12"}>
                        <table className="table table-sm">
                            <tr>
                                <th>Title</th>
                                <th>Added At</th>
                                <th>Current Rank</th>
                                <th>New Rank</th>
                            </tr>
                            {programs.map((program, index) => {
                                return <tr key={index}>
                                    <td>
                                        <a href={`${this.getEditUrl(program)}?id=${program.id}`}>{ellipsis(program.title, 150)}</a>
                                    </td>
                                    <td>{format(program.recentlyAdded, "DD-MM-YYYY hh:mm a")}</td>
                                    <td>
                                        {getSetting(program, payload.country).rank}
                                    </td>
                                    <td>
                                        <Form.Control type="text" placeholder="New Rank" name="rank"
                                                      value={program.newRank}
                                                      onChange={(e) => {
                                                          this.changeRankHandler(index, e);
                                                      }
                                                      }/>
                                    </td>
                                </tr>
                            })}
                        </table>


                    </div>
                </Row>
                {programs.length > 0 ? <Row>
                    <Col>
                        <Button variant="primary" type="button" onClick={this.saveRank}>
                            Save Rank
                        </Button>
                    </Col>
                </Row> : null}

            </div>
        )
    }
}

export default ManageRankStreamPrograms