import React from 'react';
import {Breadcrumb, Col, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import _ from 'lodash'
import {getParams} from "../../../utils/UrlUtil";

class ManageBlogOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            totalElements: 0,
            isInProgress: false
        }
    }


    loadBlogs = () => {
        this.toggleInProgress(true);
        let params = getParams();
        params.set("size", 1000);
        params.set("withContent", false);
        http.get("/api/blogs?" + params.toString())
            .then((response) => {
                let {blogs, totalElements} = response.data.content;
                this.setState({blogs: blogs, totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    changeHandler = (evt) => {
        this.setState({[evt.target.name]: this.state.value});
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    onBlogsDrag = (newBlogs) => {
        _.forEach(newBlogs, (blog, index) => {
            blog.tabIndex = index
        });
        this.setState({blogs: newBlogs});
    };

    saveBlogsOrder = () => {
        let {blogs} = this.state;
        let ordersModel = _.map(blogs, (blog) => {
            return {
                blogId: blog.id,
                tabIndex: blog.tabIndex
            }
        });
        http.post("/api/blogs/saveBlogOrders", {
            orders: ordersModel
        }).then((response) => {
            window.location.reload();
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    componentDidMount() {
        this.loadBlogs();
    }

    render() {
        let {blogs} = this.state;
        blogs = _.orderBy(blogs, "tabIndex");

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/blog/list">Blogs</Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Order</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <Col>
                        <input type={"button"} value={"Save"}
                               onClick={this.saveBlogsOrder}
                               className="btn btn-primary btn-sm"/>
                    </Col>
                </Row>
                <br/>

                <div>
                    {
                        <RLDD items={blogs} onChange={this.onBlogsDrag}
                              itemRenderer={(blog) => {
                                  return <div className="row table-view cursor-pointer">
                                      <div className="col-md-2">{blog.tabIndex}</div>
                                      <div className="col-md-5">
                                          {blog.teaser}
                                      </div>
                                  </div>
                              }
                              }/>
                    }
                </div>
                <br/>
                <Row>
                    <Col>
                        <input type={"button"} value={"Save"}
                               onClick={this.saveBlogsOrder}
                               className="btn btn-primary btn-sm"/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ManageBlogOrders