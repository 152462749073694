import React from 'react';
import {Breadcrumb} from 'react-bootstrap';
import StreamSeasonsTemplate from "./StreamSeasonsTemplate";

class StreamSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Tv-show or Seasons</Breadcrumb.Item>
                </Breadcrumb>
                <StreamSeasonsTemplate/>
            </div>
        )
    }
}

export default StreamSeasons