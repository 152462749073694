import React from 'react';
import {Alert, Button, Form, Row} from 'react-bootstrap';
import '../Form.scss'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import loader from '../../images/loader.gif'
import {postLoginRegister} from "../../utils/Util";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstName: '',
            lastName: '',
            password: '',
            isInProgress: false,
            errors: [],
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    doRegister = (event) => {
        event.preventDefault();
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http
                .post("/api/users/register", this.state)
                .then((response) => {
                    let {content, status, errors} = response.data;
                    if (status) {
                        localStorage.setItem("user", JSON.stringify(content.user));
                        localStorage.setItem("jtoken", content.token);
                        localStorage.setItem("isNewUser", true);
                        postLoginRegister("/");
                    } else {
                        this.setState({errors: errors})
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 register-form">
                        <h2 className="header">Registrera dig och skapa din egen kanallista</h2>
                        <Form className="form" onSubmit={this.doRegister} method="POST">
                            <Row>
                                <Form.Control name="firstName" onChange={this.changeHandler} placeholder="Förnamn"/>
                                {this.validator.message('firstName', this.state.firstName, 'required')}
                            </Row>
                            <Row>
                                <Form.Control name="lastName" onChange={this.changeHandler} placeholder="Efternamn"/>
                                {this.validator.message('lastName', this.state.lastName, 'required')}

                            </Row>
                            <Row>
                                <Form.Control name="username" type="email" onChange={this.changeHandler}
                                              placeholder="E-postadress"/>
                                {this.validator.message('email', this.state.username, 'required|email')}

                            </Row>
                            <Row>
                                <Form.Control name="password" type="password" onChange={this.changeHandler}
                                              placeholder="Lösenord"/>
                                {this.validator.message('password', this.state.password, 'required')}
                            </Row>
                            {
                                this.state.errors.map((error, index) => {
                                    return <Alert key={index} variant={'danger'}>
                                        {error}
                                    </Alert>
                                })
                            }

                            <Button variant="primary" type="submit">
                                Skapa konto
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </div>


            </div>
        )
    }
}

export default Register