import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import loader from '../../../../images/loader.gif'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams} from "../../../../utils/UrlUtil";


class SeasonCreateOrEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            season: {},
            isInProgress: false,
            providers: []
        }
    }

    save = (evt) => {
        evt.preventDefault();
        let {season} = this.state;
        this.toggleInProgress(true);
        http.post("/api/es/seasons", {
            id: season.id,
            name: season.name,
            provider: season.provider,
            sourceReference: season.sourceReference,
            number: season.number,
            active: season.active,
            videoUrl: season.videoUrl,
            latest: season.latest
        })
            .then((response) => {
                let {season} = response.data.content;
                if (response.data.status) {
                    window.location.href = "/admin/stream/season/edit?id=" + season.id;
                } else {
                    ToastUtil.error(response.data.errors[0]);
                }
            }, (error) => {
                console.log(error)
            }).finally(() => {
            this.toggleInProgress(false)
        })

    };

    changeHandler = (evt) => {
        this.state.season[evt.target.name] = evt.target.value;
        this.setState({season: this.state.season});
    };

    toggleChangeHandler = (evt) => {
        this.state.season[evt.target.name] = !this.state.season[evt.target.name];
        this.setState({season: this.state.season});
    };

    loadSeason = (id) => {
        if (id) {
            http.get("/api/es/seasons/" + id)
                .then((response) => {
                    let {season = {}} = response.data.content;
                    this.setState({season});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    loadProviders = () => {
        http.get("/api/es/providers")
            .then((response) => {
                let {providers = []} = response.data.content;
                this.setState({providers});
            }).catch((error) => {
            console.log(error)
        })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.loadSeason(getParams("id"));
        this.loadProviders()
    }

    render() {
        let {season, providers} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/season/list">
                        Tv-show or Seasons
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.save}>
                            <Row>
                                <div className="col-md-5">
                                    <Form.Group controlId="name">
                                        <Form.Label className={"font-weight-bold"}>Season Full Name
                                            *</Form.Label>
                                        <Form.Control type="text" placeholder="e.g Game Of Thrones, Season 1"
                                                      name='name' required="required"
                                                      value={season.name} onChange={this.changeHandler}/>
                                        <Form.Text className="text-muted">
                                            Maximum characters allowed 255.
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2">
                                    <Form.Group controlId="number">
                                        <Form.Label className={"font-weight-bold"}>Number *</Form.Label>
                                        <Form.Control type="text" placeholder="number" name='number' required="required"
                                                      value={season.number} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </div>
                                <div className="col-md-5">
                                    <Form.Group controlId="provider">
                                        <Form.Label className={"font-weight-bold"}>Provider *</Form.Label>
                                        <Form.Control as="select" value={season.provider} name='provider'
                                                      required="required"
                                                      onChange={this.changeHandler}>
                                            <option value=''>Select Provider</option>
                                            {
                                                providers.map((provider, index) => {
                                                    return <option value={provider.name}
                                                                   key={index}>{provider.label}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-5">
                                    <Form.Group controlId="sourceReference">
                                        <Form.Label className={"font-weight-bold"}>Source Reference URL *</Form.Label>
                                        <Form.Control type="text" placeholder="sourceReference" name='sourceReference'
                                                      required="required"
                                                      value={season.sourceReference} onChange={this.changeHandler}/>
                                        <Form.Text className="text-muted">
                                            Enter Tv-show or Series Detail URL from selected provider
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                {season.sourceId ? <div className="col-md-2">
                                    <Form.Group controlId="sourceId">
                                        <Form.Label className={"font-weight-bold"}>SourceId</Form.Label>
                                        <Form.Control disabled={"disabled"} type="text" placeholder="SourceId"
                                                      name='sourceId'
                                                      required="required"
                                                      value={season.sourceId} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </div> : null}
                                {season.scrapeId ? <div className="col-md-5">
                                    <Form.Group controlId="scrapeId">
                                        <Form.Label className={"font-weight-bold"}>ScrapeId</Form.Label>
                                        <Form.Control disabled={"disabled"} type="text" placeholder="scrapeId"
                                                      name='scrapeId'
                                                      value={season.scrapeId} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </div> : null}
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="videoUrl">
                                        <Form.Label className={"font-weight-bold"}>Youtube URL</Form.Label>
                                        <Form.Control type="text" placeholder="Youtube URL"
                                                      name='videoUrl'
                                                      value={season.videoUrl} onChange={this.changeHandler}/>
                                        {season.name ?
                                            <a href={`https://www.youtube.com/results?search_query=${season.name} trailer`}
                                               target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                               type="button">
                                                Search in Youtube
                                            </a> : null}
                                    </Form.Group>
                                </Col>
                                {season.id ? <Col>
                                    <Form.Group controlId="friendlyUrl">
                                        <Form.Label className={"font-weight-bold"}>Friendly Url</Form.Label>
                                        <Form.Control type="text" disabled={"disabled"}
                                                      name='friendlyUrl'
                                                      value={season.friendlyUrl}/>
                                    </Form.Group>
                                </Col> : null}
                            </Row>

                            <Row>
                                <div className="col-md-2">
                                    <Form.Group controlId="active">
                                        <Form.Check type="checkbox" label="Active" name='active'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={season.active ? 'checked' : ''}/>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2">
                                    <Form.Group controlId="latest">
                                        <Form.Check type="checkbox" label="Latest" name='latest'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={season.latest ? 'checked' : ''}/>
                                    </Form.Group>
                                </div>
                            </Row>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </Row>
            </div>
        )
    }
}

export default SeasonCreateOrEdit