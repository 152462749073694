import React from "react";
import Programs from '../program/Programs';
import '../MainContent.scss';
import '../../components/common.scss';
import '../../components/program/ProgramDetail.scss';
import '../program/TableLayout.scss'
import http from '../../services/HttpService'
import {getParams, toQueryString} from '../../utils/UrlUtil'
import _ from 'lodash'
import {isTablet} from "react-device-detect";
import {CHANNEL_CHUNK_SIZE, TABLE_LAYOUT, TABLET_CHANNEL_CHUNK_SIZE, TOTAL_WEEK_DAYS} from "../../constants/constants";
import {
    getAdsLabel,
    getChannelLogo,
    getLayoutWithDefaultValue,
    getSelectedDay,
    getSelectedWeek,
    getTimelineDurations,
    isChannelDetailPage,
    isChannelSearchPage,
    isFilmPage,
    isLoggedIn,
    isSeriesPage,
    isSportsPage
} from "../../utils/Util";
import moment from 'moment'
import ProgramModalContext from '../context/ProgramModalContext';
import {
    doUpdateChannelsOrder,
    getChannelsOrder,
    getIncludeChannels,
    getMyChannelSettings,
    getSelectedCategoryId,
    getSelectedChannelId
} from '../../services/ChannelService'
import loader from '../../images/loaderLarge.gif'
import {Helmet} from "react-helmet";
import '../channel/channel.scss';
import {isDesktop, isMobileDevice, isTableDevice} from "../../utils/DeviceUtil";

class Channels extends React.Component {
    static contextType = ProgramModalContext;

    constructor(props) {
        super(props);
        let layout = getLayoutWithDefaultValue();
        let channels = [];
        for (let i = 0; i <= 120; i++) {
            channels.push({ programs: [] })
        }
        this.state = {
            channels: channels,
            layout: layout,
            selectedProgram: {},
            selectedChannel: {},
            showProgramDetail: false,
            hasMoreChannels: false,
            myChannelSettings: {},
            rowScrollMargin: 0,
            searchChannels: [],
            searchCategories: []
        };
    }

    onChannelSettingsUpdate = (myChannelSettings) => {
        if (isLoggedIn()) {
            this.setState({ myChannelSettings: myChannelSettings });
        } else {
            this.forceUpdate();
        }
    };

    getTimelineHeader = () => {
        return getTimelineDurations(49)
    };

    _getChannelsOrder = () => {
        if (isLoggedIn()) {
            return this.state.myChannelSettings.orders;
        } else {
            return getChannelsOrder()
        }
    };

    loadChannelWithPrograms = (page, includeChannels) => {
        let selectedDay = getSelectedDay();
        let params = getParams();
        let selectedWeek = getSelectedWeek();
        let day = (selectedWeek - 1) * TOTAL_WEEK_DAYS + selectedDay;
        params.set("page", page);
        params.set("day", day);
        params.set("active", true);

        if (page === 0) {
            let found = false;
            let rowScrollMargin = 0;
            _.forEach(this.getTimelineHeader(), (timeline) => {
                if (!found) {
                    if (!timeline.isActive) {
                        rowScrollMargin -= 140;
                    } else {
                        found = true;
                    }
                }
            });
            this.setState({ rowScrollMargin: rowScrollMargin });
        }

        if (_.size(includeChannels) > 0 && !(isChannelSearchPage() || isChannelDetailPage())) {
            params.set("includeChannels", includeChannels);
        }

        if (params.get("q")) {
            params.set("q", getParams("q"))
        }

        if (params.get("categoryId")) {
            params.set("categoryId", getParams("categoryId"))
        }

        if (params.get("channel")) {
            params.set("channel", getParams("channel"))
        }

        if (this.props.type) {
            params.set("type", this.props.type)
        }

        if (this.props.channelFriendlyUrl) {
            params.set("channelFriendlyUrl", this.props.channelFriendlyUrl)
        }

        http.get("/api/es/channels/listWithPrograms?" + params.toString())
            .then((response) => {
                let { channels, hasMoreElements } = response.data.content;
                if (!_.some(this.state.channels, "id")) {
                    this.state.channels = [];
                }
                _.forEach(channels, (channel) => {
                    channel.rowScrollMargin = this.state.rowScrollMargin;
                });
                this.setState({
                    channels: _.concat(this.state.channels, channels),
                    hasMoreChannels: hasMoreElements
                });
                if (hasMoreElements) {
                    this.loadChannelWithPrograms(page + 1, includeChannels)
                }
            })
            .catch(function (error) {
                console.log(error.data)
            })
    };

    loadChannelsAndCategories = () => {
        let params = {
            q: getParams("q"),
            active: true
        };

        http.get("/api/programs/channelsAndCategory" + toQueryString(params))
            .then((response) => {
                let { channels, categories } = response.data.content;
                this.setState({
                    searchChannels: channels,
                    searchCategories: categories
                });
            })
            .catch(function (error) {
                console.log(error.data)
            })
    };


    scrollAllToNextHour = () => {
        let { rowScrollMargin } = this.state;
        if (rowScrollMargin > -7200) {
            _.forEach(this.state.channels, (channel) => {
                this.scrollToNextHour(channel)
            });
            this.setState({ rowScrollMargin: this.state.channels[0].rowScrollMargin })
        }
    };

    scrollAllToPrevHour = () => {
        let { rowScrollMargin } = this.state;
        if (rowScrollMargin < 0) {
            _.forEach(this.state.channels, (channel) => {
                this.scrollToPrevHour(channel)
            });
            this.setState({ rowScrollMargin: this.state.channels[0].rowScrollMargin })
        }
    };

    scrollToNextHour = (channel) => {
        let rowScrollMargin = channel.rowScrollMargin || 0;
        rowScrollMargin -= 142;
        channel.rowScrollMargin = rowScrollMargin;
        this.forceUpdate();
    };

    scrollToPrevHour = (channel) => {
        let rowScrollMargin = channel.rowScrollMargin || 0;
        rowScrollMargin += 142;
        channel.rowScrollMargin = rowScrollMargin;
        this.forceUpdate();
    };

    componentDidMount() {
        let includeChannels = _.map(getIncludeChannels(), "id");
        if (isChannelSearchPage()) {
            this.loadChannelsAndCategories();
        }
        if (isLoggedIn()) {
            getMyChannelSettings((myChannelSettings) => {
                this.onChannelSettingsUpdate(myChannelSettings);
                if (myChannelSettings.includeChannelIds) {
                    includeChannels = myChannelSettings.includeChannelIds;
                }
                this.loadChannelWithPrograms(0, includeChannels);
            });
        } else {
            this.loadChannelWithPrograms(0, includeChannels);
        }
    }

    getCategoryHeading = () => {
        let heading = 'Sveriges största TV-kanaler';
        if (isFilmPage()) {
            heading = 'Tips från oss på filmer';
        } else if (isSeriesPage()) {
            heading = 'Tips från oss på serier';
        } else if (isChannelSearchPage()) {
            heading = '';
        } else if (isSportsPage()) {
            heading = 'Sportartiklar';
        }
        return heading;
    }

    tableLayout = () => {
        let allChannels = this.state.channels;
        let { hasMoreChannels } = this.state;
        doUpdateChannelsOrder(allChannels, this._getChannelsOrder());
        allChannels = _.sortBy(allChannels, "tabIndex");
        let screenWidth = window.screen.width;
        let channelChunkSize = isTablet && screenWidth >= 768 && screenWidth <= 970 ? TABLET_CHANNEL_CHUNK_SIZE : CHANNEL_CHUNK_SIZE;

        return (<div className={isChannelDetailPage() ? 'channel-detail-page' : ''}>
            {/* <h2>{this.getCategoryHeading()}</h2> */}
            {
                _.chunk(allChannels, channelChunkSize).map((channels, parentIndex) => {
                    return <div key={parentIndex}>
                        <ul className={`${parentIndex === 0 ? 'first-section' : ''} ${isChannelDetailPage() ? 'channel-detail-list' : ''} program-list row`}>{
                            channels.map((channel, index) => {
                                return (
                                    <li key={index * (parentIndex + 1)}
                                        className="channel col-6 col-sm-6 col-md-4 col-lg-3 item">
                                        <div className="top-logo">
                                            {getChannelLogo(channel, "channel-logo")}
                                            <h2 className="name">
                                                <a href={"/kanal/" + channel.friendlyUrl}
                                                    title={channel.name}>{channel.name}</a>
                                            </h2>
                                        </div>
                                        <Programs channel={channel}
                                            onlyNonExpired={parentIndex > 1}
                                            programs={channel.programs}
                                            initSize={parentIndex < 2 ? 0 : 5} />
                                    </li>
                                )
                            })
                        }
                        </ul>
                        {/*Display second ads */}
                        {parentIndex === 0 ? <div className="grid-add-container">
                            {getAdsLabel()}
                            {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                            {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                            {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                        </div> : null}

                        {/*TODO display third ads*/}
                        {parentIndex === 3 || !hasMoreChannels && parentIndex > 0 && _.size(allChannels) < channelChunkSize * 4 ?
                            <div className="grid-add-container sticky-ads3">
                                {getAdsLabel()}
                                {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                                {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                                {isMobileDevice() ? <div id="cncpt-mob3" className="desktopNone"></div> : ''}
                            </div> : null}
                    </div>
                })
            }

        </div>
        )
    };

    timelineLayout = () => {
        let allChannels = this.state.channels;
        doUpdateChannelsOrder(allChannels, this._getChannelsOrder());
        allChannels = _.sortBy(allChannels, "tabIndex");
        return (
            <div className="container">
                <div className="row table-layout">
                    <div className=" top-bar">
                        <span className="prev-btn" onClick={this.scrollAllToPrevHour} />
                        <div className="middle-content" style={{ 'left': `${this.state.rowScrollMargin}px` }}>
                            {
                                this.getTimelineHeader().map((timeline, index) => {
                                    return <span
                                        className={`${timeline.date.minute() === 30 ? 'small' : ''} ${timeline.isActive ? 'hourNow' : ''}`}>
                                        {timeline.duration}
                                    </span>
                                })
                            }
                        </div>
                        <span className="next-btn" onClick={this.scrollAllToNextHour} />
                    </div>

                    <div className="channel-list">
                        <ul>
                            <li>
                                <div className="middle-content">
                                    {
                                        allChannels.map((channel, index) => {
                                            return (
                                                <div key={channel.id} className="channel col-xs-12 item">

                                                    <div className="left-logo">
                                                        {getChannelLogo(channel, "channel-logo")}
                                                    </div>
                                                    <div className="timeline-channel-list">
                                                        <span className="prev-btn"
                                                            onClick={() => this.scrollToPrevHour(channel)} />

                                                        <Programs channel={channel} programs={channel.programs}
                                                            rowScrollMargin={channel.rowScrollMargin}
                                                            onlyNonExpired={false}
                                                            initSize={0} />


                                                        <span className="next-btn"
                                                            onClick={() => this.scrollToNextHour(channel)} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>



                </div>
            </div>
        )
    };

    searchLayout = () => {
        let allChannels = this.state.channels;
        let { searchChannels, searchCategories } = this.state;
        return <div className="search-result">
            <div className="action-bar">
                <span className="heading">Sökresultat</span>
                <div className="filters">
                    <span className="label">Visa</span>
                    <select name='channelId' className="category-drop-down"
                        value={`${getSelectedChannelId()}`}
                        onChange={this.onChannelChange}>
                        <option value="Select Channel">Alla kanaler</option>
                        {
                            searchChannels.map((channel, index) => {
                                return <option key={index} value={channel.id}>
                                    {channel.name}
                                </option>
                            })
                        }
                    </select>

                    <select name='categoryId' className="category-drop-down"
                        value={getSelectedCategoryId()}
                        onChange={this.onCategoryChange}>
                        <option value="Select Category">Alla kategorier</option>
                        {
                            searchCategories.map((category, index) => {
                                return <option key={index} value={category.id}>
                                    {category.name}
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>
            <ul className="search-list">
                {
                    allChannels.map((channel, channelIndex) => {
                        return channel.programs.map((program, index) => {
                            return <li>
                                <span>{moment(program.startTime).format("dddd DD MMM")}</span>
                                <span>{program.formattedStartTime} - {program.formattedEndTime}</span>
                                <span className="anchor-link" onClick={() => {
                                    this.context.onProgramDetailOpen(program, channel)
                                }}>{program.name}</span>
                                <span className="logo">{getChannelLogo(channel)}</span>
                            </li>
                        });
                    })
                }
            </ul>
        </div>
    };

    onCategoryChange = (event) => {
        let params = getParams();
        if (!isNaN(event.target.value)) {
            params.set("category", event.target.value);
        } else {
            params.delete("category")
        }
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    onChannelChange = (event) => {
        let params = getParams();
        if (!isNaN(event.target.value)) {
            params.set("channel", event.target.value);
        } else {
            params.delete("channel")
        }
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    getChannelList = () => {
        if (isChannelSearchPage()) {
            return this.searchLayout()
        }
        if (_.size(this.state.channels) > 0) {
            if (this.state.layout === TABLE_LAYOUT) {
                return this.tableLayout()
            }
            return this.timelineLayout()
        }
    };

    getChannelListInfo = () => {
        if (_.size(this.state.channels) === 0 || isChannelSearchPage()) {
            return (
                <div className="channel-list-info clearfix">
                    <span className="icon"></span>
                    <p>Kolla.tv erbjuder tablåinformation för över 170 kanaler. Ovan visas ett urval av dessa. Vill du
                        se
                        samtliga kanaler gå till “Mina kanaler”. Under denna sida kan du även anpassa hur din startsida
                        på
                        Kolla.tv</p>
                </div>
            )
        }
    };

    getChannelLoader = () => {
        if (this.state.hasMoreChannels) {
            return <div className=" text-center">
                <img src={loader} alt=" loader" />
            </div>
        }
    };

    setTitleAndMetadata = () => {
        let title = 'Kolla.tv - Swedish TV channels guide,listing to streaming and TV programs';
        let description = "Kolla.tv är den kompletta TV-guiden med TV-tablåer för alla TV-kanaler som finns tillgängliga på den svenska marknaden. Med Kolla.tv kan du skapa din personliga TV-tablå och alltid ha kolla på vad du ska titta på. ";
        let keywords = "TV ,TV-tablå ,TV tablå ,TV idag ,TV guide ,TV-guide ,TV nu ,TV program ,TV-program ,Sport på TV, på tv nu,på tv idag,tv guide,tvguide,tv guiden,tv guide sverige, kolla tv,TV-tablå, tv-tablå, tvtablå, tv-kanaler, filmer på tv, serier på tv, sport på tv, komplett tv-guide, tvguide, TV-guide, tv-guide,TV-tablåer, tvtablåer, Streaming, gratis streaming, gratis film, tablåer, SVT1,SVT2, TV3, TV4, Kanal5, TV6, TV8, Discovery, TV-listings. ";
        if (isFilmPage()) {
            title = 'Kolla.tv - Fullständig lista över svenska filmer och streaming online';
            keywords = "film på tv,film på tv idag,film på tv ikväll,film på tv3,filmer på tv,se film på tv";
            description = "Se vilken film som går på tv";
        } else if (isSeriesPage()) {
            title = 'Kolla.tv - All streaming services and series sweden';
            keywords = "tv serie,tv serier,tv serien,vänner tv serie,advokaterna tv serie,alla tv serier,amerikansk tv serie,amerikansk tv serier,amerikanska tv serier";
            description = "Se vilken tv-serie som går på tv";
        } else if (isChannelSearchPage()) {
            title = 'Kolla.tv - Sökresultat';
            keywords = "";
            description = "";
        } else if (isSportsPage()) {
            title = 'Kolla.tv - Sport på TV och streaming sverige';
            keywords = "sport på tv,all sport på tv,dagens sport på tv,gratis sport på tv,sport på tv i dag,sport på tv idag,sport på tv ikväll,sport på tv nu,sport på tv tablå,sporten på tv";
            description = "Se vilken sport som går på tv";
        } else if (isChannelDetailPage()) {
            let { channels } = this.state;
            if (_.size(channels) === 1) {
                let channel = channels[0];
                title = `Kolla.tv - På ${channel.name} - tablå och guide`;
                if (_.size(channel.programs) > 0) {
                    keywords = _.map(channel.programs, "name").join(", ")
                }
            }
        }
        return <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
        </Helmet>
    };

    render() {
        return <div>
            {this.setTitleAndMetadata()}
            {this.getChannelList()}
            {this.getChannelLoader()}
            {this.getChannelListInfo()}
        </div>
    }
}

export default Channels;