export const getParams = (key) => {
    if (key) {
        return new URLSearchParams(window.location.search).get(key);
    }
    return new URLSearchParams(window.location.search);
};

export const toQueryString = (json) => {
    return '?' + new URLSearchParams(json).toString()
};

export const redirectTo = (url, target = '_self') => {
    window.open(url, target);
};