import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import loader from '../../../images/loader.gif'
import {getParams} from "../../../utils/UrlUtil";
import {FEATURE_PERMISSIONS} from "../../../constants/constants";
import _ from 'lodash'

class CreateOrEditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {id: getParams("id")},
            isInProgress: false
        }
    }

    save = (evt) => {
        evt.preventDefault();
        let {category, isInProgress} = this.state;
        if (!isInProgress) {
            this.toggleInProgress(true);
            http.put("/api/categories", {
                id: category.id,
                name: category.name,
                dagensId: category.dagensId,
            })
                .then((response) => {
                    let {category} = response.data.content;
                    if (category) {
                        window.location.href = "/admin/category/edit?id=" + category.id;
                    }
                }, (error) => {
                    console.log(error)
                }).finally(() => {
                this.toggleInProgress(false)
            })
        }
    };

    changeHandler = (evt) => {
        this.state.category[evt.target.name] = evt.target.value;
        this.setState({category: this.state.category});
    };

    toggleChangeHandler = (evt) => {
        this.state.category[evt.target.name] = !this.state.category[evt.target.name];
        this.setState({category: this.state.category});
    };


    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    onFeaturePermissionsChange = (selectedPermissions) => {
        let {category} = this.state;
        category.featurePermissions = FEATURE_PERMISSIONS.filter((permission) => {
            return _.includes(_.map(selectedPermissions, "value"), permission)
        });
        console.log(category)
        this.setState({category});
    };

    load = (id) => {
        if (id) {
            http.get("/api/categories/" + id)
                .then((response) => {
                    let {category} = response.data.content;
                    this.setState({category});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    componentDidMount() {
        this.load(getParams("id"))
    }

    render() {
        let {category} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={`/admin/category/list`}>Categories</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Category</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-6">
                        <Form onSubmit={this.save}>
                            <Form.Group controlId="Name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" name='name'
                                              value={category.name} disabled={"disabled"}/>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group controlId="dagensId">
                                        <Form.Label>DagensTV Category ID</Form.Label>
                                        <Form.Control type="text" placeholder="DagensTV ID" name='dagensId'
                                                      value={category.dagensId} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>

                </Row>
            </div>
        )
    }
}

export default CreateOrEditCategory