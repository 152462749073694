import React from 'react';
import {getCurrentUser, isNewUser} from "../../utils/Util";


class SignupWelcomeMessage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            localStorage.removeItem("isNewUser");
            this.forceUpdate();
        }, 10000);
    }

    render() {
        let user = getCurrentUser();
        if (isNewUser()) {
            return (
                <div className="welcome-message clearfix">
                    <i className="bi bi-chat-quote">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor"
                             className="bi bi-chat-quote" viewBox="0 0 16 16">
                            <path
                                d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
                            <path
                                d="M7.066 6.76A1.665 1.665 0 0 0 4 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"/>
                        </svg>
                    </i>
                    <div className="text">
                        <h2>Välkommen till Kolla.tv!</h2>
                        <p>Välkommen till Kolla.tv!
                            Vi vill göra det enkelt för dig att hänga med i TV utbudet. Du ska alltid kunna hitta något
                            som passar dig, just nu. Lägga bevakningar på ditt favoritprogram, välj dina kanaler och
                            mycket mer. </p>
                        <p>Ditt användarnamn: {user.username}</p>
                        <p>Vänliga hälsningar,<br></br>
                            Teamet på Kolla.tv</p>
                    </div>
                </div>
            )
        }
        return <div/>
    }
}

export default SignupWelcomeMessage