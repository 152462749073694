import React from 'react';

class IsNotLoggedIn extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!(!!localStorage.getItem("jtoken"))) {
            return (
                this.props.children
            )
        }
        return null;
    }
}

export default IsNotLoggedIn