import React from 'react'
import IsLoggedIn from "../user/IsLoggedIn";
import LoginHeader from "../userPages/LoginHeader";
import {Alert, Button, Form, Row} from 'react-bootstrap';
import loader from '../../images/loader.gif'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import ToastUtil from "../../utils/ToastUtil";


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: '',
                firstName: '',
                lastName: '',
                password: '',
            },
            isInProgress: false,
            errors: [],
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    saveSettings = (evt) => {
        evt.preventDefault();
        let {user, isInProgress} = this.state;
        if (this.validator.allValid() && !isInProgress) {
            this.toggleInProgress(true);
            let params = {
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username
            };

            if (user.password) {
                params.password = user.password
            }
            http.put("/api/users/saveSettings", params)
                .then((response) => {
                    let {user} = response.data.content;
                    this.setState({
                        user: user
                    });
                    ToastUtil.success("Settings has been saved");
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    changeHandler = (event) => {
        let {user} = this.state;
        user[event.target.name] = event.target.value;
        this.setState({user: user});
    };

    componentDidMount() {
        this.toggleInProgress(true);
        http.get("/api/users/getSettings")
            .then((response) => {
                let {user = {}} = response.data.content;
                this.setState({
                    user: user
                });
            })
            .catch((error) => {
                alert("error in fetch settings")
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="user-container setting-page">
                            <LoginHeader/>

                            <IsLoggedIn>
                                <h2>Användarinställningssida</h2>

                                <Form className="form" onSubmit={this.saveSettings} method="POST">
                                    <Row>
                                        <div className="col-md-6">
                                            <Form.Control name="firstName" onChange={this.changeHandler}
                                                          value={this.state.user.firstName} placeholder="Förnamn"/>
                                            {this.validator.message('firstName', this.state.user.firstName, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control name="lastName" onChange={this.changeHandler}
                                                          value={this.state.user.lastName} placeholder="Efternamn"/>
                                            {this.validator.message('lastName', this.state.user.lastName, 'required')}
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className="col-md-6">
                                            <Form.Control name="username" type="email" onChange={this.changeHandler}
                                                          value={this.state.user.username}
                                                          placeholder="E-postadress"/>
                                            {this.validator.message('username', this.state.user.username, 'required|email')}
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control name="password" type="password" onChange={this.changeHandler}
                                                          placeholder="Lösenord"/>
                                        </div>
                                    </Row>
                                    {
                                        this.state.errors.map((error, index) => {
                                            return <Alert key={index} variant={'danger'}>
                                                {error}
                                            </Alert>
                                        })
                                    }
                                    <Row>
                                        <div className="col-md-12">
                                            <Button variant="primary" type="submit">
                                                Ändra
                                            </Button>
                                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                                        </div>
                                    </Row>
                                </Form>

                            </IsLoggedIn>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Settings
