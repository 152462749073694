import {isMobile, isTablet} from "react-device-detect";


export const isTableDevice = () => {
    return isTablet;
};

export const isMobileDevice = () => {
    return isMobile && !isTablet;
};
export const isDesktop = () => {
    return !isMobile;
};