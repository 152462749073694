import React from 'react';
import {Link} from 'react-router-dom'
import './UserPages.scss'

class UserMenu extends React.Component {
    render() {
        return (
            <div className="user-menu sticky-top container">
                <nav className="navbar">
                    <Link to="/MinaKanaler">
                        Mina kanaler
                    </Link>
                    <Link to="/ProgramJagFoljer">
                        Program jag följer
                    </Link>
                    <Link to="/Installningar">
                        Inställningar
                    </Link>
                </nav>
            </div>
        )
    }
}

export default UserMenu
