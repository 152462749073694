import React from 'react';
import {Alert, Button, Form, Modal} from 'react-bootstrap';
import '../Form.scss'
import http from '../../services/HttpService'
import loader from '../../images/loader.gif'
import SimpleReactValidator from 'simple-react-validator';
import {postLoginRegister} from "../../utils/Util";
import {toQueryString} from "../../utils/UrlUtil";
import ToastUtil from "../../utils/ToastUtil";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: undefined,
            password: undefined,
            isInProgress: false,
            showForgotPassword: false,
            message: ''
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    onUsernameChange = (event) => {
        this.setState({username: event.target.value})
    };

    onPasswordChange = (event) => {
        this.setState({password: event.target.value})
    };

    toggleForgotPassword = (bool) => {
        this.setState({showForgotPassword: bool})
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    forgotPassword = (event) => {
        event.preventDefault();
        let {username} = this.state;
        if (username) {
            this.toggleInProgress(true);
            http.get("/api/users/forgotPassword" + toQueryString({email: username}))
                .then(response => {
                    this.setState({message: 'successfully sent forgot password mail'});
                    console.log(response)
                })
                .catch(error => {
                    ToastUtil.error(error.response.data.errors[0]);
                })
                .finally(() => {
                    this.toggleInProgress(false);
                });
        }
    };

    doLogin = (event) => {
        event.preventDefault();
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http.post("/api/login", {
                username: this.state.username,
                password: this.state.password
            }).then(response => {
                let data = response.data;
                localStorage.setItem("user", JSON.stringify(data.content.user));
                localStorage.setItem("jtoken", data.content.token);
                postLoginRegister();
            }).catch(error => {
                ToastUtil.error(error.response.data.errors[0]);
            }).finally(() => {
                this.toggleInProgress(false);
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        let {showForgotPassword, isInProgress, message} = this.state;
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {showForgotPassword ? 'Glömt ditt lösenord' : ''}
                        {!showForgotPassword ? 'Logga in för din på kanallista' : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!showForgotPassword ? <Form onSubmit={this.doLogin}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" name="username" onChange={this.onUsernameChange}
                                          placeholder="E-postadress"/>
                            {this.validator.message('email', this.state.username, 'required|email')}
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" name="password" onChange={this.onPasswordChange}
                                          placeholder="Lösenord"/>
                            {this.validator.message('password', this.state.password, 'required')}
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Kom ihåg mig"/>
                            <a href="javascript:void(0)" className="float-right" onClick={() => {
                                this.toggleForgotPassword(true);
                            }}>Glömt lösenord?</a>
                        </Form.Group>
                        <Button variant="primary" type="submit">Logga in</Button>&nbsp;
                        <a href="/register" target="_self" className="btn btn-default">Skapa Konto</a>
                        {isInProgress ? <img src={loader}/> : ''}
                    </Form> : ''}

                    {showForgotPassword ?
                        <Form onSubmit={this.forgotPassword}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" name="username" onChange={this.onUsernameChange}
                                              placeholder="E-postadress"/>
                            </Form.Group>
                            {message ? <Alert key={0} variant={'success'}>
                                {this.state.message}
                            </Alert> : ''}
                            <Button variant="primary" type="submit">Skicka</Button>&nbsp;
                            <Button variant="default" type="button" onClick={() => {
                                this.toggleForgotPassword(false);
                            }}>Logga in</Button>
                            {isInProgress ? <img src={loader}/> : ''}
                        </Form> : ''}
                </Modal.Body>
            </Modal>
        )
    }
}

export default Login