import React from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import {getParams, toQueryString} from "../../../utils/UrlUtil";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ToastUtil from "../../../utils/ToastUtil";
import loader from '../../../images/loader.gif'
import HasFeaturePermission from "../../user/HasFeaturePermission";

class Medias extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            medias: [],
            searchQuery: {
                size: params.get("size") || 6,
                page: params.get("page") || 0,
                active: true,
                q: params.get("q") || '',
                order: "desc",
                sort: "id"
            },
            totalElements: 0,
            isInProgress: false,
            isCreateMediaFormOpen: false,
            selectedFile: undefined,
            url: undefined
        }
    }

    changeHandler = (evt) => {
        let {searchQuery} = this.state;
        searchQuery.q = evt.target.value;
        this.setState({searchQuery});
    };

    formChangeHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    };

    list = (params) => {
        this.toggleInProgress(true);
        http.get("/api/media" + toQueryString(params))
            .then((response) => {
                let {medias, totalElements} = response.data.content;
                this.setState({medias: medias, totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        })
            .finally(() => {
                this.toggleInProgress(false);
            })
    };

    deleteMedia = (media) => {
        if (!this.state.isInProgress) {
            let hasConfirmed = window.confirm("Are you sure? You want to delete file");
            if (hasConfirmed === true) {
                this.toggleInProgress(true);
                http.delete("/api/media/" + media.id)
                    .then((response) => {
                        this.list(this.state.searchQuery)
                    }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.toggleInProgress(false);
                })
            }
        }
    };

    toggleCreateMediaForm = (bool) => {
        this.setState({isCreateMediaFormOpen: bool})
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    gotoNext = () => {
        let {searchQuery} = this.state;
        searchQuery.page += +1;
        this.setState({searchQuery});
        this.list(searchQuery)
    };

    gotoPrevious = () => {
        let {searchQuery} = this.state;
        searchQuery.page += -1;
        this.setState({searchQuery});
        this.list(searchQuery)
    };

    onFileChangeHandler = (e) => {
        e.preventDefault();
        this.setState({
            selectedFile: e.target.files[0]
        });
    };

    upload = (e) => {
        e.preventDefault();
        if (!this.state.isInProgress) {
            let {selectedFile, url} = this.state;
            const formData = new FormData();
            this.toggleInProgress(true);
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
            if (url) {
                formData.append('url', url);
            }
            http
                .post("/api/media", formData)
                .then((response) => {
                    if (response.data.status) {
                        ToastUtil.success("File has been uploaded successfully");
                        this.toggleCreateMediaForm(false);
                        this.list(this.state.searchQuery);
                    } else {
                        ToastUtil.error(response.data.errors[0])
                    }
                    console.log(response)
                }).finally(() => {
                this.toggleInProgress(false);
            });
        }
    };

    onCopy = (url) => {
        let {onSelect, hideOnSelect, onHide} = this.props;
        if (onSelect) {
            onSelect(url);
        }

        if (hideOnSelect && onHide) {
            onHide();
        } else {
            ToastUtil.success("URL has been Copied");
        }
    };

    componentDidMount() {
        let {searchQuery} = this.state;
        this.list(searchQuery);
    }

    render() {
        let {medias, isInProgress, searchQuery, isCreateMediaFormOpen, totalElements} = this.state;
        let {selectBtnText = "Copy URL"} = this.props;
        return (
            <div>
                {!isCreateMediaFormOpen ? <div>
                        <Row>
                            <Col>
                                <HasFeaturePermission permission={'media.write'}>
                                    <input type="button" className="btn btn-primary btn-sm" value="Add New Media"
                                           onClick={() => {
                                               this.toggleCreateMediaForm(true)
                                           }}/>
                                </HasFeaturePermission>
                                &nbsp;{isInProgress ? <img src={loader}/> : ''}

                            </Col>
                            <Col>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.list(searchQuery)
                                }}>
                                    <Row>
                                        <Col>
                                            <Form.Control type="text" placeholder="Search" name="q" value={searchQuery.q}
                                                          onChange={this.changeHandler}/>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <br/>
                        <div>
                            <p className="font-weight-bold">
                                Total Media : {totalElements}
                            </p>
                        </div>
                        <Row>
                            {
                                medias.map((media, index) => {
                                    return <div className={"col-md-4 col-sm-6"}>
                                        <Card key={index}>
                                            <Card.Img variant="top" style={{'max-height': '163px'}}
                                                      src={`${media.s3url}`}/>
                                            <Card.Body>
                                                <Card.Text>
                                                    {media.name}
                                                </Card.Text>
                                                <CopyToClipboard text={media.s3url}
                                                                 onCopy={this.onCopy}>
                                                    <Button variant="primary" className={"btn-sm"}>{selectBtnText}</Button>
                                                </CopyToClipboard>
                                                {' '}
                                                <HasFeaturePermission permission={'media.delete'}>
                                                    <Button variant="danger" className={"btn-sm float-right"}
                                                            onClick={() => {
                                                                this.deleteMedia(media);
                                                            }}>Delete</Button>
                                                </HasFeaturePermission>
                                            </Card.Body>
                                        </Card>
                                        <br/>
                                    </div>
                                })
                            }
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                {searchQuery.page > 0 ?
                                    <input type={"button"} className="btn btn-primary btn-sm" value={"Previous"}
                                           onClick={this.gotoPrevious}/> : ''}
                            </Col>
                            <Col>
                                <input type={"button"} className="btn btn-primary btn-sm float-right" value={"Next"}
                                       onClick={this.gotoNext}/>
                            </Col>
                        </Row>
                    </div> :
                    ''
                }

                <HasFeaturePermission permission={'media.write'}>
                    {
                        isCreateMediaFormOpen ? <div>
                            <h4>Create New Media</h4><br/>
                            <Form onSubmit={this.upload}>
                                <Form.Group>
                                    <Form.File id="file" name="file" label="Select file"
                                               onChange={this.onFileChangeHandler}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className={"font-weight-bold"}>Or Upload Image from URL</Form.Label>
                                    <Form.Control type="text" name="url" placeholder="Enter Image URL"
                                                  onChange={this.formChangeHandler}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Upload
                                </Button>
                                <Button variant="default" type="button"
                                        onClick={() => this.toggleCreateMediaForm(false)}>
                                    Cancel
                                </Button>
                                &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                            </Form>
                        </div> : ''
                    }
                </HasFeaturePermission>
            </div>
        )
    }
}

export default Medias