import React from 'react';
import {Breadcrumb, Col, Row, Table} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import {getParams} from "../../../../utils/UrlUtil";
import _ from 'lodash'
import {prettyBool} from "../../../../utils/Util";

class Providers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            totalElements: 0,
            isInProgress: false
        }
    }


    list = () => {
        this.toggleInProgress(true);
        let params = getParams();
        http.get("/api/es/providers?" + params.toString())
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
            }).catch((error) => {
            console.log(error)
        })
            .finally(() => {
                this.toggleInProgress(false);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {providers} = this.state;
        providers = _.orderBy(providers, "tabIndex");
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Providers</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        <a href="/admin/stream/provider/create" className="btn btn-primary btn-sm">Create Provider</a>
                        &nbsp;
                        <a href="/admin/stream/provider/manageOrder" className="btn btn-primary btn-sm">Manage Order</a>
                    </Col>
                </Row>
                <br/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Label</th>
                        <th>Base URL</th>
                        <th>Added From</th>
                        <th>Active</th>
                        <th>Countries</th>
                        <th>Order</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        providers.map((provider, index) => {
                            return <tr key={index}>
                                <td>{provider.name}</td>
                                <td>{provider.label}</td>
                                <td>{provider.baseUrl}</td>
                                <td>{provider.addedFrom}</td>
                                <td className={provider.active ? 'admin-active' : 'admin-inactive'}>{prettyBool(provider.active)}</td>
                                <td>{_.join(provider.countries, ",")}</td>
                                <td>{provider.tabIndex}</td>
                                <td>
                                    <a href={`/admin/stream/provider/edit?id=${provider.id}`}
                                       className="btn btn-sm btn-primary">Edit</a>
                                    {' '}
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default Providers