import React from 'react';
import IsNotLoggedIn from '../user/IsNotLoggedIn';
import Login from '../user/Login';
import {Link} from 'react-router-dom';


class LoginHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLogin: false
        }
    }

    openLogin = () => {
        this.setState({showLogin: true})
    };

    closeLogin = () => {
        this.setState({showLogin: false})
    };

    render() {
        return (
            <IsNotLoggedIn>
                <div className="notes">
                    <Link className="" href="#" to="#" onClick={this.openLogin}>Login</Link> in för att inte bli av
                    med dina inställningar (om cookies rensas).
                    Vill du bli medlem  <Link to="/register">
                    <span>Skapa Konto</span>
                </Link>
                </div>
                <Login show={this.state.showLogin} onHide={this.closeLogin}/>
            </IsNotLoggedIn>
        )
    }
}

export default LoginHeader