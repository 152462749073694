import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {getDay, getMyPrograms, getSelectedDay, getSelectedWeek, isLoggedIn, removeMyPrograms} from "../../utils/Util";
import {FaMinusCircle, FaPrint} from 'react-icons/fa';
import http from "../../services/HttpService";
import {toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'
import moment from 'moment'
import ProgramModalContext from '../context/ProgramModalContext';
import ToastUtil from "../../utils/ToastUtil";

class MyFavouritePrograms extends React.Component {
    static contextType = ProgramModalContext;

    constructor(props) {
        super(props);
        this.smallLogos = require.context('../../images/logos/small', true, /.png$/);
        this.state = {
            myPrograms: [],
            isInProgress: false,
            selectedDate: moment(),
        }
    }

    removeProgram = (program) => {
        if (isLoggedIn()) {
            let params = {programId: program.id};
            http.get("/api/programs/removeFromMyPrograms" + toQueryString(params))
                .then((response) => {
                    let {status, errors} = response.data;
                    if (status) {
                        ToastUtil.success("Program has been deleted from MyProgram list.");
                        this.loadMyPrograms()
                    } else {
                        alert(errors[0])
                    }
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
        } else {
            removeMyPrograms(program);
            this.loadMyPrograms()
        }
    };

    getSmallLogo = (sourceId) => {
        try {
            return this.smallLogos('./' + sourceId + ".png").default
        } catch (e) {

        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    loadMyPrograms = () => {
        if (isLoggedIn()) {
            this.toggleInProgress(true);
            let params = {day: getDay()};
            http.get("/api/public/programs/me" + toQueryString(params))
                .then((response) => {
                    this.setState({
                        myPrograms: response.data.content.programs
                    });
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })
        } else {
            this.setState({
                myPrograms: getMyPrograms()
            });
        }
    };

    getText = () => {
        let {myPrograms} = this.state;
        if (_.size(myPrograms) === 0) {
            return <span className='text'>Håll koll på dina favoritprogram genom att klicka på ett program och välja "Lägg till i min tablå". Ange om du bara vill lägga till detta sändningstillfälle eller om du även vill visa framtida avsnitt (byt dag i navigeringen för att se dessa). Dina val sparas med cookies. Observera att om du tömmer dina cookies kommer dina val försvinna nästa gång du besöker Kolla.tv om du inte skapar ett konto</span>
        }
    };

    componentDidMount() {
        this.setState({selectedDate: moment().add(getDay(), "days")});
        this.loadMyPrograms();
    }

    render() {
        let {myPrograms, selectedDate} = this.state;
        myPrograms = _.sortBy(myPrograms, "startTime");
        return (

            <div className="my-list">
                <h2>
                        <span className="print-icon">
                            <a href={`/PrintMyPrograms?day=${getSelectedDay()}&week=${getSelectedWeek()}`}
                               target="_blank">   <FaPrint/></a>
                        </span>
                    MIN TABLÅ<br/>
                    {_.size(myPrograms) > 0 ?
                        <span className="small">{selectedDate.format("ddd DD MMMM, yyyy")}</span> : null}
                </h2>
                <ul>
                    {
                        myPrograms.map((program, index) => {
                            return <li className="clearfix" key={index}>
                                <span className="remove-icon"><FaMinusCircle onClick={() => {
                                    this.removeProgram(program)
                                }}/></span>
                                <span className='smalllogo'>
                                    <img src={this.getSmallLogo(program.channel.sourceId)}/>
                                </span>
                                <span className="name" onClick={() => {
                                    this.context.onProgramDetailOpen(program, program.channel)
                                }}>
                                    {program.formattedStartTime} {program.name}
                                    </span>
                            </li>
                        })
                    }
                </ul>
                {this.getText()}
            </div>

        )
    }
}

export default MyFavouritePrograms;