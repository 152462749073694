import React from 'react';

class IsLoggedIn extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!!localStorage.getItem("jtoken")) {
            return this.props.children
        }
        return null
    }
}

export default IsLoggedIn