import React from 'react';
import {Breadcrumb, Button, Card, Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import loader from '../../../../images/loader.gif'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams} from "../../../../utils/UrlUtil";
import Select from 'react-select'
import _ from 'lodash'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import MediasModal from "../../media/MediasModal";
import StreamSeasonsModal from "../season/StreamSeasonsModal";
import SimpleReactValidator from 'simple-react-validator';
import {format} from "../../../../utils/DateUtil";

class StreamProgramCreateOrEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            program: {},
            countries: [],
            programTypes: [],
            languages: [],
            isInProgress: false,
            isGalleryOpen: false,
            isSeasonOpen: false,
            genres: [],
            providers: [],
            translations: [],
            isMultiSeasons: false
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    saveProgram = (evt) => {
        evt.preventDefault();
        let {program} = this.state;
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http.post("/api/es/programs", {
                id: program.id,
                sourceId: program.sourceId,
                title: program.title,
                active: program.active,
                description: program.description,
                year: program.year,
                duration: program.duration,
                directors: program.directors,
                actors: program.actors,
                rating: program.rating,
                programType: program.programType,
                imageUrl: program.imageUrl,
                live: program.live,
                episodeNumber: program.episodeNumber,
                free: program.free,
                sourceReference: program.sourceReference,
                age: program.age,
                tags: program.tags,
                prioritized: program.prioritized,
                imdbId: program.imdbId,
                countryOfOrigin: program.countryOfOrigin,
                videoUrl: program.videoUrl,
                wikiUrl: program.wikiUrl,
                genres: _.map(program.genres, "name"),
                languages: program.languages,
                videoUrls: program.videoUrls,
                providers: program.providers,
                translations: program.translations,
                settings: program.settings,
                season: program.season ? program.season.id : null,
                seasons: program.seasons ? _.map(program.seasons, "id") : null,
                liveStartDate: program.liveStartDate,
                liveEndDate: program.liveEndDate,
                draft: program.draft,
                checkProgramExist: program.checkProgramExist,
                featured: program.featured,
                featuredExpire: program.featured ? program.featuredExpire : null,
                featuredTabIndex: program.featuredTabIndex >= 0 ? program.featuredTabIndex : null

            })
                .then((response) => {
                    let {program} = response.data.content;
                    if (response.data.status) {
                        window.location.href = "/admin/stream/program/edit?id=" + program.id;
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }, (error) => {
                    console.log(error)
                }).finally(() => {
                this.toggleInProgress(false)
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    };

    changeHandler = (evt) => {
        console.log(evt.target.value)
        this.state.program[evt.target.name] = evt.target.value;
        this.setState({program: this.state.program});
    };

    changeProviderHandler = (index, e) => {
        let {program} = this.state;
        program.providers[index][e.target.name] = e.target.value;
        this.setState({program});
    };

    changeTranslationHandler = (index, e) => {
        let {program} = this.state;
        program.translations[index][e.target.name] = e.target.value;
        this.setState({program});
    };

    changeSettingHandler = (index, e) => {
        let {program} = this.state;
        program.settings[index][e.target.name] = e.target.value;
        this.setState({program});
    };

    toggleChangeSettingHandler = (index, e) => {
        let {program} = this.state;
        console.log(program.settings[index][e.target.name])
        program.settings[index][e.target.name] = !program.settings[index][e.target.name];
        console.log(program.settings[index])
        this.setState({program});
    };

    toggleChangeHandler = (evt) => {
        this.state.program[evt.target.name] = !this.state.program[evt.target.name];
        this.setState({program: this.state.program});
    };

    onTagsChange = (property, tags) => {
        let {program} = this.state;
        program[property] = tags;
        this.setState({program})
    };

    onGenreChange = (_genres) => {
        let {genres, program} = this.state;
        program["genres"] = genres.filter((genre) => {
            return _.includes(_.map(_genres, "value"), genre.name)
        });
        this.setState({program});
    };

    removeSeason = (index) => {
        let {program} = this.state;
        program.seasons.splice(index, 1);
        this.setState({program});
    };

    onSeasonSelect = (season) => {
        let {program, isMultiSeasons} = this.state;
        if (isMultiSeasons) {
            if (!_.some(program.seasons, ["id", season.id])) {
                program.seasons.push(season);
            }
        } else {
            program.season = season;
        }
        this.setState({program});
    };

    onLanguageChange = (_languages) => {
        let {languages, program} = this.state;
        program["languages"] = _.map(languages.filter((language) => {
            return _.includes(_.map(_languages, "value"), language.name)
        }), "name");
        this.setState({program});
    };

    loadProgram = (id) => {
        if (id) {
            http.get("/api/es/programs/" + id)
                .then((response) => {
                    let {program = {}} = response.data.content;
                    this.setState({program});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    createDuplicate = (id) => {
        if (id) {
            http.get("/api/es/programs/duplicate/" + id)
                .then((response) => {
                    let {program = {}} = response.data.content;
                    if (response.data.status) {
                        window.location.href = "/admin/stream/program/edit?id=" + program.id;
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    markLatest = (id) => {
        if (id) {
            http.get("/api/es/programs/markLatest/" + id)
                .then((response) => {
                    let {program = {}} = response.data.content;
                    if (response.data.status) {
                        window.location.href = "/admin/stream/program/edit?id=" + program.id;
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    loadCountries = () => {
        http.get("/api/es/countries")
            .then((response) => {
                let {countries} = response.data.content;
                this.setState({countries});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadStreamTypes = () => {
        http.get("/api/es/programs/types")
            .then((response) => {
                let {programTypes} = response.data.content;
                this.setState({programTypes});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadGenres = () => {
        http.get("/api/es/genres")
            .then((response) => {
                let {genres} = response.data.content;
                this.setState({genres});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadLanguages = () => {
        http.get("/api/es/languages")
            .then((response) => {
                let {languages} = response.data.content;
                this.setState({languages});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadProviders = () => {
        http
            .get("/api/es/providers")
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    openGallery = () => {
        this.setState({isGalleryOpen: true});
    };

    closeGallery = () => {
        this.setState({isGalleryOpen: false});
    };

    openSeasons = (isMultiSeasons) => {
        this.setState({isSeasonOpen: true, isMultiSeasons: isMultiSeasons});
    };

    closeSeasons = () => {
        this.setState({isSeasonOpen: false});
    };

    addProvider = () => {
        let {program} = this.state;
        program.providers.push({});
        this.setState({program})
    };
    addTranslation = () => {
        let {program} = this.state;
        program.translations.push({});
        this.setState({program})
    };

    addSetting = () => {
        let {program} = this.state;
        program.settings.push({});
        this.setState({program})
    };

    removeProvider = (index) => {
        let {program} = this.state;
        program.providers.splice(index, 1);
        this.setState({program})
    };

    removeTranslation = (index) => {
        let {program} = this.state;
        program.translations.splice(index, 1);
        this.setState({program})
    };

    removeSetting = (index) => {
        let {program} = this.state;
        program.settings.splice(index, 1);
        this.setState({program})
    };

    onImageUrlSelect = (url) => {
        let {program} = this.state;
        program.imageUrl = url;
        this.setState({program})
    };

    componentDidMount() {
        this.loadProgram(getParams("id"));
        this.loadCountries();
        this.loadGenres();
        this.loadLanguages();
        this.loadStreamTypes();
        this.loadProviders();
    }

    render() {
        let {program, countries, genres, programTypes, languages, isGalleryOpen, providers, isSeasonOpen} = this.state;
        if (_.size(program.providers) === 0) {
            program.providers = [{}];
        }
        if (_.size(program.translations) === 0) {
            program.translations = [{}];
        }
        if (_.size(program.settings) === 0) {
            program.settings = [{}];
        }
        if (_.size(program.seasons) === 0) {
            program.seasons = [];
        }
        if (!program.season) {
            program.season = {};
        }
        if (!program.id && program.checkProgramExist === undefined) {
            program.checkProgramExist = true
        }

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/program/list">
                        Programs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    {program.id ? <Col>
                        <input type={"button"} value={"Create Duplicate"} onClick={() => {
                            this.createDuplicate(program.id)
                        }} className="btn btn-sm btn-primary"/>&nbsp;
                        <input type={"button"} value={"Mark Latest"} onClick={() => {
                            this.markLatest(program.id)
                        }} className="btn btn-sm btn-primary"/>
                    </Col> : null}
                </Row>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.saveProgram} method="POST" novalidate={"novalidate"}>
                            <Form.Group controlId="title">
                                <Form.Label className={"font-weight-bold"}>Title *</Form.Label>
                                <Form.Control type="text" placeholder="Title" name='title' required="required"
                                              value={program.title} onChange={this.changeHandler}/>
                                {!program.id ?
                                    <Form.Check type="checkbox" label="Check Program Exist" name='checkProgramExist'
                                                onChange={this.toggleChangeHandler}
                                                checked={program.checkProgramExist ? 'checked' : ''}/> : null}
                                <div>{this.validator.message('title', program.title, 'required')}</div>
                            </Form.Group>


                            <Form.Group controlId="description">
                                <Form.Label className={"font-weight-bold"}>Description *</Form.Label>
                                <Form.Control type="text" placeholder="Description" name='description'
                                              required="required"
                                              value={program.description} onChange={this.changeHandler}/>
                                {this.validator.message('description', program.description, 'required')}
                            </Form.Group>
                            {program.id ? <Row>
                                <Col>
                                    <Form.Group controlId="dateCreated">
                                        <Form.Label className={"font-weight-bold"}>Date Created *</Form.Label>
                                        <Form.Control type="text" name='dateCreated'
                                                      value={format(program.dateCreated, "DD-MM-YYYY hh:mm a")}
                                                      disabled={"disabled"}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="recentlyAdded">
                                        <Form.Label className={"font-weight-bold"}>Recently Added *</Form.Label>
                                        <Form.Control type="text" name='recentlyAdded'
                                                      value={format(program.recentlyAdded, "DD-MM-YYYY hh:mm a")}
                                                      disabled={"disabled"}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="addedFrom">
                                        <Form.Label className={"font-weight-bold"}>Added From</Form.Label>
                                        <Form.Control type="text" name='addedFrom'
                                                      value={program.addedFrom}
                                                      disabled={"disabled"}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="friendlyUrl">
                                        <Form.Label className={"font-weight-bold"}>Friendly URL *</Form.Label>
                                        <Form.Control type="text" name='friendlyUrl'
                                                      value={program.friendlyUrl} disabled={"disabled"}/>
                                    </Form.Group>
                                </Col>
                            </Row> : null}
                            <Form.Group>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <div className="col-md-3">
                                                <Form.Label className={"font-weight-bold"}>Program Type *</Form.Label>
                                                <Form.Control required={"required"} as="select"
                                                              value={program.programType} name='programType'
                                                              onChange={this.changeHandler}>
                                                    <option value=''>Select</option>
                                                    {
                                                        programTypes.map((type, index) => {
                                                            return <option value={type.name}
                                                                           key={index}>{type.label}</option>

                                                        })
                                                    }
                                                </Form.Control>
                                                {this.validator.message('programType', program.programType, 'required')}
                                            </div>
                                            {
                                                program.programType === 'episode' ? <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <Form.Label className={"font-weight-bold"}>Episode
                                                                Number *</Form.Label>
                                                            <Form.Control type="number" placeholder="Episode Number"
                                                                          name='episodeNumber'
                                                                          value={program.episodeNumber}
                                                                          onChange={this.changeHandler}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {
                                                                <Form.Group controlId="videoUrl">
                                                                    <Form.Label
                                                                        className={"font-weight-bold"}>Season
                                                                        *</Form.Label>
                                                                    <Form.Control type="text" placeholder="Season"
                                                                                  disabled={"disabled"}
                                                                                  name='season'
                                                                                  value={program.season.name}/>
                                                                </Form.Group>
                                                            }

                                                        </div>
                                                        <div className="col-md-3">
                                                            <Form.Label
                                                                className={"font-weight-bold d-block"}>Actions</Form.Label>
                                                            <Button variant="primary"
                                                                    className={"btn"}
                                                                    type="button"
                                                                    onClick={() => this.openSeasons(false)}>
                                                                Select Season
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                            {
                                                program.programType === 'tv-show' ? <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <Form.Group controlId="genres">
                                                                <Form.Label
                                                                    className={"font-weight-bold d-block"}>Seasons
                                                                    *</Form.Label>
                                                                {
                                                                    program.seasons.map((season, index) => {
                                                                        return <div className="form-control-badge">
                                                                            <a href={`/admin/stream/program/list?seasonFriendlyUrl=${season.friendlyUrl}`}>
                                                                                {season.name}
                                                                            </a>
                                                                            <span onClick={() => {
                                                                                this.removeSeason(index)
                                                                            }} className="close-btn">x</span>
                                                                        </div>
                                                                    })
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Form.Label
                                                                className={"font-weight-bold d-block"}>Actions</Form.Label>
                                                            <Button variant="primary"
                                                                    className={"btn"}
                                                                    type="button"
                                                                    onClick={() => this.openSeasons(true)}>
                                                                Select Seasons
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                            {
                                                program.programType === 'live' ? <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <Form.Group controlId="live">
                                                                <Form.Label
                                                                    className={"font-weight-bold d-block"}>Live</Form.Label>
                                                                <Form.Check type="checkbox" label="Live" name='live'
                                                                            onChange={this.toggleChangeHandler}
                                                                            checked={program.live ? 'checked' : ''}/>
                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            program.live ? <div className="row">
                                                                <div className="col-md-6">
                                                                    <Form.Label className={"font-weight-bold"}>Start
                                                                        Date</Form.Label>
                                                                    <Form.Control type="datetime-local"
                                                                                  placeholder="Live Start Date"
                                                                                  name='liveStartDate'
                                                                                  value={program.liveStartDate}
                                                                                  onChange={this.changeHandler}/>

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Form.Label className={"font-weight-bold"}>End
                                                                        Date</Form.Label>
                                                                    <Form.Control type="datetime-local"
                                                                                  placeholder="Live End Date"
                                                                                  name='liveEndDate'
                                                                                  value={program.liveEndDate}
                                                                                  onChange={this.changeHandler}/>
                                                                </div>
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div> : null
                                            }
                                        </Row>
                                    </Card.Body>
                                </Card>
                                {program.title && program.programType ?
                                    <a href={`https://www.google.com/search?q=${program.title} ${program.programType}`}
                                       target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                       type="button">
                                        Search {program.programType} detail in Google
                                    </a> : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Providers Detail</Form.Label>
                                {
                                    program.providers.map((provider, index) => {
                                        return <Card className={"margin-bottom-5px"}>
                                            <Card.Body>
                                                <Row>
                                                    <div className="col-md-7">
                                                        <Form.Label className={"font-weight-bold"}>Details
                                                            URL *</Form.Label>
                                                        <Form.Control type="url" placeholder="Program Detail URL"
                                                                      name='detailUrl' required="required"
                                                                      value={provider.detailUrl}
                                                                      onChange={(event) => this.changeProviderHandler(index, event)}/>
                                                        {this.validator.message('DetailUrl', provider.detailUrl, 'required|url')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <Form.Label className={"font-weight-bold"}>Provider
                                                            *</Form.Label>
                                                        <Form.Control as="select" value={provider.provider}
                                                                      name='provider'
                                                                      onChange={(event) => this.changeProviderHandler(index, event)}>
                                                            <option value=''>Select</option>
                                                            {
                                                                providers.map((provider, index) => {
                                                                    return <option value={provider.name}
                                                                                   key={index}>
                                                                        {provider.label}
                                                                        ({provider.active ? 'Active' : 'Inactive'})
                                                                    </option>

                                                                })
                                                            }
                                                        </Form.Control>
                                                        {this.validator.message('Provider', provider.provider, 'required')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <Form.Label className={"font-weight-bold"}>Country
                                                            *</Form.Label>
                                                        <Form.Control as="select" value={provider.country}
                                                                      name='country'
                                                                      onChange={(event) => this.changeProviderHandler(index, event)}>
                                                            <option value=''>Select</option>
                                                            {
                                                                countries.map((country, index) => {
                                                                    return <option value={country.code}
                                                                                   key={index}>
                                                                        {country.name}
                                                                    </option>

                                                                })
                                                            }
                                                        </Form.Control>
                                                        {this.validator.message('Country', provider.country, 'required')}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <Form.Label className={"font-weight-bold"}>Action</Form.Label>
                                                        <Button onClick={() => this.removeProvider(index)}
                                                                variant="danger"
                                                                className={"btn"}>
                                                            X
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    })
                                }
                                <Button onClick={this.addProvider} variant="primary"
                                        className={"btn btn-sm margin-top-5px"}>
                                    Add More Provider
                                </Button>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Translations</Form.Label>
                                {
                                    program.translations.map((translation, index) => {
                                        return <Card className={"margin-bottom-5px"}>
                                            <Card.Body>
                                                <Row>
                                                    <div className="col-md-2">
                                                        <Form.Label className={"font-weight-bold"}>Country</Form.Label>
                                                        <Form.Control as="select" value={translation.country}
                                                                      name='country'
                                                                      onChange={(event) => this.changeTranslationHandler(index, event)}>
                                                            <option value=''>Select</option>
                                                            {
                                                                countries.map((country, index) => {
                                                                    return <option value={country.code}
                                                                                   key={index}>
                                                                        {country.name}
                                                                    </option>

                                                                })
                                                            }
                                                        </Form.Control>
                                                        {/*{this.validator.message('Country', translation.country, 'required')}*/}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Label className={"font-weight-bold"}>
                                                            Title</Form.Label>
                                                        <Form.Control type="text" placeholder="Title"
                                                                      name='title'
                                                                      value={translation.title}
                                                                      onChange={(event) => this.changeTranslationHandler(index, event)}/>
                                                        {/*{this.validator.message('Title', translation.title, 'required')}*/}

                                                    </div>
                                                    <div className="col-md-5">
                                                        <Form.Label className={"font-weight-bold"}>
                                                            Description</Form.Label>
                                                        <Form.Control type="text" placeholder="Description"
                                                                      name='description'
                                                                      value={translation.description}
                                                                      onChange={(event) => this.changeTranslationHandler(index, event)}/>
                                                        {/*{this.validator.message('Description', translation.description, 'required')}*/}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <Form.Label className={"font-weight-bold"}>Action</Form.Label>
                                                        <Button onClick={() => this.removeTranslation(index)}
                                                                variant="danger"
                                                                className={"btn"}>
                                                            X
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    })
                                }
                                <Button onClick={this.addTranslation} variant="primary"
                                        className={"btn btn-sm margin-top-5px"}>
                                    Add Translation
                                </Button>
                            </Form.Group>


                            <Form.Group controlId="imageUrl">
                                <Form.Label className={"font-weight-bold"}>Image URL *</Form.Label>
                                <Form.Control type="url" placeholder="Image Url" name='imageUrl' required="required"
                                              value={program.imageUrl} onChange={this.changeHandler}/>
                                <div>{this.validator.message('Image url', program.imageUrl, 'url')}</div>
                                <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"
                                        onClick={this.openGallery}>
                                    Choose Program Image
                                </Button>&nbsp;
                                {program.title ? <a href={` https://www.google.com/search?tbm=isch&q=${program.title}`}
                                                    target={'_blank'}
                                                    className={"btn btn-sm btn-primary margin-top-5px"}
                                                    type="button">
                                    Search Images in Google
                                </a> : null}
                                {program.imageUrl ? <div className="margin-top-5px">
                                    <img src={program.imageUrl} width={250}/>
                                </div> : null}
                            </Form.Group>

                            <Row>
                                <Col>
                                    <Form.Group controlId="videoUrl">
                                        <Form.Label className={"font-weight-bold"}>Youtube Trailer URL</Form.Label>
                                        <Form.Control type="url" placeholder="Youtube Trailer URL" name='videoUrl'
                                                      value={program.videoUrl} onChange={this.changeHandler}/>
                                        {program.title ?
                                            <a href={`https://www.youtube.com/results?search_query=${program.title} trailer`}
                                               target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                               type="button">
                                                Search in Youtube
                                            </a> : null}
                                        <div>{this.validator.message('Youtube url', program.videoUrl, 'url')}</div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="videoUrls">
                                        <Form.Label className={"font-weight-bold"}>Youtube URLs</Form.Label>
                                        <TagsInput inputProps={{placeholder: ""}} value={program.videoUrls || []}
                                                   onChange={(videoUrl) => {
                                                       this.onTagsChange("videoUrls", videoUrl);
                                                   }}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="wikiUrl">
                                        <Form.Label className={"font-weight-bold"}>Wikipedia URL</Form.Label>
                                        <Form.Control type="url" placeholder="Wiki URL" name='wikiUrl'
                                                      value={program.wikiUrl} onChange={this.changeHandler}/>
                                        {program.title ?
                                            <a href={`https://en.wikipedia.org/wiki/Special:Search?search=${program.title}&go=Go&ns0=1`}
                                               target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                               type="button">
                                                Search in Wikipedia
                                            </a> : null}
                                        <div>{this.validator.message('wikipedia url', program.wikiUrl, 'url')}</div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>

                                <Col>
                                    <Form.Group controlId="duration">
                                        <Form.Label className={"font-weight-bold"}>Duration *</Form.Label>
                                        <Form.Control type="number" placeholder="Duration" name='duration'
                                                      required={"required"}
                                                      value={program.duration} onChange={this.changeHandler}/>
                                        <Form.Text className="text-muted">
                                            * Duration in minutes
                                        </Form.Text>
                                        {this.validator.message('Duration', program.duration, 'required|number')}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="year">
                                        <Form.Label className={"font-weight-bold"}>Year</Form.Label>
                                        <Form.Control type="number" placeholder="Year" name='year'
                                                      value={program.year} onChange={this.changeHandler}/>
                                        {this.validator.message('Year', program.year, 'number')}
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId="age">
                                        <Form.Label className={"font-weight-bold"}>Age</Form.Label>
                                        <Form.Control type="text" placeholder="Age" name='age'
                                                      value={program.age} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId="rating">
                                        <Form.Label className={"font-weight-bold"}>Rating</Form.Label>
                                        <Form.Control type="text" placeholder="Rating" name='rating'
                                                      value={program.rating} onChange={this.changeHandler}/>
                                        {program.title ? <a href={`https://www.imdb.com/find?q=${program.title}`}
                                                            target={'_blank'}
                                                            className={"btn btn-sm btn-primary margin-top-5px"}
                                                            type="button">
                                            Search in IMDB
                                        </a> : null}
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId="imdbId">
                                        <Form.Label className={"font-weight-bold"}>IMDB Id</Form.Label>
                                        <Form.Control type="text" placeholder="IMDB Id" name='imdbId'
                                                      value={program.imdbId} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="sourceId">
                                        <Form.Label className={"font-weight-bold"}>Source Id</Form.Label>
                                        <Form.Control type="text" placeholder="SourceId" name='sourceId'
                                                      value={program.sourceId} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group controlId="sourceReference">
                                        <Form.Label className={"font-weight-bold"}>Source Reference</Form.Label>
                                        <Form.Control type="text" placeholder="Source Reference" name='sourceReference'
                                                      value={program.sourceReference} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="countryOfOrigin">
                                        <Form.Label className={"font-weight-bold"}>Country of Production</Form.Label>
                                        <Form.Control type="text" placeholder="Country of Production"
                                                      name='countryOfOrigin'
                                                      value={program.countryOfOrigin} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group controlId="actors">
                                        <Form.Label className={"font-weight-bold"}>Actors</Form.Label>
                                        <TagsInput inputProps={{placeholder: ""}} value={program.actors || []}
                                                   onChange={(actors) => {
                                                       this.onTagsChange("actors", actors);
                                                   }}/>
                                        {program.title ?
                                            <a href={`https://www.google.com/search?q=${program.title} actors`}
                                               target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                               type="button">
                                                Search Actors in Google
                                            </a> : null}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="directors">
                                        <Form.Label className={"font-weight-bold"}>Directors</Form.Label>
                                        <TagsInput inputProps={{placeholder: ""}}
                                                   value={program.directors || []}
                                                   onChange={(directors) => {
                                                       this.onTagsChange("directors", directors);
                                                   }}/>
                                        {program.title ?
                                            <a href={`https://www.google.com/search?q=${program.title} director`}
                                               target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                               type="button">
                                                Search Director in Google
                                            </a> : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        genres.length > 0 ? <Form.Group controlId="genres">
                                            <Form.Label className={"font-weight-bold"}>Genres</Form.Label>
                                            <Select
                                                defaultValue={genres.filter((genre) => {
                                                    return _.includes(_.map(program.genres, "name"), genre.name)
                                                }).map((genre) => {
                                                    return {value: genre.name, label: genre.name}
                                                })}
                                                onChange={this.onGenreChange}
                                                isMulti
                                                name="genres"
                                                options={genres.map((genre) => {
                                                    return {value: genre.name, label: genre.name}
                                                })}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                            {program.title ?
                                                <a href={`https://www.google.com/search?q=${program.title} genre`}
                                                   target={'_blank'} className={"btn btn-sm btn-primary margin-top-5px"}
                                                   type="button">
                                                    Search Genre in Google
                                                </a> : null}
                                        </Form.Group> : null
                                    }
                                </Col>
                                <Col>
                                    {
                                        languages.length > 0 ? <Form.Group controlId="genres">
                                            <Form.Label className={"font-weight-bold"}>Audio Languages</Form.Label>
                                            <Select
                                                defaultValue={languages.filter((language) => {
                                                    return _.includes(program.languages, language.name)
                                                }).map((language) => {
                                                    return {value: language.name, label: language.name}
                                                })}
                                                onChange={this.onLanguageChange}
                                                isMulti
                                                name="languages"
                                                options={languages.map((language) => {
                                                    return {value: language.name, label: language.name}
                                                })}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </Form.Group> : null
                                    }
                                </Col>
                                <Col>
                                    <Form.Group controlId="tags">
                                        <Form.Label className={"font-weight-bold"}>Tags</Form.Label>
                                        <TagsInput inputProps={{placeholder: ""}} value={program.tags || []}
                                                   onChange={(tags) => {
                                                       this.onTagsChange("tags", tags);
                                                   }}/>
                                    </Form.Group>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <Form.Group controlId="active">
                                        <Form.Check type="checkbox" label="Active" name='active'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={program.active ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="draft">
                                        <Form.Check type="checkbox" label="Draft" name='draft'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={program.draft ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="free">
                                        <Form.Check type="checkbox" label="Free" name='free'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={program.free ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="prioritized">
                                        <Form.Check type="checkbox" label="Prioritized" name='prioritized'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={program.prioritized ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Settings</Form.Label>
                                {
                                    program.settings.map((setting, index) => {
                                        return <Card className={"margin-bottom-5px"}>
                                            <Card.Body>
                                                <Row>
                                                    <div className="col-md-11">
                                                        <Form.Label className={"font-weight-bold"}>Country</Form.Label>
                                                        <Form.Control as="select" value={setting.country}
                                                                      name='country'
                                                                      onChange={(event) => this.changeSettingHandler(index, event)}>
                                                            <option value=''>Select</option>
                                                            {
                                                                countries.map((country, index) => {
                                                                    return <option value={country.code}
                                                                                   key={index}>
                                                                        {country.name}
                                                                    </option>

                                                                })
                                                            }
                                                        </Form.Control>
                                                        {/*{this.validator.message('Country', translation.country, 'required')}*/}
                                                    </div>
                                                    {/*<div className="col-md-8">*/}
                                                    {/*    <Form.Label className={"font-weight-bold"}>*/}
                                                    {/*        Banner URL</Form.Label>*/}
                                                    {/*    <Form.Control type="text" placeholder="Banner Url"*/}
                                                    {/*                  name='bannerUrl'*/}
                                                    {/*                  value={setting.bannerUrl}*/}
                                                    {/*                  onChange={(event) => this.changeSettingHandler(index, event)}/>*/}
                                                    {/*    /!*{this.validator.message('Title', translation.title, 'required')}*!/*/}

                                                    {/*</div>*/}
                                                    {/*<div className="col-md-1">*/}
                                                    {/*    <Form.Label className={"font-weight-bold"}>*/}
                                                    {/*        Gallery</Form.Label>*/}
                                                    {/*    <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"*/}
                                                    {/*            onClick={this.openGallery}>*/}
                                                    {/*        Open*/}
                                                    {/*    </Button>*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-1">
                                                        <Form.Label className={"font-weight-bold"}>Action</Form.Label>
                                                        <Button onClick={() => this.removeSetting(index)}
                                                                variant="danger"
                                                                className={"btn"}>
                                                            X
                                                        </Button>
                                                    </div>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <div className="col-md-12">
                                                        <Card>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group controlId={`featured${index}`}>
                                                                            <Form.Check type="checkbox"
                                                                                        label="Featured Program"
                                                                                        name='featured'
                                                                                        onChange={(event) => this.toggleChangeSettingHandler(index, event)}
                                                                                        checked={setting.featured ? 'checked' : ''}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group controlId="featuredExpireInDays">
                                                                            <Form.Label className={"font-weight-bold"}>
                                                                                Featured Expire Date
                                                                            </Form.Label>
                                                                            <Form.Control type="datetime-local"
                                                                                          placeholder="YYYY-MM-DD"
                                                                                          name='featuredExpire'
                                                                                          value={format(setting.featuredExpire, "YYYY-MM-DDTHH:mm")}
                                                                                          onChange={(event) => this.changeSettingHandler(index, event)}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label className={"font-weight-bold"}>Featured
                                                                            Display
                                                                            Order</Form.Label>
                                                                        <Form.Control required={"required"} as="select"
                                                                                      value={setting.featuredTabIndex}
                                                                                      name='featuredTabIndex'
                                                                                      onChange={(event) => this.changeSettingHandler(index, event)}>
                                                                            <option value=''>Select</option>
                                                                            {
                                                                                [1, 2, 3, 4, 5].map((value, index) => {
                                                                                    return <option value={value}
                                                                                                   key={index}>{value}</option>

                                                                                })
                                                                            }
                                                                        </Form.Control>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group controlId={`trending${index}`}>
                                                                            <Form.Check type="checkbox"
                                                                                        label="Trending"
                                                                                        name='trending'
                                                                                        onChange={(event) => this.toggleChangeSettingHandler(index, event)}
                                                                                        checked={setting.trending ? 'checked' : ''}/>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col>
                                                                        <Form.Group controlId={`newRelease${index}`}>
                                                                            <Form.Check type="checkbox"
                                                                                        label="New Release"
                                                                                        name='newRelease'
                                                                                        onChange={(event) => this.toggleChangeSettingHandler(index, event)}
                                                                                        checked={setting.newRelease ? 'checked' : ''}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group controlId={`topList${index}`}>
                                                                            <Form.Check type="checkbox"
                                                                                        label="Top Rank"
                                                                                        name='topList'
                                                                                        onChange={(event) => this.toggleChangeSettingHandler(index, event)}
                                                                                        checked={setting.topList ? 'checked' : ''}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Control type="text" placeholder="Rank"
                                                                                      name='rank'
                                                                                      value={setting.rank}
                                                                                      onChange={(event) => this.changeSettingHandler(index, event)}/>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group controlId={`popular${index}`}>
                                                                            <Form.Check type="checkbox"
                                                                                        label="Popular"
                                                                                        name='popular'
                                                                                        onChange={(event) => this.toggleChangeSettingHandler(index, event)}
                                                                                        checked={setting.popular ? 'checked' : ''}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col> <Form.Group controlId={`latestPopular${index}`}>
                                                                        <Form.Check type="checkbox"
                                                                                    label="Mark Popular as Latest"
                                                                                    name='latestPopular'
                                                                                    onChange={(event) => this.toggleChangeSettingHandler(index, event)}
                                                                                    checked={setting.latestPopular ? 'checked' : ''}/>
                                                                    </Form.Group>

                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    })
                                }
                                <Button onClick={this.addSetting} variant="primary"
                                        className={"btn btn-sm margin-top-5px"}>
                                    Add Setting
                                </Button>
                            </Form.Group>

                            {/*<Card>*/}
                            {/*    <Card.Body>*/}
                            {/*        <Row>*/}
                            {/*            <Col>*/}
                            {/*                <Form.Group controlId="featured">*/}
                            {/*                    <Form.Check type="checkbox" label="Featured Program" name='featured'*/}
                            {/*                                onChange={this.toggleChangeHandler}*/}
                            {/*                                checked={program.featured ? 'checked' : ''}/>*/}
                            {/*                </Form.Group>*/}
                            {/*            </Col>*/}
                            {/*        </Row>*/}
                            {/*        <Row>*/}
                            {/*            <Col>*/}
                            {/*                <Form.Group controlId="featuredExpireInDays">*/}
                            {/*                    <Form.Label className={"font-weight-bold"}>*/}
                            {/*                        Featured Expire Date*/}
                            {/*                    </Form.Label>*/}
                            {/*                    <Form.Control type="datetime-local" placeholder="YYYY-MM-DD"*/}
                            {/*                                  name='featuredExpire'*/}
                            {/*                                  value={format(program.featuredExpire, "YYYY-MM-DDTHH:mm")}*/}
                            {/*                                  onChange={this.changeHandler}/>*/}
                            {/*                </Form.Group>*/}
                            {/*            </Col>*/}
                            {/*            <Col>*/}
                            {/*                <Form.Label className={"font-weight-bold"}>Featured Display*/}
                            {/*                    Order</Form.Label>*/}
                            {/*                <Form.Control required={"required"} as="select"*/}
                            {/*                              value={program.featuredTabIndex} name='featuredTabIndex'*/}
                            {/*                              onChange={this.changeHandler}>*/}
                            {/*                    <option value=''>Select</option>*/}
                            {/*                    {*/}
                            {/*                        [1, 2, 3, 4, 5].map((value, index) => {*/}
                            {/*                            return <option value={value}*/}
                            {/*                                           key={index}>{value}</option>*/}

                            {/*                        })*/}
                            {/*                    }*/}
                            {/*                </Form.Control>*/}
                            {/*            </Col>*/}
                            {/*        </Row>*/}
                            {/*    </Card.Body>*/}
                            {/*</Card>*/}
                            <br/>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </Row>
                <MediasModal show={isGalleryOpen} onHide={this.closeGallery} selectBtnText={"Select"}
                             hideOnSelect={true} onSelect={this.onImageUrlSelect}/>
                <StreamSeasonsModal show={isSeasonOpen} onHide={this.closeSeasons}
                                    onSeasonSelect={this.onSeasonSelect}/>
            </div>
        )
    }
}

export default StreamProgramCreateOrEdit