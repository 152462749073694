import React from 'react';
import {Breadcrumb, Button, Card, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import loader from '../../../images/loader.gif'
import {getParams} from "../../../utils/UrlUtil";
import {FEATURE_PERMISSIONS} from "../../../constants/constants";
import Select from 'react-select'
import _ from 'lodash'

class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {id: getParams("id")},
            isInProgress: false
        }
    }

    save = (evt) => {
        evt.preventDefault();
        let {user, isInProgress} = this.state;
        if (!isInProgress) {
            this.toggleInProgress(true);
            http.put("/api/users", {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                enable: user.enable,
                password: user.password || null,
                featurePermissions: user.featurePermissions
            })
                .then((response) => {
                    let {user} = response.data.content;
                    if (user) {
                        window.location.href = "/admin/user/edit?id=" + user.id;
                    }
                }, (error) => {
                    console.log(error)
                }).finally(() => {
                this.toggleInProgress(false)
            })
        }
    };

    changeHandler = (evt) => {
        this.state.user[evt.target.name] = evt.target.value;
        this.setState({user: this.state.user});
    };

    toggleChangeHandler = (evt) => {
        this.state.user[evt.target.name] = !this.state.user[evt.target.name];
        this.setState({user: this.state.user});
    };


    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    onFeaturePermissionsChange = (selectedPermissions) => {
        let {user} = this.state;
        user.featurePermissions = FEATURE_PERMISSIONS.filter((permission) => {
            return _.includes(_.map(selectedPermissions, "value"), permission)
        });
        console.log(user)
        this.setState({user});
    };

    load = (id) => {
        if (id) {
            http.get("/api/users/" + id)
                .then((response) => {
                    let {user} = response.data.content;
                    this.setState({user: user});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    componentDidMount() {
        this.load(getParams("id"))
    }

    render() {
        let {user} = this.state;
        if (user && !user.featurePermissions) {
            user.featurePermissions = []
        }
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={`/admin/user/list`}>Users</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit User</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-6">
                        <Form onSubmit={this.save}>
                            <Form.Group controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Username" name='username'
                                              value={user.username} disabled={"disabled"}/>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name='firstName'
                                                      value={user.firstName} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="lastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name='lastName'
                                                      value={user.lastName} onChange={this.changeHandler}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" placeholder="Password" name='password'
                                              onChange={this.changeHandler}/>
                            </Form.Group>
                            {
                                user.id ? <Form.Group controlId="enable">
                                    <Card>
                                        <Card.Body>
                                            <Form.Label><strong>Feature Permissions</strong></Form.Label>
                                            <Select
                                                defaultValue={user.featurePermissions.filter((permission) => {
                                                    return _.includes(FEATURE_PERMISSIONS, permission)
                                                }).map((permission) => {
                                                    return {value: permission, label: permission}
                                                })}
                                                onChange={this.onFeaturePermissionsChange}
                                                isMulti
                                                name="genres"
                                                options={FEATURE_PERMISSIONS.map((permission) => {
                                                    return {value: permission, label: permission}
                                                })}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Form.Group> : null
                            }
                            <Row>
                                <Col>
                                    <Form.Group controlId="enable">
                                        <Form.Check type="checkbox" label="Active" name='enable'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={user.enable ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>

                </Row>
            </div>
        )
    }
}

export default EditUser