import React from 'react';
import './ProgramDetail.scss'
import {addMyPrograms, downloadFile, getCurrentUser, isLoggedIn, removeLastChar} from "../../utils/Util";
import http from "../../services/HttpService";
import imdb from '../../images/icon_imdb.gif';
import tipafriend from '../../images/new/popup/icon_tipafriend.gif';
import {FaCalendarAlt, FaHeart} from 'react-icons/fa';
import {redirectTo, toQueryString} from "../../utils/UrlUtil";
import loader from '../../images/loader.gif'
import ToastUtil from "../../utils/ToastUtil";
import AddToFavouriteOptionsModal from "./AddToFavouriteOptionsModal";


class ProgramDetail extends React.Component {
    constructor(props) {
        super(props);
        let user = getCurrentUser() || {};
        this.state = {
            canShowTipsToFriend: false,
            canShowAddToFavouriteOptions: false,
            tellToFriendData: {yourName: user.fullName, yourEmail: user.username},
            isInProgress: false
        }
    }

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    handleTellToFriendForm = (evt) => {
        let {tellToFriendData} = this.state;
        tellToFriendData[evt.target.name] = evt.target.value;
        this.setState({tellToFriendData: tellToFriendData})
    };

    submitTellToFriendForm = (evt) => {
        evt.preventDefault();
        this.toggleInProgress(true);
        let {program} = this.props;
        let params = this.state.tellToFriendData;
        params.programId = program.id;
        http.post("/api/users/tellToFriend", params)
            .then((response) => {
                let {status, errors} = response.data;
                if (status) {
                    this.toggleShowTipsToFriend();
                    ToastUtil.success("Skicka ditt meddelande till din vän.");
                } else {
                    ToastUtil.error(errors[0])
                }
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    };

    getCalendarEvent = () => {
        let {program} = this.props;
        http
            .get("/api/programs/calendar/" + program.id)
            .then((response) => {
                let {CalendarEvent} = response.data.content;
                downloadFile("CalendarEvent.ics", CalendarEvent, "text/calendar");
            }).catch((error) => {
            console.log(error)
        });
    };

    gotoIMDB = () => {
        let {program} = this.props;
        redirectTo("https://www.imdb.com/find?q=" + program.name, '_blank');
    };

    showAddToFavouriteOptions = () => {
        let {channel, program} = this.props;
        if (isLoggedIn() && program.seasonLabel) {
            this.setState({canShowAddToFavouriteOptions: true})
        } else {
            this.addProgram(program, channel, false);
        }
    };

    closeAddToFavouriteOptions = () => {
        this.setState({canShowAddToFavouriteOptions: false})
    };

    onFavouriteOptionsSelect = (option) => {
        let addSeries = false;
        let {channel, program} = this.props;
        switch (option) {
            case "SERIES":
                addSeries = true;
        }
        this.addProgram(program, channel, addSeries)
    };

    addProgram = (program, channel, series) => {
        if (isLoggedIn()) {
            let params = {programId: program.id, addSeries: series};
            http.get("/api/programs/addToMyPrograms" + toQueryString(params))
                .then((response) => {
                    let {status, errors} = response.data;
                    if (status) {
                        window.location.reload(false)
                    } else {
                        alert(errors[0])
                    }
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
        } else {
            addMyPrograms(program, channel);
            window.location.reload(false)
        }
    };

    toggleShowTipsToFriend = () => {
        this.setState({canShowTipsToFriend: !this.state.canShowTipsToFriend})
    };

    closeProgramDetail = () => {
        this.props.onHide()
    };

    render() {
        let {channel, program} = this.props;
        let {canShowTipsToFriend, canShowAddToFavouriteOptions} = this.state;
        return (
            <div className="detail-modal">
                <div className="modal-header">
                    <span onClick={this.closeProgramDetail} className="close">X</span>
                    <h5>Programinformation</h5>
                    <div className="meta">
                        <span className={`left-logo largelogo-${channel.sourceId}`}/>
                        <div className="name">
                            <span className="text">{program.name}</span>
                            <span className="time">{program.formattedStartTime}
                                - {program.formattedEndTime}</span>
                        </div>
                    </div>
                </div>
                <div className="modal-content">
                    <p>{program.description}</p>
                    <div>{program.director ? <span>Regissör: {removeLastChar(program.director)}</span> : null}</div>
                    <div>{program.actor ? <span>Skådespelare: {removeLastChar(program.actor)}</span> : null}</div>
                    <div>{program.commentator ? <span>Kommentator: {removeLastChar(program.commentator)}</span> : null}</div>
                    <div>{program.host ? <span>Programledare: {removeLastChar(program.host)}</span> : null}</div>
                    <div className="meta-btm">
                        {program.seasonLabel ? <span>Säsong: {program.seasonLabel} </span> : null}
                        {program.productionYear ? <span>Produktionsår: {program.productionYear}</span> : null}
                        {(program.episodeNumber || program.totalEpisode) ?
                            <span>Avsnitt: {program.episodeNumber} {program.totalEpisode ? '/ ' + program.totalEpisode : null}</span> : null
                        }
                    </div>
                    <div className="meta-btm">
                        {program.repeat ? <span>Repris </span> : ''}
                    </div>
                    <div id="cncpt-rec2"></div>
                </div>

                {!program.expired ? <div className="modal-footer">
                    <ul className="action-list">
                        <li onClick={() => {
                            this.showAddToFavouriteOptions()
                        }}><span><FaHeart/></span>Lägg till min tablå
                        </li>
                        <li onClick={this.getCalendarEvent}><span><FaCalendarAlt/></span>Outlook / ICal</li>
                        <li onClick={this.gotoIMDB}><span><img src={imdb}/></span> Mer info</li>
                        <li onClick={() => {
                            this.toggleShowTipsToFriend()
                        }}><span><img src={tipafriend}/></span>Tipsa en vän
                        </li>
                    </ul>
                    <form onSubmit={this.submitTellToFriendForm}>
                        <div className="mail-to" style={{display: canShowTipsToFriend ? 'block' : "none"}}>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label"> Ditt namn:</label>
                                <div className="col-sm-8">
                                    <input type="text" title="Ditt namn" name="yourName"
                                           onChange={this.handleTellToFriendForm}
                                           value={this.state.tellToFriendData.yourName}
                                           className="form-control"
                                           id="yourName"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label"> Din väns namn:</label>
                                <div className="col-sm-8">
                                    <input type="text" title="Din väns namn" name="friendName"
                                           onChange={this.handleTellToFriendForm}
                                           className="form-control"
                                           id="friendName"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label"> Din email:</label>
                                <div className="col-sm-8">
                                    <input type="email" title="Din email" name="yourEmail"
                                           value={this.state.tellToFriendData.yourEmail}
                                           onChange={this.handleTellToFriendForm}
                                           className="form-control"
                                           id="yourEmail"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label"> Din väns email:</label>
                                <div className="col-sm-8">
                                    <input type="email" title="Din väns email" name="friendEmail"
                                           className="form-control"
                                           onChange={this.handleTellToFriendForm} id="friendEmail"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label"> Personligt meddelande:</label>
                                <div className="col-sm-8">
                                <textarea name="message" rows="2" cols="20" id="message"
                                          onChange={this.handleTellToFriendForm}
                                          title="Personligt meddelande"></textarea>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <input type="submit" className="btn btn-primary btn-sm" value="Skicka"/>
                                    &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                                </div>
                            </div>
                        </div>
                    </form>
                </div> : ''}
                <AddToFavouriteOptionsModal show={canShowAddToFavouriteOptions}
                                            channel={channel} program={program}
                                            onFavouriteOptionsSelect={this.onFavouriteOptionsSelect}
                                            onHide={this.closeAddToFavouriteOptions}/>
            </div>
        )
    }
}

export default ProgramDetail