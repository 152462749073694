import React from 'react';
import MainContent from '../MainContent';
import Register from '../user/Register';
import Settings from '../userPages/Settings';
import FavouritePrograms from '../userPages/FavouritePrograms';
import {Route, Switch} from 'react-router-dom';
import PageContent from "../staticPages/Content";
import MyChannels from "../userPages/MyChannels";
import BlogListing from "../blog/BlogListing";
import BlogDetail from "../blog/BlogDetail";
import StreamList from "../stream/list/StreamList";
import StreamDetail from "../stream/StreamDetail";
import StreamHome from "../stream/home/StreamHome";

class MainRoutes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path='/OmKollaTV' render={() => {
                    return <PageContent type='ABOUT_US'/>
                }}/>
                <Route exact path='/Help' render={() => {
                    return <PageContent type='HELP'/>
                }}/>
                <Route exact path='/Annonsera' render={() => {
                    return <PageContent type='ADVERTISEMENT'/>
                }}/>
                <Route exact path='/Cookies' render={() => {
                    return <PageContent type='COOKIES'/>
                }}/>
                <Route exact path='/Sitemap' render={() => {
                    return <PageContent type='SITEMAP'/>
                }}/>
                <Route exact path="/Artiklar" component={BlogListing}/>

                <Route path="/Stream/Home" exact={true} component={StreamHome}/>
                <Route path="/Stream" exact={true} component={StreamList}/>
                <Route path="/Stream/:streamType" exact={true} component={StreamList}/>
                <Route path="/play/:friendlyUrl" component={StreamDetail}/>

                <Route exact path="/artikel/:friendlyUrl" component={BlogDetail}/>
                <Route exact path='/register' component={Register}/>
                <Route exact path='/MinaKanaler' component={MyChannels}/>
                <Route exact path='/ProgramJagFoljer' component={FavouritePrograms}/>
                <Route exact path='/Installningar' component={Settings}/>
                <Route path="/Sok" component={MainContent}/>
                <Route path="/kanal/:channelFriendlyUrl" component={MainContent}/>
                <Route path="/:type?" component={MainContent}/>
            </Switch>
        )
    }
}

export default MainRoutes;