import React from 'react';
import {Breadcrumb, Card, Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import _ from 'lodash'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams, redirectTo, toQueryString} from "../../../../utils/UrlUtil";
import {ellipsis, isArticle, parseBoolean, prettyBool} from "../../../../utils/Util";
import {DEFAULT_SIZE} from "../../../../constants/constants";
import {format} from "../../../../utils/DateUtil";
import ReactPaginate from 'react-paginate';

class StreamPrograms extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            programs: [],
            countries: [],
            providers: [],
            programTypes: [],
            totalElements: 0,
            isInProgress: false,
            pageCount: 0
        };
        let payload = {
            size: params.get("size") || DEFAULT_SIZE,
            page: params.get("page") || 0,
        };

        if (params.get("q")) {
            payload.q = params.get("q")
        }

        if (params.get("country")) {
            payload.country = params.get("country")
        }

        if (params.get("streamType")) {
            payload.streamType = params.get("streamType")
        }

        if (params.get("providers")) {
            payload.providers = params.get("providers")
        }

        if (parseBoolean(params.get("active"))) {
            payload.active = true
        }

        if (parseBoolean(params.get("draft"))) {
            payload.draft = params.get("draft")
        }

        if (parseBoolean(params.get("free"))) {
            payload.free = params.get("free")
        }
        if (parseBoolean(params.get("featured"))) {
            payload.featured = params.get("featured")
        }
        this.state.payload = payload;
    }

    list = () => {
        this.toggleInProgress(true);
        let params = getParams();
        params.set("sort", "dateCreated");
        params.set("order", "desc");
        params.set("size", 20)
        http.get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                let pageCount = Math.ceil(totalElements / 10);
                this.setState({programs, totalElements, pageCount});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    loadCountries = () => {
        http.get("/api/es/countries")
            .then((response) => {
                let {countries} = response.data.content;
                this.setState({countries});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadStreamTypes = () => {
        http.get("/api/es/programs/types")
            .then((response) => {
                let {programTypes} = response.data.content;
                this.setState({programTypes});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadProviders = () => {
        http
            .get("/api/es/providers")
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    changeHandler = (evt) => {
        let {payload} = this.state;
        if (evt.target.value) {
            payload[evt.target.name] = evt.target.value
        } else {
            delete payload[evt.target.name]
        }
        this.setState({payload});
    };

    toggleChangeHandler = (evt) => {
        let {payload} = this.state;
        if (!payload[evt.target.name]) {
            payload[evt.target.name] = true
        } else {
            delete payload[evt.target.name]
        }
        this.setState({payload});
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };


    search = (e) => {
        e.preventDefault();
        let {payload} = this.state;
        window.location.href = window.location.pathname + toQueryString(payload);
    };

    onPageChange = (data) => {
        let selectedPage = data.selected;
        let params = getParams();
        params.set("page", selectedPage);
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    toggleActive = (id) => {
        if (id) {
            http.get("/api/es/programs/toggleActive/" + id)
                .then((response) => {
                    if (response.data.status) {
                        window.location.reload()
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    getEditUrl = (program) => {
        if (isArticle(program)) {
            return "/admin/stream/blog/edit?id=" + program.id
        }
        return "/admin/stream/program/edit?id=" + program.id
    };

    componentDidMount() {
        this.list();
        this.loadCountries();
        this.loadStreamTypes();
        this.loadProviders();
    }

    render() {
        let {programs, q, totalElements, pageCount, countries, programTypes, providers, payload} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/program/list">Programs</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <Form.Group>
                            <Row>
                                <div className="col-md-3">
                                    <Form.Control as="select" value={payload.country} name='country'
                                                  onChange={this.changeHandler}>
                                        <option value=''>All Countries</option>
                                        {
                                            countries.map((country, index) => {
                                                return <option value={country.code}
                                                               key={index}>{country.name}</option>

                                            })
                                        }
                                    </Form.Control>
                                </div>

                                <div className="col-md-3">
                                    <Form.Control as="select" value={payload.streamType} name='streamType'
                                                  onChange={this.changeHandler}>
                                        <option value=''>All Program Type</option>
                                        {
                                            programTypes.map((type, index) => {
                                                return <option value={type.name} key={index}>{type.label}</option>

                                            })
                                        }
                                    </Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Col>
                                        <Form.Control type="text" placeholder="Search" name="q" value={payload.q}
                                                      onChange={this.changeHandler}/>
                                    </Col>
                                </div>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Check type="checkbox" label="Draft" name='draft'
                                                onChange={this.toggleChangeHandler}
                                                checked={payload.draft ? 'checked' : ''}/>
                                </Col>
                                <Col>
                                    <Form.Check type="checkbox" label="Active" name='active'
                                                onChange={this.toggleChangeHandler}
                                                checked={payload.active ? 'checked' : ''}/>
                                </Col>
                                <Col>
                                    <Form.Check type="checkbox" label="Free" name='free'
                                                onChange={this.toggleChangeHandler}
                                                checked={payload.free ? 'checked' : ''}/>
                                </Col>
                                <Col>
                                    <Form.Check type="checkbox" label="Featured" name='featured'
                                                onChange={this.toggleChangeHandler}
                                                checked={payload.featured ? 'checked' : ''}/>
                                </Col>
                                <Col>
                                    <Form.Control as="select" value={payload.providers} name='providers'
                                                  onChange={this.changeHandler}>
                                        <option value=''>All Providers</option>
                                        {
                                            providers.map((provider, index) => {
                                                return <option value={provider.name}
                                                               key={index}>{provider.label}</option>

                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Row>
                            <Col>
                                <input type={"button"} onClick={this.search} className="btn btn-primary btn-sm"
                                       value={"Search"}/>
                                &nbsp;
                                <input type={"button"} onClick={() => {
                                    redirectTo("/admin/stream/program/list")
                                }} className="btn btn-default btn-sm" value={"Clear"}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <br/>
                <Row>
                    <div className="col-md-9">
                        <Form.Group>
                            <a href="/admin/stream/program/create" className="btn btn-primary btn-sm">Create</a>
                            &nbsp;
                            <a href="/admin/stream/program/manageDraftPrograms" className="btn btn-primary btn-sm">Manage
                                Draft</a>
                            &nbsp;
                            <a href="/admin/stream/program/manageRankPrograms" className="btn btn-primary btn-sm">Manage
                                Rank</a>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <p className="font-weight-bold">
                            Total Programs : {totalElements}
                        </p>
                    </div>
                </Row>
                <div>
                    <table className="table table-sm">
                        <tr>
                            <th>Title</th>
                            <th>Created At</th>
                            <th>Type</th>
                            <th>Providers</th>
                            <th>Active</th>
                            <th>Added From</th>
                            <th>Actions</th>
                        </tr>
                        {programs.map((program, index) => {
                            return <tr key={index}>
                                <td>
                                    <a ref={`${this.getEditUrl(program)}?id=${program.id}`}>{ellipsis(program.title, 150)}</a>
                                </td>
                                <td>{format(program.dateCreated, "DD-MM-YYYY hh:mm a")}</td>
                                <td>{program.programType}</td>
                                <td>{_.join(_.uniq(_.map(program.providers, "provider")), ",")}</td>
                                <td className={program.active ? 'admin-active' : 'admin-inactive'}>{prettyBool(program.active)}</td>
                                <td>{program.addedFrom}</td>
                                <td>
                                    <a href={`${this.getEditUrl(program)}`}
                                       className="btn btn-sm btn-primary">Edit</a>
                                    &nbsp;
                                    <a href="" onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleActive(program.id)
                                    }} className="btn btn-sm btn-primary">
                                        {program.active ? 'Inactive' : 'Active'}
                                    </a>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
                <div>
                    {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={parseInt(pageCount)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={this.onPageChange}
                        containerClassName={'pagination'}
                        initialPage={parseInt(payload.page)}
                        disableInitialCallback={true}
                        activeClassName={'active'}
                    /> : null}
                </div>
            </div>
        )
    }
}

export default StreamPrograms