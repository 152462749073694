import React from 'react';
import {isAboutPage, isAdvertisementPage, isCookiePage, isHelpPage, isSitemapPage} from "../../utils/Util";

function StaticLinks() {
    return (
        <nav className="nav">
            <ul>
                <li>
                    <a className={`nav-link ${isAboutPage() ? 'active' : ''}`} target="_self" href="/OmKollaTV">OmKolla.tv</a>
                </li>
                <li>
                    <a className={`nav-link ${isHelpPage() ? 'active' : ''}`} target="_self" href="/Help">Hjälp</a>
                </li>
                <li>
                    <a className={`nav-link ${isAdvertisementPage() ? 'active' : ''}`} target="_self" href="/Annonsera">Annonsera</a>
                </li>
                <li>
                    <a className={`nav-link ${isCookiePage() ? 'active' : ''}`} target="_self"
                       href="/Cookies">Cookies</a>
                </li>
                <li>
                    <a className={`nav-link ${isSitemapPage() ? 'active' : ''}`} target="_self"
                       href="/Sitemap">Sitemap</a>
                </li>
            </ul>
        </nav>
    )
}


export default StaticLinks