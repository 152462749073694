import React from 'react';
import '../App.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import MainLayout from "./layouts/MainLayout";
import AdminLayout from "./layouts/AdminLayout";
import PrintMyPrograms from "./program/PrintMyPrograms";
import {hasFeaturePermissions, isAdmin, logout, setBlogSettings} from "../utils/Util";
import {redirectTo} from "../utils/UrlUtil";
import ProgramModalContext from './context/ProgramModalContext'
import ProgramDetail from './program/ProgramDetail'
import ForgotPassword from "./user/ForgotPassword";
import AccessDenied from "./user/AccessDenied";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import http from "../services/HttpService";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedChannel: {},
            selectedProgram: {},
            showProgramDetail: false,
            blogSettings: {}
        };
        toast.configure();
    }

    onProgramDetailClose = () => {
        this.setState({
            showProgramDetail: false
        })
    };

    loadBlogSettings = () => {
        http.get("/api/blogs/blogSettings")
            .then((response) => {
                let {blogSettings} = response.data.content;
                setBlogSettings(blogSettings);
                this.setState({blogSettings});
            })
            .catch((error) => {
                console.log(error)
            })
    };

    onProgramDetailOpen = (program, channel) => {
        this.setState({
            selectedProgram: program,
            selectedChannel: channel,
            showProgramDetail: true,
        });
    };

    componentDidMount() {
        // this.loadBlogSettings();
    }

    render() {
        let {showProgramDetail, selectedChannel, selectedProgram, blogSettings} = this.state;
        // if (!_.size(blogSettings)) {
        //     return <div/>
        // }

        return (
            <Router>
                <Switch>
                    <Route exact path='/logout' render={() => {
                        logout();
                        redirectTo("/")
                    }}/>
                    <Route exact path="/admin/:controller/:action?/:id?" render={() => {
                        if (isAdmin() || hasFeaturePermissions()) {
                            return <AdminLayout/>
                        } else {
                            return <AccessDenied/>
                        }
                    }}/>
                    <Route exact path='/PrintMyPrograms' component={PrintMyPrograms}/>
                    <Route exact path="/forgotPassword/:hash" component={ForgotPassword}/>
                    <ProgramModalContext.Provider value={{onProgramDetailOpen: this.onProgramDetailOpen}}>
                        <Route path="/" component={MainLayout}/>
                    </ProgramModalContext.Provider>
                </Switch>
                <div className="modal-overlay" style={{display: showProgramDetail ? 'block' : 'none'}}>
                    <div className=" modal-inner-container">
                        <ProgramDetail className="detail-modal" channel={selectedChannel}
                                       program={selectedProgram}
                                       onHide={this.onProgramDetailClose}/>
                    </div>

                </div>
            </Router>
        )
    }
}

export default App