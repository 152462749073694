import React from 'react';
import {Breadcrumb, Col, Row, Table} from 'react-bootstrap';
import http from "../../../services/HttpService";
import {toQueryString} from "../../../utils/UrlUtil";
import _ from 'lodash'

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            totalElements: 0,
            isInProgress: false
        }
    }

    changeHandler = (evt) => {
        this.setState({[evt.target.name]: this.state.value});
    };

    list = () => {
        this.toggleInProgress(true);
        http.get("/api/categories" + toQueryString({size: 1000}))
            .then((response) => {
                let {categories, totalElements} = response.data.content;
                this.setState({categories: _.orderBy(categories, "name"), totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        })
            .finally(() => {
                this.toggleInProgress(false);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {categories, totalElements, page, q} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Categories</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col><b>Total : {totalElements}</b></Col>
                </Row>
                <br/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>DagensTV Category ID</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        categories.map((category, index) => {
                            return <tr key={index}>
                                <td>{category.id}</td>
                                <td>{category.name}</td>
                                <td>{category.dagensId}</td>
                                <td>
                                    <a href={`/admin/category/edit?id=${category.id}`}
                                       className="btn btn-sm btn-primary">Edit</a>
                                    {' '}
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default Categories