import React from 'react';
import {Breadcrumb, Col, Form, Row, Table} from 'react-bootstrap';
import http from "../../../services/HttpService";
import {getParams} from "../../../utils/UrlUtil";
import {gotoPage} from "../../../utils/Util";
import {format} from "../../../utils/DateUtil";
import {DEFAULT_SIZE} from "../../../constants/constants";

class Programs extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            programs: [],
            size: params.get("size") || DEFAULT_SIZE,
            page: params.get("page") || 0,
            q: params.get("q") || '',
            totalElements: 0,
            isInProgress: false
        }
    }

    changeHandler = (evt) => {
        this.setState({[evt.target.name]: this.state.value});
    };

    list = () => {
        this.toggleInProgress(true);
        let params = getParams();
        http.get("/api/programs?" + params.toString())
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                this.setState({programs: programs, totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        })
            .finally(() => {
                this.toggleInProgress(false);
            })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.list();
    }

    render() {
        let {programs, isInProgress, totalElements, page, q} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Programs</Breadcrumb.Item>
                </Breadcrumb>


                <Row>
                    <Col><b>Total Programs: {totalElements}</b></Col>
                    <Col>
                        <Form onSubmit={this.search}>
                            <Row>
                                <Col>
                                    <Form.Control type="text" placeholder="Search" name="q" value={q}
                                                  onChange={this.changeHandler}/>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <br/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Date Created</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        programs.map((program, index) => {
                            return <tr key={index}>
                                <td>{program.id}</td>
                                <td>{program.name}</td>
                                {/*<td>{program.lastName}</td>*/}
                                {/*<td>{program.enable ? 'Yes' : 'No'}</td>*/}
                                <td>{format(program.dateCreated, "DD-MM-YYYY hh:mm a")}</td>
                                {/*<td>{program.username}</td>*/}
                                <td>
                                    <a href={`/admin/program/edit?id=${program.id}`} className="btn btn-sm btn-primary">Edit</a>
                                    {' '}
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
                <Row>
                    <Col>
                        {page > 0 ? <input type={"button"} className="btn btn-primary btn-sm" value={"Previous"}
                                           onClick={() => {
                                               gotoPage(parseInt(page) - 1)
                                           }}/> : ''}
                    </Col>
                    <Col>
                        <input type={"button"} className="btn btn-primary btn-sm float-right" value={"Next"}
                               onClick={() => {
                                   gotoPage(parseInt(page) + 1)
                               }}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Programs