import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import moment from 'moment-timezone'
import {TIME_ZONE} from "../../../utils/DateUtil";
import loader from '../../../images/loader.gif'
import MediasModal from "../media/MediasModal";


class Show extends React.Component {
    constructor(props) {
        super(props);
        this.state = {channel: {}, isInProgress: false, isGalleryOpen: false}
    }

    saveChannel = (evt) => {
        evt.preventDefault();
        let {channel} = this.state;
        this.toggleInProgress(true);
        http.put("/api/channels", {
            id: channel.id,
            name: channel.name,
            sourceId: channel.sourceId,
            active: channel.active,
            default: channel.default,
            logo: channel.logo,
            dagensId: channel.dagensId
        })
            .then((response) => {
                console.log(response)
            }, (error) => {
                console.log(error)
            }).finally(() => {
            this.toggleInProgress(false)
        })

    };

    changeHandler = (evt) => {
        this.state.channel[evt.target.name] = evt.target.value;
        this.setState({channel: this.state.channel});
    };

    toggleChangeHandler = (evt) => {
        this.state.channel[evt.target.name] = !this.state.channel[evt.target.name];
        this.setState({channel: this.state.channel});
    };

    loadChannel = (id) => {
        if (id) {
            http.get("/api/channels/" + id)
                .then((response) => {
                    let {channel} = response.data.content;
                    this.setState({channel: channel});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    getFormattedDate = (mills) => {
        return moment(new Date(mills)).tz(TIME_ZONE).format("DD-MM-yyyy HH:mm")
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    openGallery = () => {
        this.setState({isGalleryOpen: true});
    };

    closeGallery = () => {
        this.setState({isGalleryOpen: false});
    };

    componentDidMount() {
        let {match: {params}} = this.props;
        this.loadChannel(params.id);
    }

    render() {
        let {channel, isGalleryOpen} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/channel/list">
                        List
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Show</Breadcrumb.Item>
                </Breadcrumb>
                <h5>Channel Detail</h5>
                <br/>
                <Row>
                    <div className="col-md-6">
                        <Form onSubmit={this.saveChannel}>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" name='name'
                                              value={channel.name} onChange={this.changeHandler}/>
                            </Form.Group>
                            <Form.Group controlId="sourceId">
                                <Form.Label>Source ID</Form.Label>
                                <Form.Control type="text" placeholder="sourceId" name='sourceId'
                                              value={channel.sourceId} onChange={this.changeHandler}/>
                            </Form.Group>
                            <Form.Group controlId="dagensId">
                                <Form.Label>Dagens Channel Id</Form.Label>
                                <Form.Control type="text" placeholder="dagens channel Id" name='dagensId'
                                              value={channel.dagensId} onChange={this.changeHandler}/>
                            </Form.Group>

                            <Form.Group controlId="logo">
                                <Form.Label>Channel Logo URL</Form.Label>
                                <Form.Control type="text" placeholder="logo" name='logo'
                                              value={channel.logo} onChange={this.changeHandler}/>
                                <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"
                                        onClick={this.openGallery}>
                                    Browse Media
                                </Button>
                            </Form.Group>
                            <Form.Group controlId="dateCreated">
                                <Form.Label>Date Created</Form.Label>
                                <Form.Control disabled="disabled" type="text" placeholder="Date Created"
                                              value={this.getFormattedDate(channel.dateCreated)}/>
                            </Form.Group>

                            <Form.Group controlId="orderIndex">
                                <Form.Label>Order Index</Form.Label>
                                <Form.Control disabled="disabled" type="text" placeholder="Order Index"
                                              value={channel.tabIndex}/>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group controlId="default">
                                        <Form.Check type="checkbox" label="Default" name='default'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={channel.default ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="active">
                                        <Form.Check type="checkbox" label="Active" name='active'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={channel.active ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>

                </Row>
                <MediasModal show={isGalleryOpen} onHide={this.closeGallery}/>
            </div>
        )
    }
}

export default Show