import React from 'react';
import {Breadcrumb, Card, Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import _ from 'lodash'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams, redirectTo, toQueryString} from "../../../../utils/UrlUtil";
import {ellipsis, parseBoolean, prettyBool} from "../../../../utils/Util";
import {DEFAULT_SIZE} from "../../../../constants/constants";
import {format} from "../../../../utils/DateUtil";
import ReactPaginate from 'react-paginate';
import HasFeaturePermission from "../../../user/HasFeaturePermission";

class StreamlyBlogs extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            programs: [],
            countries: [],
            providers: [],
            programTypes: [],
            totalElements: 0,
            isInProgress: false,
            pageCount: 0
        };
        let payload = {
            size: params.get("size") || DEFAULT_SIZE,
            page: params.get("page") || 0,
        };

        if (params.get("q")) {
            payload.q = params.get("q")
        }

        if (params.get("country")) {
            payload.country = params.get("country")
        }

        if (params.get("streamType")) {
            payload.streamType = params.get("streamType")
        }

        if (params.get("providers")) {
            payload.providers = params.get("providers")
        }

        if (parseBoolean(params.get("active"))) {
            payload.active = true
        }

        if (parseBoolean(params.get("draft"))) {
            payload.draft = params.get("draft")
        }

        if (parseBoolean(params.get("free"))) {
            payload.free = params.get("free")
        }
        this.state.payload = payload;
    }

    list = () => {
        this.toggleInProgress(true);
        let params = getParams();
        params.set("sort", "dateCreated");
        params.set("order", "desc");
        params.set("providers", "streamly");
        http.get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                let pageCount = Math.ceil(totalElements / 10);
                this.setState({programs, totalElements, pageCount});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    loadCountries = () => {
        http.get("/api/es/countries")
            .then((response) => {
                let {countries} = response.data.content;
                this.setState({countries});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadStreamTypes = () => {
        http.get("/api/es/programs/types")
            .then((response) => {
                let {programTypes} = response.data.content;
                this.setState({programTypes});
            }).catch((error) => {
            console.log(error)
        })
    };

    loadProviders = () => {
        http
            .get("/api/es/providers")
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    changeHandler = (evt) => {
        let {payload} = this.state;
        if (evt.target.value) {
            payload[evt.target.name] = evt.target.value
        } else {
            delete payload[evt.target.name]
        }
        this.setState({payload});
    };

    toggleChangeHandler = (evt) => {
        let {payload} = this.state;
        if (!payload[evt.target.name]) {
            payload[evt.target.name] = true
        } else {
            delete payload[evt.target.name]
        }
        this.setState({payload});
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };


    search = (e) => {
        e.preventDefault();
        let {payload} = this.state;
        window.location.href = window.location.pathname + toQueryString(payload);
    };

    onPageChange = (data) => {
        let selectedPage = data.selected;
        let params = getParams();
        params.set("page", selectedPage);
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    toggleActive = (id) => {
        if (id) {
            http.get("/api/es/programs/toggleActive/" + id)
                .then((response) => {
                    if (response.data.status) {
                        window.location.reload()
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    componentDidMount() {
        this.list();
        this.loadCountries();
        this.loadStreamTypes();
        this.loadProviders();
    }

    render() {
        let {programs, q, totalElements, pageCount, countries, programTypes, providers, payload} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/blog/list">Blogs</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Control type="text" placeholder="Search" name="q" value={payload.q}
                                                  onChange={this.changeHandler}/>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Row>
                            <Col>
                                <input type={"button"} onClick={this.search} className="btn btn-primary btn-sm"
                                       value={"Search"}/>
                                &nbsp;
                                <input type={"button"} onClick={() => {
                                    redirectTo("/admin/stream/program/list")
                                }} className="btn btn-default btn-sm" value={"Clear"}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <br/>
                <Row>
                    <div className="col-md-10">
                        <HasFeaturePermission permission={'streamly.blog.write'}>
                            <Form.Group>
                                <a href="/admin/stream/blog/create" className="btn btn-primary btn-sm">Create</a>
                            </Form.Group>
                        </HasFeaturePermission>
                    </div>
                    <div className="col-md-2">
                        <p className="font-weight-bold">
                            Total Blogs : {totalElements}
                        </p>
                    </div>
                </Row>
                <div>
                    <table className="table">
                        <tr>
                            <th>Title</th>
                            <th>Created At</th>
                            <th>Type</th>
                            <th>Providers</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                        {programs.map((program, index) => {
                            return <tr key={index}>
                                <td>
                                    <a href={"/admin/stream/blog/edit?id=" + program.id}>{ellipsis(program.title, 150)}</a>
                                </td>
                                <td>{format(program.dateCreated, "DD-MM-YYYY hh:mm a")}</td>
                                <td>{program.programType}</td>
                                <td>{_.join(_.uniq(_.map(program.providers, "provider")), ",")}</td>
                                <td className={program.active ? 'admin-active' : 'admin-inactive'}>{prettyBool(program.active)}</td>
                                <td>
                                    <HasFeaturePermission permission={'streamly.blog.write'}>
                                        <a href={"/admin/stream/blog/edit?id=" + program.id}
                                           className="btn btn-sm btn-primary">Edit</a>
                                    </HasFeaturePermission>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
                <div>
                    {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={parseInt(pageCount)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={this.onPageChange}
                        containerClassName={'pagination'}
                        initialPage={parseInt(payload.page)}
                        disableInitialCallback={true}
                        activeClassName={'active'}
                    /> : null}
                </div>
            </div>
        )
    }
    }

    export default StreamlyBlogs