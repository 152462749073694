import React, {useEffect, useState} from "react";
import http from "../../services/HttpService";
import "../blog/blogListing.scss";
import {format} from "../../utils/DateUtil";
import {Helmet} from "react-helmet";
import {
    findOrGetDefaultTranslation, getAdsLabel,
    getHeaderDesktopAds,
    getProgramImage,
    getProviderLabel,
    getSelectedCountry,
    getTopDesktopAds,
    isAdmin,
    isLatestTvShow,
    isLive,
    prettyTime,
} from "../../utils/Util";
import {isDesktop, isMobileDevice, isTableDevice,} from "../../utils/DeviceUtil";
import UserAction from "./UserAction";
import "./streamDetail.scss";
import _ from "lodash";
import StreamCarousel from "./StreamCarousel";
import {redirectTo} from "../../utils/UrlUtil";
import ReactPlayer from "react-player/youtube";
import StreamPlay from "./StreamPlay";
import moment from 'moment'
import 'moment/locale/sv'

const StreamDetail = (props) => {
    const [program, setProgram] = useState({});
    const [isProgramPresent, setIsProgramPresent] = useState(true);
    const [favourite, setFavourite] = useState({});
    const [favouriteLoaded, setFavouriteLoaded] = useState(false);
    const [seasonPrograms, setSeasonPrograms] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState({});
    const [showPlay, setShowPlay] = useState(false);

    const onPlay = () => {
        setShowPlay(true)
    };

    const closePlay = () => {
        setShowPlay(false);
    };

    const setTitleAndMetadata = (program) => {
        if (program) {
            return (
                <Helmet>
                    <title>{"Kolla.tv - " + program.title}</title>
                    <meta name="description" content={program.description}/>
                </Helmet>
            );
        }
    };

    const getStructuredData = (program) => {
        let data = {};
        if (program) {
            data = {
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                mainEntityOfPage: {
                    "@type": "WebPage",
                    "@id": `https://www.kolla.tv/play/${program.friendlyUrl}`,
                },
                headline: `${program.title}`,
                description: `${program.description}`,
                image: `${program.imageUrl}`,
                author: {
                    "@type": "Person",
                    name: `${program.author || "Peter Grufman"}`,
                },
                publisher: {
                    "@type": "Organization",
                    name: "kolla.tv",
                    logo: {
                        "@type": "ImageObject",
                        url: "",
                    },
                },
                datePublished: `${format(program.dateCreated, "YYYY-MM-DD")}`,
            };
        }
        return JSON.stringify(data);
    };

    const setStructureData = (program) => {
        if (program && program.id) {
            return (
                <Helmet>
                    <script type="application/ld+json">
                        {getStructuredData(program)}
                    </script>
                </Helmet>
            );
        }
    };

    const findAllProgramsBySeason = (season) => {
        if (season) {
            http
                .get("/api/es/programs/findAllBySeason/" + season.friendlyUrl)
                .then((response) => {
                    let {programs} = response.data.content;
                    setSeasonPrograms(programs);
                    setSelectedSeason(season);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const getFavourite = (program) => {
        if (program) {
            http
                .get("/api/es/favourites/kollatv/me/" + program.id)
                .then((response) => {
                    let {favourite} = response.data.content;
                    setFavourite(favourite || {});
                    setFavouriteLoaded(true)
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setFavouriteLoaded(true)
        }
    };

    const onSeasonChange = (e) => {
        let value = e.target.value;
        let season = _.filter(program.seasons, {friendlyUrl: value})[0];
        findAllProgramsBySeason(season);
    };

    const findByFriendlyUrl = () => {
        const {
            match: {params},
        } = props;
        let friendlyUrl = params.friendlyUrl;
        http
            .get("/api/es/programs/findByFriendlyUrl/" + friendlyUrl)
            .then((response) => {
                let {program} = response.data.content;
                setIsProgramPresent(program && program.friendlyUrl);
                getFavourite(program);
                if (program.season) {
                    findAllProgramsBySeason(program.season);
                } else if (_.size(program.seasons) > 0) {
                    findAllProgramsBySeason(program.seasons[0]);
                }
                setProgram(program);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        findByFriendlyUrl();
    }, []);

    if (isProgramPresent) {
        let translation = findOrGetDefaultTranslation(program, getSelectedCountry())
        return (
            <div className="program-details">
                {setTitleAndMetadata(program)}
                {setStructureData(program)}

                <div className="blog-ads">
                    {getTopDesktopAds()}
                    {getHeaderDesktopAds()}
                    {isTableDevice() ?<>{getAdsLabel()}<div id="cncpt-tab_lb1"></div> </> : ""}
                </div>
                <span className="back-btn">
          <a href={"/Stream"} target={"_self"}>
            {"<"} TILLBAKA
          </a>
        </span>

                <div className="detail">
                    <div className="detail-section">
                        <div className="top-section">
                            <div className="leftCol">
                                <div className="image">
                                    <a href={program.sourceReference}
                                       title={program.provider}
                                       target={"_blank"}>
                                        {isLive(program) ?
                                            <span className="live-badge">Live</span> : null}
                                        {isLatestTvShow(program) ? <span className="live-badge">Ny säsong</span> : null}

                                        <img src={program.imageUrl} alt={program.title}/>
                                    </a>
                                </div>
                                {favouriteLoaded ? (
                                    <UserAction onPlay={onPlay} showPlayBtn={!!program.videoUrl} favourite={favourite}
                                                programId={program.id}/>
                                ) : null}
                            </div>

                            <div className="info">
                                <div className="top">
                                    <h1>
                                        {translation.title}{" "}
                                        {program.year ? <span>({program.year})</span> : null}
                                    </h1>
                                    {isAdmin() ?
                                        <a className="float-right admin-link" target={"_blank"}
                                           href={"/admin/stream/program/edit?id=" + program.id}>Edit</a> : null}

                                </div>
                                <p>{translation.description}</p>
                                <span className="tag">
                                            <a
                                                href={program.sourceReference}
                                                title={program.provider}
                                                target={"_blank"}
                                            >
                                            Watch On
                                            </a>
                                            </span>
                                {program.providers ? (
                                    <h2>
                                        {_.filter(program.providers, ["country", getSelectedCountry()]).map((provider) => {
                                            return (
                                                <a
                                                    href={provider.detailUrl}
                                                    title={getProviderLabel(provider.provider)}
                                                    target={"_blank"}
                                                >
                                                    <span
                                                        className={`${provider.provider}_icon`}> </span>
                                                </a>
                                            );
                                        })}
                                    </h2>
                                ) : null}
                                <ul>
                                    {program.tags ? (
                                        <li>
                                            <span className="label">Tags</span>
                                            <span>
                        {program.tags.map((tag) => {
                            return (
                                <span className="badge badge-light">{tag}</span>
                            );
                        })}
                      </span>
                                        </li>
                                    ) : null}
                                    {program.directors ? (
                                        <li>
                                            <span className="label">Director</span>
                                            <span>{_.join(program.directors, ", ")}</span>
                                        </li>
                                    ) : null}

                                    {program.actors ? (
                                        <li>
                                            <span className="label">Cast</span>
                                            <span>{_.join(program.actors, ", ")}</span>
                                        </li>
                                    ) : null}

                                    {program.languages ? (
                                        <li>
                                            <span className="label">Languages</span>
                                            <span>{_.join(program.languages, ", ")}</span>
                                        </li>
                                    ) : null}
                                    {program.duration ? (
                                        <li>
                                            <span className="label">Length</span>
                                            <span>{prettyTime(program.duration)}</span>
                                        </li>
                                    ) : null}

                                    {_.size(program.genres) > 0 ? (
                                        <li>
                                            <span className="label">Genre</span>
                                            <span>{_.join(_.map(program.genres, "name"), ", ")}</span>
                                        </li>
                                    ) : null}

                                    {program.year ? (
                                        <li>
                                            <span className="label">Year:</span>
                                            <span>{program.year}</span>
                                        </li>
                                    ) : null}
                                    {program.rating ? (
                                        <li>
                                            <span className="label">Rating:</span>
                                            <span>{program.rating}</span>
                                        </li>
                                    ) : null}

                                    {program.age ? (
                                        <li>
                                            <span className="label">Age:</span>
                                            <span>{program.age}</span>
                                        </li>
                                    ) : null}
                                    {program.season ? (
                                        <li>
                                            <span className="label">Season:</span>
                                            <span>{program.season.number}</span>
                                        </li>
                                    ) : null}

                                    {program.episodeNumber ? (
                                        <li>
                                            <span className="label">Episode:</span>
                                            <span>{program.episodeNumber}</span>
                                        </li>
                                    ) : null}
                                    {program.countryOfOrigin ? (
                                        <li>
                                            <span className="label">Country:</span>
                                            <span>{program.countryOfOrigin}</span>
                                        </li>
                                    ) : null}
                                    {program.programType ? (
                                        <li>
                                            <span className="label">Type:</span>
                                            <span>{program.programType === 'live' ? 'Sport' : program.programType}</span>
                                        </li>
                                    ) : null}

                                    {program.liveStartDate ? (
                                        <li>
                                            <span className="label">Start:</span>
                                            <span>{moment(program.liveStartDate).format("DD-MMMM, YYYY hh:MM a")}</span>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>

                        </div>

                        {program.videoUrl ? (
                            <div className="related-videos">
                                <h2>Trailer {translation.title}</h2>
                                <ul>

                                    <li>
                                        <div className="player-wrapper">
                                            <ReactPlayer controls={true}
                                                         className="react-player"
                                                         url={program.videoUrl}
                                                         width="100%"
                                                         height="100%"
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        ) : null}

                        {
                            program.videoUrls ? <div className="related-videos">
                                <h2>Släpvagnar, Teasers av {translation.title}</h2>
                                <ul className="related-videos">
                                    {
                                        program.videoUrls.map((url) => {
                                            return (
                                                <li>
                                                    <ReactPlayer controls={true} className="react-playe"
                                                                 url={url} width="100%"
                                                                 height="100%"/>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div> : null
                        }

                        {program.seasons || program.season ? (
                            <div className="multiple-seasons">
                                <br/>

                                <div className="episode-section">
                                    <div>
                                        <div className="justifySpaceBetween">
                                            {/* <h5>{selectedSeason.name}</h5> */}
                                            <h5>Säsong</h5>
                                            {program.seasons ? (
                                                <div className="custom-selectBox ">
                                                    <div>
                                                        <form>
                                                            <select
                                                                value={selectedSeason.friendlyUrl}
                                                                onChange={onSeasonChange}
                                                            >
                                                                {program.seasons.map((season, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={season.friendlyUrl}
                                                                        >
                                                                            {season.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </form>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <ul className="season-episode">
                                            {_.orderBy(seasonPrograms, "episodeNumber").map((program) => {
                                                return (
                                                    <li
                                                        onClick={() =>
                                                            redirectTo("/play/" + program.friendlyUrl)
                                                        }
                                                    >
                                                        <div className="inner-content">
                                                            <div className="image">
                                                                <img src={getProgramImage(program)}/>
                                                            </div>
                                                            <div className="episode-info">
                                                                <div className="cardList-title">
                                                                    <h3>{translation.title}</h3>
                                                                    <span>{program.duration}
                                                                        min</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

                {/*Display second ads */}

                <div className="blog-ads">
                    {isDesktop() ? <div id="cncpt-lb2"></div> : ""}
                    {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ""}
                    {isMobileDevice() ? <div id="cncpt-mob2"></div> : ""}
                </div>

                <h3> People who liked {translation.title} also liked </h3>
                {program.providers ? (
                    <StreamCarousel
                        heading={" "}
                        country={getSelectedCountry()}
                        providers={_.map(program.providers, "provider")}
                        genres={_.map(program.genres, "name")}
                    />
                ) : null}

                {/*TODO display third ads*/}
                <div className="blog-ads">
                    {isDesktop() ? <div id="cncpt-lb3"></div> : ""}
                    {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ""}
                    {isMobileDevice() ? <div id="cncpt-mob3"></div> : ""}
                </div>
                <StreamPlay show={showPlay} onHide={closePlay} program={program}/>
            </div>
        );
    } else if (!isProgramPresent) {
        return (
            <div class="row">
                <div class="col-md-12">
                    <div class="error-template text-center">
                        <br/>
                        <br/>
                        <br/>
                        <h1>Oops!</h1>
                        <h2>404 Ej Hittad</h2>
                        <div class="error-details">Tyvärr, begärd sida hittades inte!</div>
                        <br/>
                        <div class="error-actions">
                            <a href="/Stream" target="_self" class="btn btn-primary btn">
                                Stream
                            </a>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
};
export default StreamDetail;
