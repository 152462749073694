import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../../services/HttpService";
import loader from '../../../../images/loader.gif'
import ToastUtil from "../../../../utils/ToastUtil";
import {getParams} from "../../../../utils/UrlUtil";
import 'react-tagsinput/react-tagsinput.css'
import MediasModal from "../../media/MediasModal";
import SimpleReactValidator from 'simple-react-validator';
import {format} from "../../../../utils/DateUtil";
import CKEditor from 'ckeditor4-react';
import HasFeaturePermission from "../../../user/HasFeaturePermission";

class StreamlyBlogCreateOrEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            program: {},
            isInProgress: false,
            isGalleryOpen: false
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    save = (evt) => {
        evt.preventDefault();
        let {program} = this.state;
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http.post("/api/es/blogs", {
                id: program.id,
                title: program.title,
                active: program.active,
                description: program.description,
                imageUrl: program.imageUrl,

            })
                .then((response) => {
                    let {program} = response.data.content;
                    if (response.data.status) {
                        window.location.href = "/admin/stream/blog/edit?id=" + program.id;
                    } else {
                        ToastUtil.error(response.data.errors[0]);
                    }
                }, (error) => {
                    console.log(error)
                }).finally(() => {
                this.toggleInProgress(false)
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    };

    changeHandler = (evt) => {
        this.state.program[evt.target.name] = evt.target.value;
        this.setState({program: this.state.program});
    };


    toggleChangeHandler = (evt) => {
        this.state.program[evt.target.name] = !this.state.program[evt.target.name];
        this.setState({program: this.state.program});
    };

    loadProgram = (id) => {
        if (id) {
            http.get("/api/es/programs/" + id)
                .then((response) => {
                    let {program = {}} = response.data.content;
                    this.setState({initCKEditor: true, program});
                }).catch((error) => {
                console.log(error)
            })
        } else {
            this.setState({initCKEditor: true});
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    openGallery = () => {
        this.setState({isGalleryOpen: true});
    };

    closeGallery = () => {
        this.setState({isGalleryOpen: false});
    };

    onImageUrlSelect = (url) => {
        let {program} = this.state;
        program.imageUrl = url;
        this.setState({program})
    };

    onEditorChange = (evt) => {
        let {program} = this.state;
        program["description"] = evt.editor.getData();
        this.setState({program});
    };

    componentDidMount() {
        this.loadProgram(getParams("id"));
    }

    render() {
        let {program, isGalleryOpen, initCKEditor} = this.state;

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/stream/blog/list">
                        Blogs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.save} method="POST" novalidate={"novalidate"}>
                            <Form.Group controlId="title">
                                <Form.Label className={"font-weight-bold"}>Title *</Form.Label>
                                <Form.Control type="text" placeholder="Title" name='title' required="required"
                                              value={program.title} onChange={this.changeHandler}/>
                                <div>{this.validator.message('title', program.title, 'required')}</div>
                            </Form.Group>


                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Article Content</Form.Label>
                                {initCKEditor ? <CKEditor data={program.description}
                                                          onChange={this.onEditorChange}/> : null}
                                <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"
                                        onClick={this.openGallery}>
                                    Browse Media And Select Image URL for Blog
                                </Button>
                            </Form.Group>
                            {program.id ? <Row>
                                <Col>
                                    <Form.Group controlId="dateCreated">
                                        <Form.Label className={"font-weight-bold"}>Date Created *</Form.Label>
                                        <Form.Control type="text" name='dateCreated'
                                                      value={format(program.dateCreated, "DD-MM-YYYY hh:mm a")}
                                                      disabled={"disabled"}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="friendlyUrl">
                                        <Form.Label className={"font-weight-bold"}>Friendly URL *</Form.Label>
                                        <Form.Control type="text" name='friendlyUrl'
                                                      value={program.friendlyUrl} disabled={"disabled"}/>
                                    </Form.Group>
                                </Col>
                            </Row> : null}

                            <Form.Group controlId="imageUrl">
                                <Form.Label className={"font-weight-bold"}>Image URL *</Form.Label>
                                <Form.Control type="url" placeholder="Image Url" name='imageUrl' required="required"
                                              value={program.imageUrl} onChange={this.changeHandler}/>
                                <div>{this.validator.message('Image url', program.imageUrl, 'url')}</div>
                                <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"
                                        onClick={this.openGallery}>
                                    Choose Program Image
                                </Button>
                                {program.imageUrl ? <div className="margin-top-5px">
                                    <img src={program.imageUrl} width={250}/>
                                </div> : null}
                            </Form.Group>


                            <Row>
                                <Col>
                                    <Form.Group controlId="active">
                                        <Form.Check type="checkbox" label="Active" name='active'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={program.active ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>

                            </Row>

                            <HasFeaturePermission permission={'streamly.blog.write'}>
                                <Button variant="primary" type="submit">
                                    Save
                                </Button>
                                &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                            </HasFeaturePermission>
                        </Form>
                    </div>
                </Row>
                <MediasModal show={isGalleryOpen} onHide={this.closeGallery}/>
            </div>
        )
    }
}

export default StreamlyBlogCreateOrEdit