import React, {useState} from "react";
import Login from "../user/Login";
import http from "../../services/HttpService";
import {isLoggedIn} from "../../utils/Util";

const UserAction = (props) => {
    const [showLogin, setShowLogin] = useState(false);
    const [favourite, setFavourite] = useState(props.favourite || {});

    let {pageType = {}, showPlayBtn} = props;

    const openLogin = () => {
        setShowLogin(true)
    };

    const closeLogin = (e) => {
        setShowLogin(false)
    };

    const saveFavourite = () => {
        if (props.programId) {
            favourite.programId = props.programId;
            http
                .post("/api/es/favourites/kollatv", favourite).then((response) => {
                let {favourite} = response.data.content;
                setFavourite(favourite || {});
            }).catch((error) => {
                console.log(error);
            });
        }
    };


    const play = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let {onPlay} = props;
        if (onPlay) {
            onPlay()
        }
    };

    const like = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.like = !favourite.like;
            if (favourite.like) {
                favourite.dislike = false
            }
            saveFavourite();
        } else {
            openLogin();
        }
    };

    const dislike = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.dislike = !favourite.dislike;
            if (favourite.dislike) {
                favourite.like = false
            }
            saveFavourite();
        } else {
            openLogin();
        }
    };


    const watch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.watch = !favourite.watch;
            saveFavourite();
        } else {
            openLogin();
        }
    };

    return <div>
        <div className="user-action">
            {pageType === 'ProgramDetails' ?
                <span class="title"><strong>Sign in</strong> to create Watchlist</span> : ''}

            <a href="" onClick={like} title="Like"
               className={`like-icon ${favourite.like ? 'liked' : ''}`}/>
            <a href="" onClick={dislike} title="Dislike"
               className={`dislike-icon ${favourite.dislike ? 'disliked' : ''}`}/>
            
            < a href="" onClick={watch} title="Add to WatchList"
                className={`addTOlist-icon2 ${favourite.watch ? 'watched' : ''}`}/>
        </div>
        <Login show={showLogin} onHide={closeLogin}/>
        {showPlayBtn ? <a href="" onClick={play} title="Visa trailer"
                              className={`play-icon ${favourite.watch ? 'watched' : ''}`}/> : null}
    </div>
};

export default UserAction;