import React from 'react';
import './Header.scss';
import TopNavigation from '../TopNavigation';
import Search from '../Search';
import logo from '../../images/logo.webp';
import logoFilm from '../../images/logo-film.webp';
import logoSeries from '../../images/logo-serier.webp';
import logoSport from '../../images/logo-sport.webp';
import {getParams} from "../../utils/UrlUtil";
import {
    isArticleDetailPage,
    isArticleListPage,
    isFilmPage,
    isSeriesPage,
    isSportsPage,
    isStreamDetail,
    isStreamPage
} from "../../utils/Util";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: getParams("q") || ''
        }
    }

    onSearchSubmit = searchTerm => {
        let params = getParams();
        if (searchTerm) {
            params.set("q", searchTerm);
        } else {
            params.delete("q")
        }
        if (isArticleListPage() || isArticleDetailPage()) {
            window.location.href = "/Artiklar?" + params.toString();

        } else if (isStreamPage() || isStreamDetail()) {
            window.location.href = "/Stream?" + params.toString();

        } else {
            window.location.href = "/Sok?" + params.toString();
        }

    };

    getLogo = () => {
        let _logo = logo;
        if (isSeriesPage()) {
            _logo = logoSeries
        } else if (isSportsPage()) {
            _logo = logoSport
        } else if (isFilmPage()) {
            _logo = logoFilm
        }
        return <img src={_logo} alt={"KollaTv"}/>
    };

    render() {
        let {searchTerm} = this.state;
        return (
            <div>
                <header className="container">
                    <div className="row">
                        <h1 className="col-xs-12 col-sm-12 col-md-5 col-lg-5 padding0">
                            <a href="/" className="logo" target="_self" title="Kolla.tv - Din personliga Tvtablå">
                                {this.getLogo()}
                            </a>
                        </h1>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 padding0">
                            <TopNavigation/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 padding0">
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 padding0">
                            <Search onFormSubmit={this.onSearchSubmit} searchTerm={searchTerm}/>
                        </div>

                    </div>
                </header>
            </div>
        )
    }
}

export default Header;


