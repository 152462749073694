import React from "react";
import "../ChannelList.scss";
import _ from "lodash";
import {
    ellipsis,
    getTimelineDurations,
    isChannelDetailPage,
    isDefaultLayoutSelected,
    isTimelineLayoutSelected
} from "../../utils/Util";
import moment from "moment";
import ProgramModalContext from "../context/ProgramModalContext";

class Programs extends React.Component {
  static contextType = ProgramModalContext;

  constructor(props) {
    super(props);
    this.state = {
      size: props.initSize,
      onlyNonExpired: props.onlyNonExpired,
      rowScrollMargin: 0,
    };
  }

  getPrograms = (size, onlyNonExpired) => {
    let { programs } = this.props;

    if (_.size(programs)) {
      if (onlyNonExpired) {
        programs = programs.filter((program) => {
          return !program.expired;
        });
      }
      if (size > 0) {
        return _.sortBy(programs.slice(0, size), ["startTime"]);
      }
      return _.sortBy(programs, ["startTime"]);
    }
    return [];
  };

  viewAll = () => {
    let { initSize } = this.props;
    if (this.state.size > 0) {
      this.setState({ size: 0, onlyNonExpired: false });
    } else {
      this.setState({ size: initSize, onlyNonExpired: true });
    }
  };

  getViewAll = () => {
    let { initSize } = this.props;
    if (_.size(this.getPrograms(0)) > 0) {
      return (
        <li onClick={this.viewAll} className="viewAll">
          <span> &#8250; Visa hela dagen</span>
        </li>
      );
    }
  };

  getProgramClasses = (program) => {
    if (program.onTelecast) {
      return "active";
    } else if (program.expired) {
      return "inactive";
    }
    return "";
  };

  getProgramStatusSpan = (program) => {
    return <span className="status">{program.live ? "Live" : ""}</span>;
  };

  UNSAFE_componentWillMount() {
    this.setState({ size: this.props.initSize });
  }

  render() {
    let { initSize, rowScrollMargin = 0 } = this.props;
    let { size, onlyNonExpired } = this.state;
    let programPassedLeft = 0,
      programPassedMinutes = 0;
    let firstTimeline = getTimelineDurations(1)[0];

    return (
      <ul style={{ left: `${rowScrollMargin}px` }}>
        {this.getPrograms(size, onlyNonExpired).map((program, index) => {
          // console.log('program', program)
          let style = {};
          if (isTimelineLayoutSelected()) {
            let minutes = moment
              .duration(moment(program.endTime).diff(moment(program.startTime)))
              .asMinutes();
            let width = Math.ceil((minutes * 142) / 30);
            programPassedMinutes = moment
              .duration(firstTimeline.date.diff(moment(program.startTime)))
              .asMinutes();
            programPassedLeft = Math.ceil((-programPassedMinutes * 142) / 30);
            style = {
              position: "absolute",
              left: `${programPassedLeft + 15}px`,
              minWidth: `${width}px`,
              width: `${width}px`,
            };
          }
          return (
            <li
              onClick={() => {
                this.context.onProgramDetailOpen(program, this.props.channel);
              }}
              key={program.id}
              className={`${this.getProgramClasses(program)}`}
              style={style}
              title={`${program.formattedStartTime} ${program.name}`}
            >
              <h3>
                <span className="time">{program.formattedStartTime}&nbsp;</span>
                {isTimelineLayoutSelected()
                  ? this.getProgramStatusSpan(program)
                  : ""}
                <span className="program"> {program.name}&nbsp;</span>
                {isDefaultLayoutSelected()
                  ? this.getProgramStatusSpan(program)
                  : ""}
              </h3>
              {isChannelDetailPage() ? <div>
                <p>
                    <span>
                    {program.formattedStartTime} : {program.formattedEndTime} | </span>

                    {program.seasonLabel ? (
                    <span> Säsong: {program.seasonLabel} | </span>
                    ) : null}
                    {program.productionYear ? (
                    <span> Produktionsår: {program.productionYear} | </span>
                    ) : null}
                    {program.episodeNumber || program.totalEpisode ? (
                    <span>
                        Avsnitt: {program.episodeNumber}{" "}
                        {program.totalEpisode ? "/ " + program.totalEpisode : null}
                    </span>
                    ) : null}
                </p>
                <p>{ellipsis(program.description, 200)}</p>
              </div>
              : ''}
            </li>
          );
        })}
        {initSize > 0 ? this.getViewAll() : ""}
      </ul>
    );
  }
}

export default Programs;
