import {toast} from 'react-toastify';

let ToastUtil = {
    success: (message) => {
        toast.success(message)
    },
    error: (error) => {
        toast.error(error)
    }
}
export default ToastUtil