import React from 'react';

class AccessDenied extends React.Component {

    render() {
        return <div className="container text-center">
            <br/>
            <h1>Access Denied (403)</h1>
            <hr/>
            <p>
                You do not have permission to access this page.
                <br/>Please contact your Site Administrator(s) to request access
                <br/>
                Try <a href="/logout">logging out</a> and log in with a different account
                <a href="/" target="_blank"> kolla.tv</a>
            </p>
        </div>
    }
}

export default AccessDenied