import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import loader from '../../../images/loader.gif'
import {getParams} from "../../../utils/UrlUtil";


class CreateOrEditBlogCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {id: getParams("id")},
            isInProgress: false,

        }
    }

    save = (evt) => {
        evt.preventDefault();
        let {category, isInProgress} = this.state;
        if (!isInProgress) {
            this.toggleInProgress(true);
            http.post("/api/blogCategories", {
                id: category.id,
                name: category.name,
                active: category.active
            })
                .then((response) => {
                    let {category} = response.data.content;
                    if (category) {
                        window.location.href = "/admin/blogCategory/edit?id=" + category.id;
                    }
                }, (error) => {
                    console.log(error)
                }).finally(() => {
                this.toggleInProgress(false)
            })
        }
    };

    changeHandler = (evt) => {
        this.state.category[evt.target.name] = evt.target.value;
        this.setState({category: this.state.category});
    };

    toggleChangeHandler = (evt) => {
        this.state.category[evt.target.name] = !this.state.category[evt.target.name];
        this.setState({category: this.state.category});
    };


    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    load = (id) => {
        if (id) {
            http.get("/api/blogCategories/" + id)
                .then((response) => {
                    let {category} = response.data.content;
                    this.setState({category: category});
                }).catch((error) => {
                console.log(error)
            })
        }
    };

    componentDidMount() {
        this.load(getParams("id"))
    }

    render() {
        let {category} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={`/admin/blogCategory/list`}>Blog Categories</Breadcrumb.Item>
                    <Breadcrumb.Item active>Category</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-6">
                        <Form onSubmit={this.save}>
                            <Form.Group controlId="name">
                                <Form.Label className={"font-weight-bold"}>Category Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" name='name'
                                              onChange={this.changeHandler}
                                              value={category.name}/>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group controlId="active">
                                        <Form.Check type="checkbox" label="Active" name='active'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={category.active ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>

                </Row>
            </div>
        )
    }
}

export default CreateOrEditBlogCategory