import React from "react";
import "./filterBar.scss";
import "../../constants/constants";
import http from "../../services/HttpService";
import _ from 'lodash';
import {getParams} from "../../utils/UrlUtil";
import {getSelectedCountry, isStreamHomePage, isStreamPage} from "../../utils/Util";

class Providers extends React.Component {
    constructor() {
        super();
        let params = getParams();
        this.state = {
            providers: [],
            selectedProviders: params.get("providers")
        };
    }

    loadProviders = () => {
        http
            .get("/api/es/providers/active/" + getSelectedCountry())
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
                if (this.props.onProvidersLoaded) {
                    this.props.onProvidersLoaded(providers)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    _hasProviderSelected = (provider) => {
        let {selectedProviders} = this.state;
        let selectedProvidersAsList = selectedProviders ? selectedProviders.split(",") : [];
        return _.includes(selectedProvidersAsList, provider.name);
    };


    getStreamListUrl = (provider) => {
        let params = getParams();
        params.set("providers", provider.name);
        if (!isStreamPage() || isStreamHomePage()) {
            return '/Stream?providers=' + provider.name;
        }
        return '?' + params.toString();
    };

    componentDidMount() {
        this.loadProviders();
    }

    render() {
        let {providers, selectedProviders} = this.state;
        let {hideText} = this.props;
        providers = _.orderBy(providers, "tabIndex");

        return (
            <div className="provider-section">
                {!hideText ?
                    <strong className="title">Alla streamingtjänster - filmer och serier online</strong> : null}
                {!hideText ?
                    <p>Hitta var du kan titta på filmer och serier online med Kolla.tv - den ultimata guiden som hjälper
                        dig att hitta de bästa filmerna, serierna och programmen över streamingtjänsterna. Skapa ett
                        gratis konto för att spara dina tjänster, och titta på filmer och serier när du vill se dem.
                    </p> : ""}

                <div className="providerList sticky-top container">
                    <div className="scroll-nav">
                        <a href={"/Stream"} className="choose-btn" type="button">Visa alla leverantörer</a>
                        <ul className={`nav ${_.size(selectedProviders) > 0 ? 'provider-selected' : ''}`}>
                            {
                                providers.map((provider, index) => {
                                    return <li key={index}
                                               className={`${this._hasProviderSelected(provider) ? 'selected' : ''}`}>
                                        <a className={`nav-link`}
                                           title={provider.label}
                                           href={this.getStreamListUrl(provider)}>
                                            <span className={`${provider.name}_icon`}> </span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                        {/*<button className="btn btn-primary" onClick={this.clearSelectedProviders} type="button">Clear*/}
                        {/*Filter*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Providers;
