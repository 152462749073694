import React from "react";
import http from "../../../services/HttpService";
import {getParams, redirectTo} from "../../../utils/UrlUtil";
import {BLOG_CHUNK_SIZE, TABLET_BLOG_CHUNK_SIZE} from "../../../constants/constants";
import {getAdsLabel, getSelectedCountry, isStreamPage} from "../../../utils/Util";
import _ from 'lodash'
import {isDesktop, isMobileDevice, isTableDevice} from "../../../utils/DeviceUtil";
import ReactPaginate from 'react-paginate';
import {isTablet} from "react-device-detect";
import StreamCard from "../StreamCard";
import StreamPlay from "../StreamPlay";
import {Helmet} from "react-helmet";

class Streams extends React.Component {
    constructor(props) {
        super(props);
        const params = getParams();
        this.state = {
            programs: [],
            favourites: [],
            pageCount: 0,
            totalElements: 0,
            selectedPage: params.get("page") || 0,
            selectedProgram: {},
            showPlay: false
        };
    }

    onPlay = (program) => {
        this.setState({selectedProgram: program, showPlay: true});
    };


    closePlay = () => {
        this.setState({showPlay: false})
    };

    loadFavourites = () => {
        http
            .get("/api/es/favourites/kollatv/me")
            .then((response) => {
                let {favourites} = response.data.content;
                this.setState({favourites});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    loadPrograms = () => {
        let params = getParams();
        const DEFAULT_STREAM_SIZE = 32;
        const {sorting, streamType} = this.props;
        const size = params.get("size") || DEFAULT_STREAM_SIZE;

        if (sorting) {
            params.set("sorting", sorting);
        }
        if (streamType) {
            params.set("streamType", streamType);
        }
        params.set("active", true);
        params.set("size", size);
        params.set("excludeProgramsType", ["article"]);
        params.set("seriesProgram", false);
        params.set("country", getSelectedCountry());
        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                let pageCount = Math.ceil(totalElements / DEFAULT_STREAM_SIZE);
                this.setState({programs, totalElements, pageCount});
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getFavouriteByProgramId = (programId) => {
        const {favourites} = this.state;
        if (_.size(favourites) > 0) {
            return _.find(favourites, ["programId", programId])
        }
    };

    onPageChange = (data) => {
        let selectedPage = data.selected;
        let params = getParams();
        params.set("page", selectedPage);
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    setTitleAndMetadata = () => {
        let title = 'Kolla.tv - Swedish TV channels guide,listing to streaming and TV programs';
        let description = "Kolla.tv är den kompletta TV-guiden med TV-tablåer för alla TV-kanaler som finns tillgängliga på den svenska marknaden. Med Kolla.tv kan du skapa din personliga TV-tablå och alltid ha kolla på vad du ska titta på. ";
        let keywords = "TV ,TV-tablå ,TV tablå ,TV idag ,TV guide ,TV-guide ,TV nu ,TV program ,TV-program ,Sport på TV, på tv nu,på tv idag,tv guide,tvguide,tv guiden,tv guide sverige, kolla tv,TV-tablå, tv-tablå, tvtablå, tv-kanaler, filmer på tv, serier på tv, sport på tv, komplett tv-guide, tvguide, TV-guide, tv-guide,TV-tablåer, tvtablåer, Streaming, gratis streaming, gratis film, tablåer, SVT1,SVT2, TV3, TV4, Kanal5, TV6, TV8, Discovery, TV-listings. ";
        if (isStreamPage()) {
            title = 'Kolla.tv - Svenska filmer, serier och tv-program streamas online\n';
        }
        return <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords}/>
            <meta name="description" content={description}/>
        </Helmet>
    };

    componentDidMount() {
        let {programs} = this.state;
        for (let i = 1; i <= 40; i++) {
            programs.push({})
        }
        this.setState({programs});
        this.loadFavourites();
        this.loadPrograms();
    }

    render() {
        let {programs, selectedPage, pageCount, selectedProgram, showPlay} = this.state;
        let screenWidth = window.screen.width;
        const blogChunkSize = isTablet && screenWidth >= 768 && screenWidth <= 970 ? TABLET_BLOG_CHUNK_SIZE : BLOG_CHUNK_SIZE;

        return (<div>
                {this.setTitleAndMetadata()}
                {
                    _.chunk(programs, blogChunkSize).map((programsSubList, parentIndex) => {
                        return <div key={parentIndex}>
                            <ul className="stream-listing">
                                {
                                    programsSubList.map((program, index) => {
                                        return (
                                            <li className="item cursor-pointer" key={index}
                                                onClick={() => redirectTo('/play/' + program.friendlyUrl)}>
                                                <StreamCard program={program} onPlay={() => {
                                                    this.onPlay(program)
                                                }} favourite={this.getFavouriteByProgramId(program.id)}/>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                            {/*Display second ads */}
                            {parentIndex === 0 ? <div className="blog-ads">
                                {getAdsLabel()}
                                {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                                {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                                {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                            </div> : null}

                            {parentIndex === 2 ?
                                <div className="blog-ads">
                                    {getAdsLabel()}
                                    {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                                    {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                                    {isMobileDevice() ? <div id="cncpt-mob3" className="desktopNone"></div> : ''}
                                </div> : null}
                        </div>
                    })
                }

                {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                    previousLabel={'Tidigare'}
                    nextLabel={'Nästa'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={parseInt(pageCount)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={5}
                    onPageChange={this.onPageChange}
                    containerClassName={'pagination'}
                    initialPage={parseInt(selectedPage)}
                    disableInitialCallback={true}
                    activeClassName={'active'}

                /> : null}
                <StreamPlay show={showPlay} onHide={this.closePlay} program={selectedProgram}/>

            </div>
        );
    }
}

export default Streams;
