import React from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import HasFeaturePermission from "../../user/HasFeaturePermission";


class AdminRoutes extends React.Component {
    render() {
        return (
            <div className="container">
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="/admin/index">Kollatv</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <HasFeaturePermission permission={'channel.allow'}>
                                <NavDropdown title="Channel" id="basic-nav-dropdown">
                                    {/*<NavDropdown.Item href="#action/3.1">Create</NavDropdown.Item>*/}
                                    <HasFeaturePermission permission={'channel.read'}>

                                        <NavDropdown.Item href="/admin/channel/list">List</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'category.allow'}>
                                <NavDropdown title="Category" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'category.read'}>
                                        <NavDropdown.Item href="/admin/category/list">List</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'program.allow'}>
                                <NavDropdown title="Programs" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'program.read'}>
                                        <NavDropdown.Item href="/admin/program/list">List</NavDropdown.Item>
                                    </HasFeaturePermission>
                                    <NavDropdown.Divider/>
                                    <HasFeaturePermission permission={'program.import'}>
                                        <NavDropdown.Item href="/admin/program/import">Import</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'user.allow'}>
                                <NavDropdown title="Users" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'user.read'}>
                                        <NavDropdown.Item href="/admin/user/list">List</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'blog.allow'}>
                                <NavDropdown title="Blogs" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'blog.write'}>
                                        <NavDropdown.Item href="/admin/blog/create">Create</NavDropdown.Item>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'blog.read'}>
                                        <NavDropdown.Item href="/admin/blog/list">List</NavDropdown.Item>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'blog.settings'}>
                                        <NavDropdown.Item href="/admin/blog/blogSettings">Settings</NavDropdown.Item>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'blog.category.read'}>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item href="/admin/blogCategory/list">Category</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'media.allow'}>
                                <NavDropdown title="Gallery" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'media.read'}>
                                        <NavDropdown.Item href="/admin/media/list">List</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'content.allow'}>
                                <NavDropdown title="Content" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'content.write'}>
                                        <NavDropdown.Item
                                            href="/admin/content/index">Kolla/Streamly/Dagenstv</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'streamly.allow'}>
                                <NavDropdown title="Streamly" id="basic-nav-dropdown">
                                    <HasFeaturePermission permission={'streamly.program.read'}>
                                        <NavDropdown.Item href="/admin/stream/program/list">Programs</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'streamly.provider.read'}>
                                        <NavDropdown.Item
                                            href="/admin/stream/provider/list">Providers</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'streamly.genre.read'}>
                                        <NavDropdown.Item href="/admin/stream/genre/list">Genres</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'streamly.season.read'}>
                                        <NavDropdown.Item href="/admin/stream/season/list">Seasons</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'streamly.language.read'}>
                                        <NavDropdown.Item
                                            href="/admin/stream/language/list">Languages</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'streamly.country.raed'}>
                                        <NavDropdown.Item href="/admin/stream/country/list">Countries</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                    </HasFeaturePermission>
                                    <HasFeaturePermission permission={'streamly.blog.read'}>
                                        <NavDropdown.Item href="/admin/stream/blog/list">Blogs</NavDropdown.Item>
                                    </HasFeaturePermission>
                                </NavDropdown>
                            </HasFeaturePermission>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default AdminRoutes