import React from "react";
import {
    ellipsis,
    findOrGetDefaultTranslation,
    getProgramImage,
    getProviderLabel,
    getSelectedCountry,
    getStreamStartTime,
    isAdmin,
    isLatestTvShow,
    isLive
} from "../../utils/Util";
import UserAction from "./UserAction";
import _ from 'lodash'

class StreamCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {program, favourite, onPlay} = this.props;
        let translation = findOrGetDefaultTranslation(program, getSelectedCountry());
        return (
            <div>
                <div className="top-image-section">
                    {isLive(program) ? <span className="live-badge">Live</span> : null}
                    {isLatestTvShow(program) ? <span className="live-badge">Ny säsong</span> : null}
                    {isAdmin() && program.draft ? <span className="live-badge">Draft</span> : null}
                    {getStreamStartTime(program) ?
                        <span className="live-badge">{getStreamStartTime(program)}</span> : null}
                    <div className="imageContainer">
                        <img src={getProgramImage(program)} alt={translation.title}/>
                    </div>
                    <div className="actions">
                        {program.id ?
                            <UserAction showPlayBtn={!!program.videoUrl} onPlay={onPlay} programId={program.id}
                                        favourite={favourite}/> : null}
                    </div>
                </div>


                <div className="info">
                    <div className="heading-sec">
                        <h3>{translation.title} </h3>
                        {program.rating ? <span
                            className="badge badge-info">{program.rating}</span> : null}
                    </div>
                    <div>
                        {
                            program.providers ? _.filter(program.providers, ["country", getSelectedCountry()]).map((provider) => {
                                return <span className="provider-badge">{getProviderLabel(provider.provider)}</span>
                            }) : null
                        }
                    </div>
                    <p>{ellipsis(translation.description, 75)}</p>
                </div>
            </div>
        );
    }
}

export default StreamCard;
