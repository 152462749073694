import React from 'react';
import {Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import _ from 'lodash'
import {FaBars} from 'react-icons/fa';
import ToastUtil from "../../../utils/ToastUtil";
import loader from '../../../images/loader.gif'

class Channels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {channels: [], totalElements: 0, orderBy: "tabIndex", isInProgress: false}
    }

    toggleDefault = (_channel) => {
        http.get("/api/channels/toggleDefault?id=" + _channel.id)
            .then((response) => {
                let {channel} = response.data.content;
                _channel.default = channel.default;
                this.forceUpdate();
            }).catch((error) => {
            console.log(error)
        })
    };

    onChannelsDrag = (newChannels) => {
        _.forEach(newChannels, (channel, index) => {
            channel.tabIndex = index
        });
        this.setState({channels: newChannels});
    };

    saveChannelsOrder = () => {
        let orders = _.map(this.state.channels, (channel, index) => {
            return {channelId: channel.id, tabIndex: index};
        });
        this.toggleInProgress(true);
        http.post("/api/channels/saveChannelsOrder", {orders: orders})
            .then((response) => {
                ToastUtil.success("Channels settings saved successfully");
            }, (error) => {
                console.log(error)
            }).finally(() => {
            this.toggleInProgress(false);
        })

    };

    setOrderBy = (orderBy) => {
        this.setState({orderBy: orderBy})
    };

    loadCacheChannels = () => {
        this.toggleInProgress(true);
        http.get("/api/channels/cache")
            .then((response) => {
                let {channels, totalElements} = response.data.content;
                this.setState({channels: channels, totalElements: totalElements});
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    saveDefaultChannelsOrderForBackup = () => {
        this.toggleInProgress(true);
        http.get("/api/channels/saveDefaultChannelsOrderForBackup")
            .then((response) => {
                this.loadCacheChannels();
                ToastUtil.success("Successfully save default channel orders for backup")
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false);
        })
    };

    revertDefaultChannelsOrderFromBackup = () => {
        this.toggleInProgress(true);
        http.get("/api/channels/revertDefaultChannelsOrderFromBackup")
            .then((response) => {
                this.loadCacheChannels();
                ToastUtil.success("Successfully revert channel orders from backup")
            }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.toggleInProgress(false)
        })
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    componentDidMount() {
        this.loadCacheChannels();
    }

    render() {
        let {channels, orderBy, isInProgress} = this.state;
        let order = orderBy === "default" ? "desc" : "asc";
        channels = _.orderBy(channels, [orderBy], [order]);
        return (
            <div>
                <h5>Channel list</h5>
                <br/>
                <Row>
                    <div className="col-md-7">
                        <b>Sort By :&nbsp;&nbsp;</b>
                        <input type="button" onClick={() => {
                            this.setOrderBy("default")
                        }} className="btn btn-sm btn-primary" value="Default"/>
                        &nbsp;&nbsp;
                        <input type="button" onClick={() => {
                            this.setOrderBy("tabIndex")
                        }} className="btn btn-sm btn-primary" value="Order"/>
                        &nbsp;&nbsp;
                        <input type="button" onClick={() => {
                            this.setOrderBy("name")
                        }} className="btn btn-sm btn-primary" value="Name"/>
                    </div>
                    <div className="col-md-5">
                        <input type="button" onClick={() => {
                            this.revertDefaultChannelsOrderFromBackup();
                        }} className="btn btn-sm btn-primary" value="Revert from Backup"/>&nbsp;
                        <input type="button" onClick={() => {
                            this.saveDefaultChannelsOrderForBackup();
                        }} className="btn btn-sm btn-primary" value="Save for Backup"/>&nbsp;
                        <input type="button" onClick={() => {
                            this.saveChannelsOrder();
                        }} className="btn btn-sm btn-primary" value="Save"/>
                        {isInProgress ? <img src={loader}/> : ''}
                    </div>
                </Row>
                <br/>
                <Row>
                    <div className="col-md-2"><b>Order Index</b></div>
                    <div className="col-md-3"><b>Name</b></div>
                    <div className="col-md-3"><b>DagensTV Channel Id</b></div>
                    <div className="col-md-1"><b>Active</b></div>
                    <div className="col-md-3"><b>Actions</b></div>
                </Row>
                {
                    <RLDD items={channels} onChange={this.onChannelsDrag}
                          itemRenderer={(channel) => {
                              return <Row className="table-view">
                                  <div className="col-md-2 table-item"><FaBars/> ({channel.tabIndex})</div>
                                  <div className="col-md-3 table-item">
                                      <a href={'/admin/channel/show/' + channel.id}>
                                          {channel.name}
                                      </a>
                                  </div>
                                  <div className="col-md-3 table-item">
                                      {channel.dagensId}
                                  </div>
                                  <div className="col-md-1">
                                      {channel.active ? 'Yes' : 'No'}
                                  </div>
                                  <div className="col-md-3 table-item">
                                      <button type="button" onClick={() => this.toggleDefault(channel)}
                                              className="btn btn-primary btn-sm">
                                          {channel.default ? 'Remove Default' : 'Make Default'}
                                      </button>
                                  </div>
                              </Row>
                          }
                          }/>
                }
            </div>
        )
    }
}

export default Channels