import React from 'react';
import './Footer.scss';
import StaticLinks from './StaticLinks';

function Footer() {
    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-4 ">
                            <h2>Om Kolla.tv</h2>
                            <p>Sveriges bästa TV-tablå heter kolla.tv. Här hittar du en enkel tv-guide som ger dig full
                                översikt över vilka program, film och serier som visas på Sveriges TV-kanaler. Vår tv-guide
                                drivs inom Comspace group, som också ligger bakom streamingguiden <a href="https://www.streamly.com/" target="_blank"></a></p>
                            {/* <StaticLinks/> */}
                        </div>
                        <div className="col-xs-12">
                            <h2>Våra kategorier:</h2>
                            <div className="row">
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Film">Film</a></h3>
                                    <p>Här har vi samlat en översikt över vilka filmer du kan se varje dag. Du kan också få tips på
                                        filmer genom att läsa våra populära artiklar.</p>
                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Tv-serier">Serier</a></h3>
                                    <p>I denna kategori hittar du en stort utbud på olika serie, som våra TV-kanaler sänder varje
                                        dag. Från reality till drama, nya till gamla serier.</p>

                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Sport">Sport</a></h3>
                                    <p>Vad vore livet utan sport och genom vår snabba filitering får du snabbt en överblick kring
                                        varje dags olika sportsädningar.</p>
                                </div>

                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Stream/Home">Play</a></h3>
                                    <p>Senaste tillskottet på vår TV-guide är ett bibliotek med Sveriges största alla
                                        streamingtjänster. Hitta vart du kan titta på filmer och serier online – den ultimata guiden.</p>

                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Annonsera">Annonsera</a></h3>
                                    <p>Är du intresserad att synas och annonsera på Kolla.tv? Vi har många spännande upplägg och
                                        möjligheter.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <p className="copyright">© Kolla.tv 2020. Alla rättigheter förbehållna.</p> */}
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-xs-7 col-sm-7">
                            <StaticLinks />
                        </div>
                        <div className="col-xs-5 col-sm-5"><p>© Kolla.tv 2020. Alla rättigheter förbehållna.</p></div>
                    </div>
                </div>

            </footer>
        </div>

    )
}

export default Footer