import React from 'react';
import {Alert, Button, Form, Row} from 'react-bootstrap';
import '../Form.scss'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import loader from '../../images/loader.gif'
import ToastUtil from "../../utils/ToastUtil";
import {isMobile} from "react-device-detect";
import Footer from '../footer/Footer';
import {redirectTo} from "../../utils/UrlUtil";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        let {match: {params}} = this.props;
        this.state = {
            password: '',
            confirmPassword: '',
            isInProgress: false,
            hash: params.hash,
            errors: [],
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    resetPassword = (event) => {
        event.preventDefault();
        if (this.validator.allValid() && !this.state.isInProgress) {
            let {password, confirmPassword, hash} = this.state;
            this.toggleInProgress(true);
            http
                .post("/api/users/resetPassword", {
                    password: password,
                    confirmPassword: confirmPassword,
                    hash: hash
                })
                .then((response) => {
                    let {status, errors} = response.data;
                    if (status) {
                        ToastUtil.success("You have been successfully reset your password")
                    } else {
                        this.setState({errors: errors})
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };


    getAdsRender = () => {
        if (isMobile) {
            return <div id="cncpt-mob1"></div>
        } else {
            return <div id="cncpt-lb1"></div>
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    render() {
        return (
            <div className="container forgot-password">
                <div>
                    {this.getAdsRender()}
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-8 register-form">
                        <h2 className="header">Återställ lösenord</h2>
                        <Form className="form" onSubmit={this.resetPassword} method="POST">
                            <Row>
                                <Form.Control type="password" name="password" onChange={this.changeHandler}
                                              placeholder="Lösenord"/>
                                {this.validator.message('password', this.state.password, 'required')}
                            </Row>
                            <Row>
                                <Form.Control type="password" name="confirmPassword" onChange={this.changeHandler}
                                              placeholder="Bekräfta lösenord"/>
                                {this.validator.message('confirmPassword', this.state.confirmPassword, 'required')}
                            </Row>
                            {
                                this.state.errors.map((error, index) => {
                                    return <Alert key={index} variant={'danger'}>
                                        {error}
                                    </Alert>
                                })
                            }
                            <Button variant="primary" type="submit">
                                Återställa
                            </Button>
                            <input type="button" value="Avbryt" className="btn btn-default" onClick={() => {
                                redirectTo("/")
                            }}/>
                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default ForgotPassword