import React from 'react';
import './Weekdays.scss';
import './header/Header.scss';
import '../constants/constants'
import {TOTAL_WEEK_DAYS, WEEKDAYS} from "../constants/constants";
import {equalDate} from "../utils/DateUtil";
import moment from 'moment'
import {getSelectedDay, getSelectedWeek} from "../utils/Util";
import {isMobile} from "react-device-detect";

class WeekDays extends React.Component {

    constructor() {
        super();
        this.state = {
            weekDays: this.getWeekDays()
        }
    }

    getWeekDays = () => {
        let days = [];
        let selectedWeek = getSelectedWeek();
        let selectedDay = getSelectedDay();
        let endDate = selectedWeek * TOTAL_WEEK_DAYS;
        let startDate = endDate - TOTAL_WEEK_DAYS;
        let today = new Date();
        let tomorrow = moment().add(1, "days").toDate();
        for (let i = startDate; i < endDate; i++) {
            let displayName;
            let date = moment().add(i, "days").toDate();
            if (equalDate(date, today)) {
                displayName = 'Idag';
            } else if (equalDate(date, tomorrow)) {
                displayName = 'Imorgon';
            } else {
                displayName = WEEKDAYS[date.getDay()].name + " " + date.getDate();
            }

            days.push({
                date: date,
                displayName: displayName,
                active: selectedDay === i % TOTAL_WEEK_DAYS
            })
        }
        return days;
    };

    getPreviousUrl = () => {
        let prevLink;
        if (isMobile) {
            let selectedDay = getSelectedDay() - 1;
            let selectedWeek = getSelectedWeek();
            if (selectedDay < 0 && selectedWeek > 1) {
                selectedWeek -= 1;
                selectedDay = 6;
            }
            if (getSelectedWeek() === 1 && getSelectedDay() === 0) {
                prevLink = <a href={"?day=-1"} className="prev-link">« Igår</a>
            } else {
                prevLink = <a href={`?day=${selectedDay}&week=${selectedWeek}`} className="prev-link">«Förgående</a>
            }
        } else {
            if (getSelectedWeek() > 1) {
                prevLink = <a href={`?day=6&week=${getSelectedWeek() - 1}`} className="prev-link">« Förgående</a>
            } else {
                prevLink = <a href={"?day=-1"} className="prev-link">« Igår</a>
            }
        }
        return prevLink
    };

    getNextUrl = () => {
        let nextLink;
        let selectedWeek = getSelectedWeek();
        if (selectedWeek < 2) {
            if (isMobile) {
                let selectedDay = getSelectedDay() + 1;
                if (selectedDay > 6) {
                    selectedDay = selectedDay % 7;
                    selectedWeek += 1;
                }
                nextLink = <a href={`?day=${selectedDay}&week=${selectedWeek}`} className="next-link">Nästa »</a>
            } else {
                nextLink = <a href={`?day=0&week=${selectedWeek + 1}`} className="next-link">Nästa »</a>
            }
        }
        return nextLink
    };

    componentDidMount() {

    }

    render() {
        return (
            <div className="weeekday sticky-top container">
                {this.getPreviousUrl()}
                <div className="scroll-nav">
                    <ul className="nav">{
                        this.state.weekDays.map((day, index) => {
                            return <li key={index} className={`${day.active ? 'active' : ''} nav-item`}>
                                <a className={`nav-link`}
                                   href={`?day=${index}&week=${getSelectedWeek()}`}>
                                    {day.displayName}
                                </a>
                            </li>
                        })
                    }
                    </ul>
                </div>
                {this.getNextUrl()}
            </div>

        )
    }
}

export default WeekDays